import { Tab, Tabs } from 'components/Tabs/Tabs';
import { useTranslation } from 'react-i18next';
import { AppRoutes } from 'Routes';

export const DocumentsTab = () => {
    const { t } = useTranslation('documents');
    return (
        <Tabs className="pt-8">
            <Tab to={AppRoutes.invoices}>{t('invoicesTab')}</Tab>
            <Tab to={AppRoutes.consumptions}>{t('consumptionsTab')}</Tab>
        </Tabs>
    );
};
