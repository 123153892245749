import { getUser } from 'services/users';
import { create } from 'zustand';

interface StaffStore {
    member: any;
    loading: boolean;
    getMember: (id: string) => void;
    setMember: (member: any) => void;
}

export const useStaffStore = create<StaffStore>((set, get) => ({
    member: null,
    loading: false,
    setMember: member => set({ member }),
    getMember: async (id: string) => {
        if (!get().member) {
            set({ loading: true });
        }
        const { data } = await getUser(id);
        if (data.user) {
            set({ member: data.user });
        }
        set({ loading: false });
    },
}));
