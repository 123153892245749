import { useMutation, useQuery } from '@apollo/client';
import { Breadcrumbs } from 'components/Breadcrumbs/Breadcrumbs';
import { Button } from 'components/Button/Button';
import { Card } from 'components/Card/Card';
import { Container } from 'components/Container/Container';
import { Input } from 'components/Input/Input';
import { PageTitle } from 'components/PageTitle/PageTitle';
import { Textarea } from 'components/Textarea/Textarea';
import { useToast } from 'components/Toast/use-toast';
import { CustomToggle } from 'components/Toggle/Toggle';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { AppRoutes } from 'Routes';
import { ANNOUNCEMENT_CREATE, ANNOUNCEMENT_UPDATE } from 'services/announcement/mutation';
import { ANNOUNCEMENTS } from 'services/announcement/qurery';

export const EditAnnouncement = () => {
    const { t } = useTranslation('anouncement');
    const { data, refetch } = useQuery(ANNOUNCEMENTS);
    const [announcement, setAnnouncement] = useState<any>(null);
    const { register, watch, handleSubmit, control, setValue } = useForm();
    const { toast } = useToast();
    const navigate = useNavigate();

    const [updateAnnouncementMutation] = useMutation(ANNOUNCEMENT_UPDATE);
    const [createAnnouncementMutation] = useMutation(ANNOUNCEMENT_CREATE);

    useEffect(() => {
        if (data?.announcements?.totalCount > 0) {
            const a = data.announcements.edges[0].node;
            setValue('title', a.title);
            setValue('description', a.description);
            setValue('status', a.status === 'ACTIVE' ? true : false);
            setAnnouncement(data.announcements.edges[0].node);
        }
    }, [data, setValue]);

    const onSubmit = (formData: any) => {
        if (announcement?.id) {
            updateAnnouncement(formData);
        } else {
            createAnnouncement(formData);
        }
    };

    const createAnnouncement = async (formData: any) => {
        formData.status = formData.status ? 'ACTIVE' : 'INACTIVE';
        const resp = await createAnnouncementMutation({
            variables: { input: formData },
        });
        if (resp.data?.announcementCreate?.announcement?.id) {
            successToast();
            refetch();
            navigate(AppRoutes.announcement);
        } else {
            failToast();
        }
    };
    const updateAnnouncement = async (formData: any) => {
        formData.status = formData.status ? 'ACTIVE' : 'INACTIVE';
        const resp = await updateAnnouncementMutation({
            variables: { announcement: announcement.id, input: formData },
        });
        if (resp.data?.announcementUpdate?.announcement?.id) {
            successToast();
            refetch();
            navigate(AppRoutes.announcement);
        } else {
            failToast();
        }
    };

    const successToast = () => {
        toast({
            title: t('successToastTitle'),
            variant: 'success',
        });
    };

    const failToast = () => {
        toast({
            title: t('failToastTitle'),
            description: t('failToastSubtitle'),
            variant: 'warning',
        });
    };

    return (
        <Container>
            <Breadcrumbs crumbs={['dashboard', 'announcement', 'edit']} />
            <PageTitle withBack>{t('title')}</PageTitle>

            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="mt-4">
                    <Card className="flex flex-col gap-6">
                        <div>
                            <div className="text-sm font-medium mb-2">{t('titleLabel')}</div>
                            <Input placeholder="Title" {...register('title')} />
                        </div>
                        <div>
                            <div className="text-sm font-medium mb-4">{t('descriptionLabel')}</div>
                            <Textarea placeholder="Description" {...register('description')} />
                            <div className="flex justify-end text-sm mt-2 text-zinc-400">
                                {watch('description')?.length || 0} / 350
                            </div>
                        </div>
                        <label className="flex items-center gap-2">
                            <Controller
                                control={control}
                                name="status"
                                render={({ field: { onChange, value } }) => (
                                    <CustomToggle icons={false} onChange={onChange} checked={value} />
                                )}
                            />

                            <span className="text-sm font-medium">{t('activeAnnouncementLabel')}</span>
                        </label>
                    </Card>
                </div>
                <div className="mt-8 flex justify-end">
                    <Button>{t('saveButton')}</Button>
                </div>
            </form>
        </Container>
    );
};
