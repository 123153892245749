import { useQuery } from '@apollo/client';
import { Balance } from 'components/Balance/Balance';
import { Breadcrumbs } from 'components/Breadcrumbs/Breadcrumbs';
import { DocumentsTab } from 'components/DocumentsTabs/DocumentsTabs';
import { Icon } from 'components/Icon/Icon';
import { PageTitle } from 'components/PageTitle/PageTitle';
import { Pagination } from 'components/Pagination/Pagination';
import { SearchFilter } from 'components/SearchFilter/SearchFilter';
import { SortFilter } from 'components/SortFilter/SortFilter';
import { Status } from 'components/Status/Status';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from 'components/Table/Table';
import { normalizeArray } from 'lib/utils';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { AppRoutes } from 'Routes';
import { USERS } from 'services/users/query';

import { ConsumprionActions } from './_components/ConsumptionActions';
import { EditBalanceModal } from './_components/EditBalanceModal';
import { ViewBalancesModal } from './_components/ViewBalancesModal';

const PAGE_SIZE = parseInt(localStorage.getItem('pageSize') as any) || 10;
const SORT = [
    { value: 'A_Z', label: 'A-Z' },
    { value: 'Z_A', label: 'Z-A' },
    { value: 'HIGHEST_BALANCE', label: 'Highest balance' },
    { value: 'LOWEST_BALANCE', label: 'Lowest balance' },
];

export const Consumptions = () => {
    const { t } = useTranslation('documents');
    const [searchParams] = useSearchParams();
    const [isOpen, setIsOpen] = useState(false);
    const [balancesModal, setBalancesModal] = useState(false);
    const [member, setMember] = useState(null);
    const navigate = useNavigate();

    const { data, refetch } = useQuery(USERS, {
        variables: { isMember: true, first: PAGE_SIZE },
    });

    useEffect(() => {
        const search = searchParams.get('search');
        const sort = searchParams.get('sort');
        const page = searchParams.get('page') || '1';

        refetch({
            search: search,
            sort: sort,
            offset: (parseInt(page) - 1) * PAGE_SIZE,
        });
    }, [searchParams, refetch]);

    const editMembersBalance = (user: any) => {
        setMember(user);
        setIsOpen(true);
    };

    const viewMemberBalances = (user: any) => {
        setMember(user);
        setBalancesModal(true);
    };

    const openConsumptions = (id: string) => {
        navigate(AppRoutes.memberConsumptions.replace(':id', id));
    };

    return (
        <div>
            <Breadcrumbs crumbs={['dashboard', 'consumptions']} />
            <DocumentsTab />
            <PageTitle>{t('consumptionsTitle')}</PageTitle>

            <div className="flex gap-4 flex-wrap pb-4">
                <SearchFilter placeholder={t('searchPlaceholder')} />
                <SortFilter options={SORT} />
            </div>

            <Table className="border">
                <TableHeader>
                    <TableRow>
                        <TableHead>{t('nameLabel')}</TableHead>
                        <TableHead>{t('phoneLabel')}</TableHead>
                        <TableHead>{t('balanceLabel')}</TableHead>
                        <TableHead>{t('statusLabel')}</TableHead>
                        <TableHead />
                    </TableRow>
                </TableHeader>
                <TableBody className="hover:cursor-pointer">
                    {data?.users.edges.length > 0 &&
                        normalizeArray(data?.users).map((user: any) => (
                            <TableRow key={user.id} className="text-zinc-700 hover:bg-rose-50">
                                <TableCell
                                    className="flex gap-2 items-center"
                                    onClick={() => openConsumptions(user.id)}
                                >
                                    <UserImage image={user.image} />
                                    {user.firstName} {user.lastName}
                                </TableCell>
                                <TableCell onClick={() => openConsumptions(user.id)}>{user.phoneNumber}</TableCell>
                                <TableCell className="hidden md:table-cell" onClick={() => openConsumptions(user.id)}>
                                    <Balance balance={user.balance} />
                                </TableCell>
                                <TableCell className="hidden md:table-cell" onClick={() => openConsumptions(user.id)}>
                                    <Status status={user.status} />
                                </TableCell>
                                <TableCell className="py-3 justify-end flex">
                                    <ConsumprionActions
                                    user={user}
                                        onEdit={() => editMembersBalance(user)}
                                        onViewBalances={() => viewMemberBalances(user)}
                                    />
                                </TableCell>
                            </TableRow>
                        ))}
                </TableBody>
            </Table>
            <Pagination pageInfo={data?.users?.pageInfo} total={data?.users?.totalCount} />
            <EditBalanceModal
                isOpen={isOpen}
                onClose={() => setIsOpen(false)}
                member={member}
                onUpdate={() => refetch()}
            />
            <ViewBalancesModal isOpen={balancesModal} onClose={() => setBalancesModal(false)} member={member} />
        </div>
    );
};

const UserImage = ({ image }: any) => {
    return (
        <div className="bg-zinc-300 w-[32px] h-[32px] flex justify-center items-center">
            {image ? (
                <img src={`${image.url}?fit=facearea&facepad=2.5&w=32&h=32`} alt={image.alt} />
            ) : (
                <Icon name="user" className="text-zinc-50 h-[18px]" />
            )}
        </div>
    );
};
