import clsx from 'clsx';
import Checkbox from 'components/Checkbox/Checkbox';
import { Icon } from 'components/Icon/Icon';
import { cn } from 'lib/utils';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import styles from './RoleFilter.module.scss';

type RoleFilterProps = {
    options: string[];
    label?: string;
};
export const RoleFilter: React.FC<RoleFilterProps> = ({ options, label }) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [active, setActive] = useState(false);
    const roleRef = useRef<any>(null);
    const { t } = useTranslation('translation', { keyPrefix: 'filters' });

    const handleClickOutside = (event: any) => {
        if (roleRef.current && !roleRef.current.contains(event.target)) {
            setActive(false);
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleClickOutside, true);

        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, []);

    const toggleStatus = (role: string) => {
        const params = new URLSearchParams(searchParams);
        const list = params.get('role')?.split(',') || [];
        if (list.includes(role)) {
            list.splice(list.indexOf(role), 1);
        } else {
            list.push(role);
        }
        if (list.length > 0) {
            params.set('role', list.join(','));
        } else {
            params.delete('role');
        }
        setSearchParams(params);
    };

    const clearFilter = (role: string) => {
        const params = new URLSearchParams(searchParams);
        const list = params.get('role')?.split(',') || [];
        list.splice(list.indexOf(role), 1);
        if (list.length > 0) {
            params.set('role', list.join(','));
        } else {
            params.delete('role');
        }
        setSearchParams(params);
        setActive(false);
    };

    const clearAllFilters = () => {
        const params = new URLSearchParams(searchParams);
        params.delete('role');
        setSearchParams(params);
        setActive(false);
    };

    return (
        <div className={clsx(styles.roleFilter, active && styles.active)} ref={roleRef}>
            <div
                className={cn(
                    styles.dashed,
                    'flex items-center gap-3 text-black outline-zinc-300 py-1 px-3 text-sm h-8 hover:cursor-pointer',
                )}
            >
                <div className="flex gap-3 items-center" onClick={() => setActive(!active)}>
                    <Icon name="carret-down" />
                    <div>{label || t('roleFilter')}</div>
                </div>
                {searchParams.get('role') && (
                    <>
                        <div className={styles.divider}></div>
                        <div className={styles.selectedFiltersList}>
                            {searchParams
                                .get('role')
                                ?.split(',')
                                .map(role => (
                                    <div className={styles.selectedFilter} key={role}>
                                        {role.toLowerCase()}
                                        <Icon name="close" onClick={() => clearFilter(role)} />
                                    </div>
                                ))}
                        </div>
                    </>
                )}
            </div>
            <div className={styles.dropdown}>
                <div className={styles.dropdownContent}>
                    <div className="pt-4 pb-2">
                        <div className="flex flex-col mb-4">
                            {options.map(role => (
                                <div
                                    className="cursor-pointer p-2 hover:bg-rose-50"
                                    onClick={() => toggleStatus(role)}
                                    key={role}
                                >
                                    <Checkbox
                                        checked={
                                            !!searchParams
                                                .get('role')
                                                ?.split(',')
                                                .find(s => s === role)
                                        }
                                        onChange={() => toggleStatus(role)}
                                    >
                                        {t(role.toLowerCase())}
                                    </Checkbox>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="h-px bg-zinc-200"></div>
                    <div
                        className="mt-2 py-2 px-4 text-center cursor-pointer hover:bg-rose-50"
                        onClick={() => clearAllFilters()}
                    >
                        {t('clearAllLabel')}
                    </div>
                </div>
            </div>
        </div>
    );
};
