import { useMutation } from '@apollo/client';
import { Breadcrumbs } from 'components/Breadcrumbs/Breadcrumbs';
import { Button } from 'components/Button/Button';
import { Icon } from 'components/Icon/Icon';
import { MemberTabs } from 'components/MemberTabs/MemberTabs';
import { PageTitle } from 'components/PageTitle/PageTitle';
import { useToast } from 'components/Toast/use-toast';
import { UserPreferences } from 'components/UserPreferences/UserPreferences';
import dayjs from 'dayjs';
import { cn } from 'lib/utils';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { AppRoutes } from 'Routes';
import { PARTNER_CREATE, PARTNER_UPDATE } from 'services/users/mutation';
import { useUserStore } from 'stores/user.store';

import { PartnerImage } from './_components/PartnerImage/PartnerImage';
import { PartnerInfo } from './_components/PartnerInfo/PartnerInfo';

export const EditPartner = () => {
    const user = useUserStore(state => state.user);
    const loadUser = useUserStore(state => state.getUser);
    const methods = useForm({
        mode: 'onChange',
    });
    const { toast } = useToast();
    const navigate = useNavigate();
    const [createPartner] = useMutation(PARTNER_CREATE);
    const [updatePartner] = useMutation(PARTNER_UPDATE);
    const { t } = useTranslation('partner');

    const onSubmit = (data: any) => {
        // Re-format dates so backend can parse them
        data.dateOfBirth = data.dateOfBirth ? dayjs(data.dateOfBirth, 'DD.MM.YYYY').format('YYYY-MM-DD') : null;
        // Map food preferences so an array of only IDs is sent
        data.likes = data.likes?.map((l: any) => l.value);
        data.dislikes = data.dislikes?.map((l: any) => l.value);
        data.dietRestrictions = data.dietRestrictions?.map((l: any) => l.value);
        data.allergies = data.allergies?.map((l: any) => l.value);

        if (!user?.partner?.id) {
            saveNewPartner(data);
        } else {
            updateExistingPartner(data);
        }
    };

    const updateExistingPartner = (data: any) => {
        if (!user) return;
        updatePartner({ variables: { input: data, partner: user?.partner?.id } }).then((res: any) => {
            if (res.errors?.length > 0) {
                toast({
                    title: t('partnerSaveFail'),
                    description: res.errors[0].message,
                    variant: 'warning',
                });
            } else {
                toast({
                    description: t('partnerSaveSuccess'),
                    variant: 'success',
                });
                loadUser(user?.id);
                navigate(AppRoutes.memberPartner.replace(':id', user?.id), { replace: true });
            }
        });
    };

    const saveNewPartner = (data: any) => {
        if (!user) return;
        createPartner({ variables: { input: data, user: user?.id } }).then((res: any) => {
            if (res.errors?.length > 0) {
                toast({
                    title: t('partnerSaveFail'),
                    description: res.errors[0].message,
                    variant: 'warning',
                });
            } else {
                toast({
                    description: t('partnerSaveSuccess'),
                    variant: 'success',
                });
                loadUser(user?.id);
                navigate(AppRoutes.memberPartner.replace(':id', user?.id), { replace: true });
            }
        });
    };

    return (
        <>
            <Breadcrumbs crumbs={['Members', 'Edit Member']} />
            <PageTitle className={cn('pt-8 flex items-center')}>
                <Icon name="back" className="h-6 text-2xl mr-2 hover:cursor-pointer" onClick={() => navigate(-1)} />
                {`${user?.firstName} ${user?.lastName}`}
                {!user?.firstName && !user?.lastName && 'Edit Member'}
            </PageTitle>
            <MemberTabs />
            <FormProvider {...methods}>
                <div>
                    <form className="flex flex-col gap-6">
                        <div className="flex gap-6">
                            <PartnerImage />
                            <PartnerInfo />
                        </div>
                        <UserPreferences user={user?.partner} />
                    </form>
                    <div className="flex justify-end py-8">
                        <Button type="submit" onClick={() => methods.handleSubmit(onSubmit)()}>
                            {t('saveButton')}
                        </Button>
                    </div>
                </div>
            </FormProvider>
        </>
    );
};
