import { useMutation } from '@apollo/client';
import { Breadcrumbs } from 'components/Breadcrumbs/Breadcrumbs';
import { Button } from 'components/Button/Button';
import { Loader } from 'components/Loader/Loader';
import { MemberHeader } from 'components/MemberHeader/MemberHeader';
import { MemberTabs } from 'components/MemberTabs/MemberTabs';
import { SaveInfoBanner } from 'components/SaveInfoBanner/SaveInfoBanner';
import { useToast } from 'components/Toast/use-toast';
import { UserPreferences } from 'components/UserPreferences/UserPreferences';
import dayjs from 'dayjs';
import { CompanyInfo } from 'pages/NewMember/_components/CompanyInfo/CompanyInfo';
import { FamilyInfo } from 'pages/NewMember/_components/FamilyInfo/FamilyInfo';
import { MemberInfo } from 'pages/NewMember/_components/MemberInfo/MemberInfo';
import { PersonalInfo } from 'pages/NewMember/_components/PersonalInfo/PersonalInfo';
import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { AppRoutes } from 'Routes';
import { MEMBER_PUBLISH_UNPUBLISH, UPDATE_MEMBER } from 'services/users/mutation';
import { useUserStore } from 'stores/user.store';

import { ConfirmPublishModal } from './_components/ConfirmPublishModal';

export const EditMember = () => {
    const { user, getUser, loading } = useUserStore(state => state) as any;
    const [isOpen, setIsOpen] = useState(false);
    const [updateMember, { loading: updateLoading }] = useMutation(UPDATE_MEMBER);
    const [publishMember] = useMutation(MEMBER_PUBLISH_UNPUBLISH);
    const methods = useForm();
    const { t } = useTranslation('member');
    const { id } = useParams();
    const { toast } = useToast();
    const [changesDetected, setChangesDetected] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        for (const prop in methods.formState.dirtyFields) {
            if (Object.hasOwn(methods.formState.dirtyFields, prop)) {
                setChangesDetected(true);
            }
        }
    }, [methods.formState]);

    const onSubmit = async (formData: any) => {
        const { email, status, ...rest } = formData;
        // Re-format dates so backend can parse them
        rest.weddingDate = rest.weddingDate ? dayjs(rest.weddingDate, 'DD.MM.YYYY').format('YYYY-MM-DD') : null;
        rest.dateOfBirth = rest.dateOfBirth ? dayjs(rest.dateOfBirth, 'DD.MM.YYYY').format('YYYY-MM-DD') : null;
        rest.membershipStart = rest.membershipStart
            ? dayjs(rest.membershipStart, 'DD.MM.YYYY').format('YYYY-MM-DD')
            : null;
        // Map food preferences so an array of only IDs is sent
        rest.likes = rest.likes.map((l: any) => l.value);
        rest.dislikes = rest.dislikes.map((l: any) => l.value);
        rest.dietRestrictions = rest.dietRestrictions.map((l: any) => l.value);
        rest.allergies = rest.allergies.map((l: any) => l.value);
        rest.specialMembershipFee = rest.specialMembershipFee ? rest.specialMembershipFee : null;
        // Map other stuff
        if (typeof rest.company === 'object') rest.company = rest.company?.id;

        updateMember({ variables: { input: rest, user: id } })
            .then((res: any) => {
                if (res.data.memberUpdate.user) {
                    toast({
                        description: t('updateSuccess'),
                        variant: 'success',
                    });
                    getUser(res.data.memberUpdate.user.id);
                    if (id) navigate(AppRoutes.memberProfile.replace(':id', id));
                }
            })
            .catch(e => {
                handleError();
            });
    };

    const submitAs = (status: string) => {
        methods.handleSubmit(data => onSubmit({ ...data, status: status }))();
    };

    const saveChanges = () => {
        setChangesDetected(false);
        methods.handleSubmit(data => onSubmit(data))();
        methods.reset(methods.getValues());
    };

    const confirmPublishing = () => {
        setIsOpen(true);
    };

    const publishUser = async (action: string) => {
        if (changesDetected) await methods.handleSubmit(data => onSubmit(data))();
        const resp = await publishMember({ variables: { user: id, action: action } });
        if (resp.data?.userPublish?.status) {
            toast({
                description: t('publishSuccess'),
                variant: 'success',
            });
            if (id) {
                getUser(id);
                navigate(AppRoutes.memberProfile.replace(':id', id));
            }
            setIsOpen(false);
        } else {
            handleError();
        }
    };

    const handleError = () => {
        toast({
            title: t('updateErrorTitle'),
            description: t('updateErrorMessage'),
            variant: 'warning',
        });
    };

    const getButtonText = () => {
        const start = methods.watch('membershipStart');
        const start2 = dayjs(start, 'DD.MM.YYYY');

        if (dayjs(start2).isAfter(dayjs())) return t('schedulePublish');
        return t('savePublish');
    };

    if (loading && !user) return <Loader fullSize />;

    return (
        <>
            {updateLoading && <Loader fullSize onUpdate />}
            <Breadcrumbs crumbs={['Members', 'Edit Member']} />
            <MemberHeader />
            <MemberTabs />
            <div>
                {changesDetected && (
                    <SaveInfoBanner
                        onDiscard={() => {
                            setChangesDetected(false);
                            methods.reset(methods.getValues());
                        }}
                        onSave={saveChanges}
                    />
                )}

                <FormProvider {...methods}>
                    <div>
                        <form className="flex flex-col gap-6">
                            <div className="flex gap-6">
                                <MemberInfo />
                                <PersonalInfo />
                            </div>
                            <FamilyInfo />
                            <CompanyInfo />
                            <UserPreferences user={user} onRemove={() => submitAs(user?.status)} />
                        </form>
                        <div className="py-8 flex justify-end gap-2">
                            {(user?.status === 'DRAFT' || user?.status === 'SUGGESTED') && (
                                <Button
                                    onClick={() => confirmPublishing()}
                                    disabled={updateLoading}
                                    type="submit"
                                    variant="outline"
                                >
                                    {getButtonText()}
                                </Button>
                            )}
                            <Button type="submit" onClick={() => submitAs(user?.status)} disabled={updateLoading}>
                                {t('saveChanges')}
                            </Button>
                        </div>
                    </div>
                </FormProvider>
            </div>
            <ConfirmPublishModal
                isOpen={isOpen}
                onClose={() => setIsOpen(false)}
                onConfirm={() => publishUser('PUBLISH')}
            />
        </>
    );
};
