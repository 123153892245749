import { Button } from 'components/Button/Button';
import { Modal } from 'components/Modal/Modal';
import { Portal } from 'components/Portal/Portal';
import { useTranslation } from 'react-i18next';

type EventDeleteModalProps = {
    isOpen: boolean;
    onClose: () => void;
    onConfirm: () => void;
};
export const EventDeleteModal: React.FC<EventDeleteModalProps> = ({ isOpen, onClose, onConfirm }) => {
    const { t } = useTranslation('events');
    return (
        <Portal>
            <Modal isOpen={isOpen} onClose={onClose} uncloseable maxWidth={512}>
                <div className="flex flex-col gap-4">
                    <div>
                        <div className="text-xl font-semibold text-zinc-700 mb-2">{t('eventDeleteModalTitle')}</div>
                        <div className="text-sm text-zinc-500">{t('eventDeleteModalSubtitle')}</div>
                    </div>
                    <div className="flex justify-end gap-2">
                        <Button variant="outline" onClick={onClose}>
                            {t('cancelModalButton')}
                        </Button>
                        <Button onClick={onConfirm}>{t('sureModalButton')}</Button>
                    </div>
                </div>
            </Modal>
        </Portal>
    );
};
