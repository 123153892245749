import { cn } from 'lib/utils';
import { useTranslation } from 'react-i18next';

export const TerminatedLabel = ({ status, className }: any) => {
    const { t } = useTranslation('translation');
    if (status !== 'TERMINATED') return null;
    return (
        <div className={cn('bg-orange-700 text-white px-1 font-medium text-xs h-[20px] leading-5', className)}>
            {t('filters.terminated').toLowerCase()}
        </div>
    );
};
