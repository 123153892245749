import { Avatar } from 'components/Avatar/Avatar';
import { Balance } from 'components/Balance/Balance';
import { Button } from 'components/Button/Button';
import { Card } from 'components/Card/Card';
import { Icon } from 'components/Icon/Icon';
import { Label } from 'components/Label/Label';
import { PreviewUploadImage } from 'components/PreviewUploadImage/PreviewUploadImage';
import { useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useCompanyStore } from 'stores/company.store';

export const CompanyProfile = () => {
    const company = useCompanyStore(state => state.company);
    const fileInputRef = useRef<any>();
    const { setValue } = useFormContext();
    const [image, setImage] = useState<any>(null);
    const { t } = useTranslation('company');

    const handleChange = (event: any) => {
        if (event.target.files.length <= 0) return;
        setValue('image', event.target.files[0]);
        setImage(URL.createObjectURL(event.target.files[0]));
    };
    return (
        <div>
            <Card className="flex flex-col gap-4 pb-2 relative">
                <div className="relative">
                    {/* <Avatar image={company?.image} className={cn(company?.status === 'BLOCKED' && 'opacity-50')} /> */}
                    <Avatar image={company?.image} />
                    <PreviewUploadImage image={image} />
                    <Button
                        type="button"
                        className="absolute bottom-2 right-2 w-8 h-8"
                        onClick={() => fileInputRef.current.click()}
                    >
                        <Icon name="upload" />
                    </Button>
                    <input
                        multiple={false}
                        type="file"
                        hidden
                        onChange={handleChange}
                        ref={fileInputRef}
                        accept="image/png, image/jpeg, image/jpg, image/heic, image/heif"
                    />
                </div>
                <div>
                    <Label>{t('companyId')}</Label>
                    <div className="text-rose-700">{company?.companyId || '-'}</div>
                </div>
                <div>
                    <Label>{t('accountType')}</Label>
                    <div className="text-rose-700">Company</div>
                </div>
                <div className="flex-1">
                    <Label>{t('balance')}</Label>
                    <div className="text-rose-700">
                        <Balance balance={company?.balance} />
                    </div>
                </div>
            </Card>
        </div>
    );
};
