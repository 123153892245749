import { Tab, Tabs } from 'components/Tabs/Tabs';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { AppRoutes } from 'Routes';

export const CompanyTabs = () => {
    const { id } = useParams();
    const { t } = useTranslation('translation');

    if (!id) return null;

    return (
        <Tabs>
            <Tab to={AppRoutes.companyProfile.replace(':id', id)} replace>
                {t('tabs.profile')}
            </Tab>
            <Tab to={AppRoutes.companyContacts.replace(':id', id)} replace end>
                {t('tabs.contacts')}
            </Tab>
            {/* <Tab>Invoices</Tab> */}
        </Tabs>
    );
};
