import { FormError } from 'components/FormError/FormError';
import { Input } from 'components/Input/Input';
import { Label } from 'components/Label/Label';
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

export const AssistantContact = () => {
    const { t } = useTranslation('forms');
    const {
        register,
        unregister,
        formState: { errors },
    } = useFormContext();

    useEffect(() => {
        return () => unregister();
    }, [unregister]);

    return (
        <div className="flex flex-col gap-6">
            <div className="flex gap-6">
                <div className="flex-1">
                    <Label>{t('firstName')}</Label>
                    <Input
                        {...register('name', {
                            required: t('firstNameRequired'),
                        })}
                        placeholder={t('firstName')}
                        error={!!errors.name}
                    />
                    <FormError error={errors.name} />
                </div>
                <div className="flex-1">
                    <Label>{t('lastName')}</Label>
                    <Input
                        {...register('lastName', {
                            required: t('lastNameRequired'),
                        })}
                        placeholder={t('lastName')}
                        error={!!errors.lastName}
                    />
                    <FormError error={errors.lastName} />
                </div>
            </div>

            <div className="flex gap-6">
                <div className="flex-1">
                    <Label>{t('personalPhone')}</Label>
                    <Input
                        {...register('phoneNumber', {
                            required: t('phoneNumberRequired'),
                        })}
                        placeholder={t('personalPhone')}
                        error={!!errors.phoneNumber}
                    />
                    <FormError error={errors.phoneNumber} />
                </div>
                <div className="flex-1">
                    <Label>{t('businessPhone')}</Label>
                    <Input {...register('secondaryPhoneNumber')} placeholder={t('businessPhone')} />
                </div>
            </div>

            <div className="flex gap-6">
                <div className="flex-1">
                    <Label>{t('email')}</Label>
                    <Input
                        {...register('email', { required: t('emailRequired') })}
                        placeholder={t('email')}
                        error={!!errors.email}
                    />
                    <FormError error={errors.email} />
                </div>
                <div className="flex-1">
                    <Label>{t('secondaryEmail')}</Label>
                    <Input {...register('secondaryEmail')} placeholder={t('secondaryEmail')} />
                </div>
            </div>
        </div>
    );
};
