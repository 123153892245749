import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

const language = localStorage.getItem('czrLanguage') || 'en';

i18n.use(Backend).use(initReactI18next).init({
    saveMissing: true,
    fallbackLng: 'en',
    lng: language,
});

export default i18n;
