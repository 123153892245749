import { Button } from 'components/Button/Button';
import { FormError } from 'components/FormError/FormError';
import { Input } from 'components/Input/Input';
import { Modal } from 'components/Modal/Modal';
import { Portal } from 'components/Portal/Portal';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

export const UserDeleteModal = ({ user, isOpen, onClose, onDelete }: any) => {
    const [confirm, setConfirm] = useState(false);
    const [error, setError] = useState(false);
    const [name, setName] = useState('');
    const { t } = useTranslation('member', { keyPrefix: 'userSettings' });

    const confirmDelete = () => {
        if (name.toLowerCase() !== user?.firstName.toLowerCase()) {
            setError(true);
        } else {
            onDelete();
        }
    };

    return (
        <Portal>
            <Modal isOpen={isOpen} onClose={onClose} maxWidth={512} uncloseable>
                {!confirm && (
                    <div className="flex flex-col gap-4">
                        <div>
                            <div className="text-xl font-semibold text-zinc-700 mb-2">{t('deleteModalTitle')}</div>
                            <div className="text-sm text-zinc-500">{t('deleteModalSubtitle')}</div>
                        </div>
                        <div className="flex justify-end gap-2">
                            <Button variant="outline" onClick={onClose}>
                                {t('cancelModalButton')}
                            </Button>
                            <Button onClick={() => setConfirm(true)}> {t('yesModalButton')}</Button>
                        </div>
                    </div>
                )}
                {confirm && (
                    <div className="flex flex-col gap-4">
                        <div>
                            <div className="text-xl font-semibold text-zinc-700 mb-2">
                                {t('deleteModalConfirmTitle')}
                            </div>
                            <div className="text-sm text-zinc-500">{t('deleteModalConfirmSubtitle')}</div>
                        </div>
                        <div>
                            <Input
                                placeholder={t('deleteModalConfirmPlaceholder')}
                                className="uppercase placeholder:normal-case"
                                autoFocus
                                onChange={(e: any) => {
                                    setName(e.target.value);
                                    setError(false);
                                }}
                                error={error}
                            />
                            {error && <FormError error={{ message: t('deleteModalConfirmError') }} />}
                        </div>
                        <div className="flex justify-end gap-2">
                            <Button
                                variant="outline"
                                onClick={() => {
                                    onClose();
                                    setConfirm(false);
                                }}
                            >
                                {t('cancelModalButton')}
                            </Button>
                            <Button onClick={() => confirmDelete()} disabled={!name}>
                                {' '}
                                {t('confirmModalButton')}
                            </Button>
                        </div>
                    </div>
                )}
            </Modal>
        </Portal>
    );
};
