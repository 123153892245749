import { useMutation } from '@apollo/client';
import { Button } from 'components/Button/Button';
import { Loader } from 'components/Loader/Loader';
import { Modal } from 'components/Modal/Modal';
import { Portal } from 'components/Portal/Portal';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { EXPORT_BIBLE } from 'services/users/mutation';

export const GenerateBible = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [loading, setLoading] = useState(true);
    const [url, setUrl] = useState(null);
    const { t } = useTranslation('member');
    const [generateBible] = useMutation(EXPORT_BIBLE);

    const closeModal = () => {
        setIsOpen(false);
        setUrl(null);
    };

    const generate = async () => {
        setIsOpen(true);
        setLoading(true);
        try {
            const { data } = await generateBible();
            if (data.exportMemberBible?.file) {
                setUrl(data.exportMemberBible?.file);
            }
        } catch (_) {
            // TODO: handle error
        } finally {
            setLoading(false);
        }
    };

    const LoadingContent = () => {
        return (
            <>
                <div>
                    <div className="text-xl font-semibold text-zinc-700 mb-2">{t('bibleGeneratingTitle')}</div>
                    <div className="text-sm text-zinc-500">{t('bibleGeneratingText')}</div>
                </div>
                <div className="flex items-center justify-center">
                    <Loader />
                </div>
            </>
        );
    };

    const LoadedContent = () => {
        return (
            <>
                <div>
                    <div className="text-xl font-semibold text-zinc-700 mb-2">{t('bibleGeneratedTitle')}</div>
                    <div className="text-sm text-zinc-500">{t('bibleGeneratedText')}</div>
                </div>
                <div className="flex justify-end gap-2">
                    <Button variant="outline" onClick={closeModal}>
                        {t('bibleCancelButton')}
                    </Button>
                    {url && (
                        <Button asChild>
                            <Link to={url} download target="_blank">
                                {t('bibleDownloadButton')}
                            </Link>
                        </Button>
                    )}
                </div>
            </>
        );
    };

    const ErrorContent = () => {
        return (
            <>
                <div>
                    <div className="text-xl font-semibold text-zinc-700 mb-2">{t('bibleErrorTitle')}</div>
                    <div className="text-sm text-zinc-500">{t('bibleErrorText')}</div>
                </div>
                <div className="flex justify-end gap-2">
                    <Button onClick={closeModal}>{t('bibleCloseButton')}</Button>
                </div>
            </>
        );
    };

    return (
        <>
            <Button variant="outline" onClick={() => generate()}>
                {t('bibleGenerateButton')}
            </Button>
            <Portal>
                <Modal isOpen={isOpen} onClose={closeModal} maxWidth={512}>
                    <div className="flex flex-col gap-4">
                        {loading && !url && <LoadingContent />}
                        {!loading && url && <LoadedContent />}
                        {!loading && !url && <ErrorContent />}
                    </div>
                </Modal>
            </Portal>
        </>
    );
};
