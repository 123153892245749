import { Container } from 'components/Container/Container';
import { Loader } from 'components/Loader/Loader';
import { useEffect } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import { useStaffStore } from 'stores/staff.store';

export const StaffLayout = () => {
    const { id } = useParams();
    const { loading, getMember, setMember } = useStaffStore(state => state);

    useEffect(() => {
        if (id) getMember(id);
        return () => setMember(null);
    }, [id, getMember, setMember]);

    if (loading) return <Loader fullSize />;

    return (
        <Container>
            <Outlet />
        </Container>
    );
};
