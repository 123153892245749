import { useMutation } from '@apollo/client';
import { Loader } from 'components/Loader/Loader';
import { Modal } from 'components/Modal/Modal';
import { Portal } from 'components/Portal/Portal';
import { useToast } from 'components/Toast/use-toast';
import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { UPDATE_CONTACT } from 'services/contacts/mutation';
import { useCompanyStore } from 'stores/company.store';
import { useUserStore } from 'stores/user.store';

import { AssistantContact } from './_components/AssistantContact';
import { CompanyContact } from './_components/CompanyContact';
import { ContactFormActions } from './_components/ContactFormActions';
import { MemberContact } from './_components/MemberContact';
import { OtherContact } from './_components/OtherContact';

export const EditContactModal = ({ onSave, onClose, contact, member, company }: any) => {
    const methods = useForm();
    const [updateContact, { loading: loadingContact }] = useMutation(UPDATE_CONTACT);
    const { toast } = useToast();
    const { t } = useTranslation('forms');
    const getUser = useUserStore(state => state.getUser);
    const getCompany = useCompanyStore(state => state.getCompany);

    useEffect(() => {
        if (contact) {
            methods.reset(contact);
            methods.setValue('salutation', contact.salutation);
            methods.setValue('country', contact.country.code);
        }
    }, [contact, methods]);

    const onSubmit = (data: any) => {
        Object.keys(data).forEach(k => (data[k] === '' ? (data[k] = null) : null));
        const { id, relatedMember, type, status, label, relatedCompany, __typename, ...rest } = data;

        updateContact({
            variables: {
                input: rest,
                contact: data.id,
            },
        })
            .then((resp: any) => {
                if (resp.errors?.length > 0) {
                    toast({
                        description: resp.errors[0].message,
                        variant: 'warning',
                    });
                }
                if (resp.data.contactUpdate) {
                    toast({
                        description: 'Contact updated successfully',
                        variant: 'success',
                    });
                    onClose();
                    if (member) getUser(member);
                    if (company) getCompany(company);
                    if (onSave) onSave();
                }
            })
            .catch(e =>
                toast({
                    description: 'Something went wrong. Please try again.',
                    variant: 'warning',
                }),
            );
    };

    if (!contact) return null;

    return (
        <Portal>
            <Modal isOpen={!!contact} onClose={onClose} maxWidth={800}>
                <div className="flex flex-col gap-8">
                    <div>
                        <div className="text-xl font-semibold text-zinc-700 mb-2">{t('editContactTitle')}</div>
                    </div>
                    {loadingContact && <Loader onUpdate />}
                    <FormProvider {...methods}>
                        <form onSubmit={methods.handleSubmit(onSubmit)}>
                            {contact.type === 'MEMBER' && <MemberContact />}
                            {contact.type === 'COMPANY' && <CompanyContact />}
                            {contact.type === 'PARTNER' && <MemberContact />}
                            {contact.type === 'ASSISTANT' && <AssistantContact />}
                            {contact.type === 'OTHER' && <OtherContact />}

                            <ContactFormActions onClose={onClose} />
                        </form>
                    </FormProvider>
                </div>
            </Modal>
        </Portal>
    );
};
