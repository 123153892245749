import { Button } from 'components/Button/Button';
import Checkbox from 'components/Checkbox/Checkbox';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

export const ContactFormActions = ({ onClose }: any) => {
    const { t } = useTranslation('forms');
    const { control } = useFormContext();
    return (
        <div className="flex mt-8 justify-between">
            <div>
                <Controller
                    control={control}
                    name="billToThisContact"
                    defaultValue={false}
                    render={({ field: { onChange, value } }) => (
                        <Checkbox onChange={onChange} checked={value}>
                            {t('billToThisContact')}
                        </Checkbox>
                    )}
                />
            </div>
            <div className="flex justify-end gap-2">
                <Button variant="outline" type="button" onClick={onClose}>
                    {t('cancelButton')}
                </Button>
                <Button type="submit">{t('confirmButton')}</Button>
            </div>
        </div>
    );
};
