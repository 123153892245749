import { Toaster } from 'components/Toast/Toaster';
import React from 'react';
import { Container as ModalContainer } from 'react-modal-promise';
import { BrowserRouter } from 'react-router-dom';
import './i18n';

import { Routes } from './Routes';

import './main.css';

const App = () => {
    return (
        <>
            <BrowserRouter>
                <Routes />
            </BrowserRouter>
            <div id="popup-portal"></div>

            <Toaster />
            <ModalContainer />
        </>
    );
};

export default App;
