import { Button } from 'components/Button/Button';
import { Modal } from 'components/Modal/Modal';
import { Portal } from 'components/Portal/Portal';
import { useTranslation } from 'react-i18next';
import { UserType } from 'types/user';

type TopupInvoiceModalProps = {
    isOpen: boolean;
    onClose: () => void;
    onConfirm: () => void;
    member: UserType | null;
    amount: number;
    loading: boolean;
};
export const TopupConfirmModal: React.FC<TopupInvoiceModalProps> = ({
    isOpen,
    onClose,
    onConfirm,
    member,
    amount,
    loading,
}) => {
    const { t } = useTranslation('dashboard');
    return (
        <Portal>
            <Modal isOpen={isOpen} onClose={onClose} maxWidth={512}>
                <div className="flex flex-col gap-6">
                    <div>
                        <div className="text-xl font-semibold text-zinc-700 mb-2">{t('topupConfirmModalTitle')}</div>
                        <div
                            className="text-sm text-zinc-500"
                            dangerouslySetInnerHTML={{
                                __html: t('topupConfirmModalText', {
                                    name: `${member?.firstName} ${member?.lastName}`,
                                    amount,
                                    newBalance: Number(amount) + Number(member?.balance.amount),
                                }),
                            }}
                        />
                    </div>
                    <div className="flex justify-end gap-2">
                        <Button variant="outline" onClick={onClose}>
                            {t('cancelButton')}
                        </Button>
                        <Button onClick={onConfirm} disabled={loading}>
                            {t('confirmSendInvoiceButton')}
                        </Button>
                    </div>
                </div>
            </Modal>
        </Portal>
    );
};
