import { useTranslation } from 'react-i18next';

export const SuspendedLabel = ({ status }: any) => {
    const { t } = useTranslation('translation');
    if (status !== 'SUSPENDED') return null;
    return (
        <div className="bg-orange-700 text-white px-1 font-medium text-xs h-[20px] leading-5 absolute bottom-2 right-2">
            {t('filters.suspended').toLowerCase()}
        </div>
    );
};
