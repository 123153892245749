import { Button } from 'components/Button/Button';
import { Input } from 'components/Input/Input';
import { Label } from 'components/Label/Label';
import { Modal } from 'components/Modal/Modal';
import { Portal } from 'components/Portal/Portal';
import { FieldValues, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { UserType } from 'types/user';

type TopupInvoiceModalProps = {
    isOpen: boolean;
    onClose: () => void;
    member: UserType | null;
    onSendInvoice: (amount: number) => void;
};
export const TopupInvoiceModal: React.FC<TopupInvoiceModalProps> = ({ isOpen, onClose, member, onSendInvoice }) => {
    const { t } = useTranslation('dashboard');
    const { register, watch, handleSubmit, reset } = useForm();

    const onSubmit = (formData: FieldValues) => {
        onSendInvoice(formData.amount);
        reset();
    };

    const closeModal = () => {
        onClose();
        reset();
    };
    return (
        <Portal>
            <Modal isOpen={isOpen} onClose={onClose} maxWidth={512} uncloseable>
                <form className="flex flex-col gap-6" onSubmit={handleSubmit(onSubmit)}>
                    <div>
                        <div className="text-xl font-semibold text-zinc-700 mb-2">
                            {t('topupModalTitle', { name: `${member?.firstName} ${member?.lastName}` })}
                        </div>
                        <div className="text-sm text-zinc-500">{t('topupModalText')}</div>
                    </div>
                    <div>
                        <Label>{t('topupInvoiceAmountLabel')}</Label>
                        <Input
                            placeholder={t('topupInvoiceAmountPlaceholder')}
                            type="number"
                            {...register('amount')}
                            autoFocus
                        />
                    </div>
                    <div className="flex justify-end gap-2">
                        <Button variant="outline" onClick={closeModal} type="button">
                            {t('closeButton')}
                        </Button>
                        <Button disabled={!watch('amount') || watch('amount') <= 0}>{t('sendInvoiceButton')}</Button>
                    </div>
                </form>
            </Modal>
        </Portal>
    );
};
