import { Avatar } from 'components/Avatar/Avatar';
import { Card } from 'components/Card/Card';
import { Label } from 'components/Label/Label';
import { UserSettings } from 'components/UserSettings/UserSettings';
import { cn } from 'lib/utils';
import { useTranslation } from 'react-i18next';
import { AppRoutes } from 'Routes';
import { useStaffStore } from 'stores/staff.store';

export const StaffInfoPreview = () => {
    const { member, getMember } = useStaffStore(state => state);
    const { t } = useTranslation('staff');

    return (
        <div>
            <Card className="flex flex-col gap-4 pb-2 relative">
                <div className="relative">
                    <Avatar image={member?.image} className={cn(member?.status === 'BLOCKED' && 'opacity-50')} />
                </div>
                <div>
                    <Label>{t('accountType')}</Label>
                    <div className="text-rose-700">{member?.memberType ? t(member?.memberType) : t('STAFF')}</div>
                </div>
                <UserSettings
                    actions={['BLOCK', 'DELETE']}
                    user={member}
                    onChange={() => getMember(member?.id)}
                    navigateTo={AppRoutes.staff}
                />
            </Card>
        </div>
    );
};
