import { Button } from 'components/Button/Button';
import { Modal } from 'components/Modal/Modal';
import { Portal } from 'components/Portal/Portal';
import { useTranslation } from 'react-i18next';

type ConfirmPublishModalProps = {
    isOpen: boolean;
    onClose: () => void;
    onConfirm: () => void;
};

export const ConfirmPublishModal: React.FC<ConfirmPublishModalProps> = ({ isOpen, onClose, onConfirm }) => {
    const { t } = useTranslation('member');

    return (
        <Portal>
            <Modal isOpen={isOpen} onClose={onClose} maxWidth={512} className="flex flex-col gap-4">
                <div>
                    <div className="text-xl font-semibold text-zinc-700 mb-2 pr-6">{t('publishModalTitle')}</div>
                    <div className="text-sm text-zinc-500">{t('publishModalSubtitle')}</div>
                </div>
                <div className="flex justify-end gap-2">
                    <Button variant="outline" onClick={onClose}>
                        {t('cancelPublish')}
                    </Button>
                    <Button onClick={onConfirm}>{t('savePublish')}</Button>
                </div>
            </Modal>
        </Portal>
    );
};
