import { gql } from '@apollo/client';

export const USER_PREFERENCES = gql`
    fragment Preferences on Partner {
        likes(first: 100) {
            edges {
                node {
                    id
                    name
                }
            }
        }
        dislikes(first: 100) {
            edges {
                node {
                    id
                    name
                }
            }
        }
        dietRestrictions {
            edges {
                node {
                    id
                    name
                }
            }
        }
        allergies {
            edges {
                node {
                    id
                    name
                }
            }
        }
        preferenceComment
    }
`;

export const USER_INVOICES = gql`
    fragment Invoices on User {
        invoices(first: 1) {
            edges {
                node {
                    id
                    invoiceId
                    status
                    dueDate
                    createdAt
                    document {
                        url
                    }
                }
            }
        }
    }
`;

export const USER_PETS = gql`
    fragment Pets on User {
        pets {
            edges {
                node {
                    id
                    name
                    type
                    breed
                }
            }
        }
    }
`;

export const USER_CHILDREN = gql`
    fragment Children on User {
        children {
            edges {
                node {
                    id
                    firstName
                    lastName
                    dateOfBirth
                    gender
                }
            }
        }
    }
`;

export const USER_CONTACTS = gql`
    fragment UserContacts on User {
        contacts(first: 100) {
            edges {
                node {
                    id
                    name
                    email
                    secondaryEmail
                    address
                    zipCode
                    poBox
                    city
                    country {
                        name
                        code
                    }
                    lastName
                    type
                    label
                    vatNumber
                    status
                    salutation
                    phoneNumber
                    secondaryPhoneNumber
                    billToThisContact
                    relatedMember {
                        id
                        firstName
                        lastName
                    }
                }
            }
        }
    }
`;
export const PARTNER_DETAILS = gql`
    ${USER_PREFERENCES}
    fragment Partner on User {
        partner {
            id
            firstName
            lastName
            image {
                url
                alt
            }
            isSpouse
            salutation
            dateOfBirth
            company
            jobTitle

            ...Preferences
        }
    }
`;

export const ANNIVERSARY_DETAILS = gql`
    fragment AnniversaryDetails on Anniversary {
        personType
        id
        firstName
        lastName
        image {
            url
            alt
        }
        date
        daysNumber
        gender
        yearNumber
        anniversaryType
        relatedMember {
            id
            firstName
            lastName
        }
    }
`;
