import { gql } from '@apollo/client';

export const CONTACTS = gql`
    query contacts($search: String, $first: Int, $offset: Int, $type: [ContactType]) {
        contacts(search: $search, first: $first, offset: $offset, type_In: $type) {
            edges {
                node {
                    id
                    name
                    lastName
                    salutation
                    type
                    phoneNumber
                    secondaryPhoneNumber
                    email
                    secondaryEmail
                    address
                    zipCode
                    poBox
                    city
                    country {
                        name
                        code
                    }
                    relatedMember {
                        id
                        firstName
                        lastName
                    }
                    relatedCompany {
                        id
                        name
                    }
                }
            }
            totalCount
            pageInfo {
                hasNextPage
            }
        }
    }
`;
