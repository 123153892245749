import { clsx, type ClassValue } from 'clsx';
import dayjs from 'dayjs';
import { twMerge } from 'tailwind-merge';

// var customParseFormat = require('dayjs/plugin/customParseFormat');
// dayjs.extend(customParseFormat);

export const EMAIL_REGEX = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,8}$/;
export const PHONE_REGEX = /^\+?\d{8,}$/;
export const PASSWORD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z.*\W\d]{8,}$/; // min 8 chars, 1 uppercase, 1 lowercase, 1 number

export function cn(...inputs: ClassValue[]) {
    return twMerge(clsx(inputs));
}

export const normalizeArray = (arr: any) => {
    return arr?.edges?.map((item: any) => item.node);
};

export const validateDate = (date: string) => {
    if (!date) return true;
    // const d = date.split('.');
    // if (!dayjs(`${d[1]}-${d[0]}-${d[2]}`).isValid()) {
    if (!dayjs(date, 'DD.MM.YYYY', true).isValid()) {
        return false;
    }
    return true;
};
