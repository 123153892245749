import { client } from 'lib/apollo';

import { COMPANY_BY_ID } from './query';

export const getCompany = (id: string) =>
    client.query({
        query: COMPANY_BY_ID,
        variables: {
            id,
        },
    });
