import { client } from 'lib/apollo';

import { LOGIN, REFRESH_TOKEN, SEND_OTP } from './mutation';

export const userLogin = (email: string, password: string, otp: string) =>
    client.mutate({
        mutation: LOGIN,
        variables: {
            email,
            password,
            otp
        },
    });

export const refreshToken = (token: string) =>
    client.mutate({
        mutation: REFRESH_TOKEN,
        variables: {
            token,
        },
    });

export const sendOtp = (email: string, password: string) =>
    client.mutate({
        mutation: SEND_OTP,
        variables: {
            email,
            password,
        },
    });
