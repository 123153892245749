import { Breadcrumbs } from 'components/Breadcrumbs/Breadcrumbs';
import { Icon } from 'components/Icon/Icon';
import { PreviewHeader } from 'components/PreviewHeader/PreviewHeader';
import { useNavigate } from 'react-router-dom';
import { useCompanyStore } from 'stores/company.store';

import { CompanyPreviewAvatar } from './_components/CompanyPreviewAvatar';
import { CompanyPreviewbalance } from './_components/CompanyPreviewBalance';
import { CompanyPreviewInfo } from './_components/CompanyPreviewInfo';
import { CompanyPreviewMembers } from './_components/CompanyPreviewMembers';

export const CompanyPreview = () => {
    const company = useCompanyStore(state => state.company);
    const navigate = useNavigate();

    return (
        <>
            <PreviewHeader />
            <Breadcrumbs crumbs={['members', 'companies', company?.name]} className="text-white" />
            <div className="text-white text-2xl mt-8 mb-4">
                <Icon name="back" onClick={() => navigate(-1)} className="hover:cursor-pointer" />
            </div>

            <div className="flex gap-4">
                <CompanyPreviewAvatar />
                <div className="flex flex-col gap-4 flex-1">
                    <CompanyPreviewbalance />
                    <CompanyPreviewInfo />
                    <CompanyPreviewMembers />
                </div>
            </div>
        </>
    );
};
