import { Button } from 'components/Button/Button';
import { Input } from 'components/Input/Input';
import { InputOTP, InputOTPGroup, InputOTPSlot } from 'components/InputOTP/InputOPT';
import { Label } from 'components/Label/Label';
import { useToast } from 'components/Toast/use-toast';
import { Typography } from 'components/Typograhpy/Typography';
import { REGEXP_ONLY_DIGITS } from 'input-otp';
import Cookies from 'js-cookie';
import { EMAIL_REGEX, cn } from 'lib/utils';
import { Loader2 } from 'lucide-react';
import { useEffect, useState } from 'react';
import Countdown from 'react-countdown';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { AppRoutes } from 'Routes';
import { sendOtp, userLogin } from 'services/auth';

import styles from './Login.module.scss';
let NOW = Date.now();

export const Login = () => {
    const [formCredentials, setFormCredentials] = useState<any>(null);
    const [otpValue, setOtpValue] = useState<any>(null);
    const [sendOTP, setSendOTP] = useState(false);
    const [phoneNo, setPhoneNo] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [showCounter, setShowCounter] = useState(true);
    const navigate = useNavigate();
    const { toast } = useToast();
    const { t } = useTranslation('auth');
    const {
        register,
        handleSubmit,
        setError,
        formState: { errors, isValid },
    } = useForm({
        mode: 'onChange',
    });

    useEffect(() => {
        const token = Cookies.get('token');
        if (token) {
            navigate(AppRoutes.dashboard, { replace: true });
        }
    }, [navigate]);

    const resendCode = () => {
        NOW = Date.now();
        setShowCounter(true);
        onSubmit(formCredentials);
    };

    const onSubmit = async (formData: any) => {
        setFormCredentials(formData);
        setIsLoading(true);
        const otp: any = await sendOtp(formData.email, formData.password);
        if (otp.errors?.length > 0) {
            setError('otp', { type: 'custom', message: otp.errors[0].message });
            toast({
                description: otp.errors[0].message,
                variant: 'warning',
            });
        }
        if (otp.data?.sendOtpCode?.success) {
            NOW = Date.now();
            setPhoneNo(otp.data?.sendOtpCode?.phoneNumber);
            setSendOTP(true);
        }

        setIsLoading(false);
    };

    const onOtpSubmit = async () => {
        setIsLoading(true);
        try {
            const { errors, data }: any = await userLogin(formCredentials?.email, formCredentials?.password, otpValue);
            if (errors?.length > 0) {
                setError('otp', { type: 'custom', message: errors[0].message });
            }
            if (data.tokenAuth) {
                Cookies.set('token', data.tokenAuth?.token);
                Cookies.set('refreshToken', data.tokenAuth.refreshToken, { expires: 19 });
                navigate(AppRoutes.dashboard);
            }
        } catch (e) {
            console.error(e);
        } finally {
            setIsLoading(false);
        }
    };

    const countdownRenderer = ({ seconds }: { seconds: number }) => {
        return (
            <div>
                {t('resendCodeIn')}
                {seconds < 10 ? `0${seconds}` : seconds}
            </div>
        );
    };

    return (
        <>
            {!sendOTP ? (
                <form onSubmit={handleSubmit(onSubmit)} className={cn(styles.content, 'flex flex-col gap-8')}>
                    <Typography.H3 className={cn('text-zinc-700')}>{t('loginTitle')}</Typography.H3>
                    <div className={cn('flex flex-col gap-6')}>
                        <div>
                            <Label className={cn('text-zinc-700')}>{t('email')}</Label>
                            <Input
                                placeholder={t('email')}
                                type="email"
                                {...register('email', {
                                    required: true,
                                    pattern: EMAIL_REGEX,
                                })}
                                error={!!errors.otp}
                            />
                        </div>
                        <div>
                            <Label className={cn('text-zinc-700')}>{t('password')}</Label>
                            <Input
                                placeholder={t('password')}
                                type="password"
                                {...register('password', { required: true })}
                                error={!!errors.otp}
                            />
                            {errors.otp && (
                                <div className={cn('text-orange-700 text-sm mt-2')}>{errors.otp.message as any}</div>
                            )}
                            <div className={cn('flex justify-end text-zinc-500')}>
                                <a href={AppRoutes.forgotPassword}>{t('forgotPassword')}</a>
                            </div>
                        </div>
                    </div>
                    <div className={cn('flex justify-end')}>
                        <Button type="submit" disabled={!isValid || isLoading}>
                            {isLoading && <Loader2 className="mr-2 h-4 w-4 animate-spin" />}
                            {t('loginButton')}
                        </Button>
                    </div>
                </form>
            ) : (
                <form className={cn(styles.content, 'flex flex-col gap-8')} onSubmit={e => e.preventDefault()}>
                    <Typography.H3 className={cn('text-zinc-700')}>{t('otpTitle')}</Typography.H3>
                    <div className={cn('flex flex-col gap-4')}>
                        <p>
                            {t('otpSubtitle')} {phoneNo}.
                        </p>
                        <InputOTP maxLength={6} pattern={REGEXP_ONLY_DIGITS} onChange={val => setOtpValue(val)}>
                            <InputOTPGroup>
                                <InputOTPSlot index={0} error={!!errors.otp} />
                                <InputOTPSlot index={1} error={!!errors.otp} />
                                <InputOTPSlot index={2} error={!!errors.otp} />
                                <InputOTPSlot index={3} error={!!errors.otp} />
                                <InputOTPSlot index={4} error={!!errors.otp} />
                                <InputOTPSlot index={5} error={!!errors.otp} />
                            </InputOTPGroup>
                        </InputOTP>
                        {errors.otp && <div className={cn('text-orange-700 text-sm')}>{errors.otp.message as any}</div>}
                        {showCounter ? (
                            <Countdown
                                date={NOW + 59000}
                                renderer={countdownRenderer}
                                onComplete={() => setShowCounter(false)}
                            />
                        ) : (
                            <div
                                className={cn('text-rose-700 underline hover:cursor-pointer hover:text-rose-900')}
                                onClick={() => resendCode()}
                            >
                                {t('sendNewCode')}
                            </div>
                        )}
                    </div>
                    <div className={cn('flex justify-end')}>
                        <Button onClick={onOtpSubmit} disabled={!otpValue || isLoading}>
                            {isLoading && <Loader2 className="mr-2 h-4 w-4 animate-spin" />}
                            {t('otpButton')}
                        </Button>
                    </div>
                </form>
            )}
        </>
        // </section>
    );
};
