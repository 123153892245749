import clsx from 'clsx';
import Checkbox from 'components/Checkbox/Checkbox';
import { Icon } from 'components/Icon/Icon';
import { cn } from 'lib/utils';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import styles from './StatusFilter.module.scss';

type StatusFilterProps = {
    options: string[];
    label?: string;
    filterKey?: string;
};
export const StatusFilter: React.FC<StatusFilterProps> = ({ options, label, filterKey = 'status' }) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [active, setActive] = useState(false);
    const statusRef = useRef<any>(null);
    const { t } = useTranslation('translation', { keyPrefix: 'filters' });

    const handleClickOutside = (event: any) => {
        if (statusRef.current && !statusRef.current.contains(event.target)) {
            setActive(false);
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleClickOutside, true);

        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, []);

    const toggleStatus = (status: string) => {
        const params = new URLSearchParams(searchParams);
        const list = params.get(filterKey)?.split(',') || [];
        if (list.includes(status)) {
            list.splice(list.indexOf(status), 1);
        } else {
            list.push(status);
        }
        if (list.length > 0) {
            params.set(filterKey, list.join(','));
        } else {
            params.delete(filterKey);
        }
        setSearchParams(params);
    };

    const clearFilter = (status: string) => {
        const params = new URLSearchParams(searchParams);
        const list = params.get(filterKey)?.split(',') || [];
        list.splice(list.indexOf(status), 1);
        if (list.length > 0) {
            params.set(filterKey, list.join(','));
        } else {
            params.delete(filterKey);
        }
        setSearchParams(params);
        setActive(false);
    };

    const clearAllFilters = () => {
        const params = new URLSearchParams(searchParams);
        params.delete(filterKey);
        setSearchParams(params);
        setActive(false);
    };

    return (
        <div className={clsx(styles.statusFilter, active && styles.active)} ref={statusRef}>
            <div
                className={cn(
                    styles.dashed,
                    'flex items-center gap-3 text-black outline-zinc-300 py-1 px-3 text-sm h-8 hover:cursor-pointer',
                )}
            >
                <div className="flex gap-3 items-center" onClick={() => setActive(!active)}>
                    <Icon name="carret-down" />
                    <div>{label || t('statusFilter')}</div>
                </div>
                {searchParams.get(filterKey) && (
                    <>
                        <div className={styles.divider}></div>
                        <div className={styles.selectedFiltersList}>
                            {searchParams
                                .get(filterKey)
                                ?.split(',')
                                .map(status => (
                                    <div className={styles.selectedFilter} key={status}>
                                        {t(status.toLowerCase())}
                                        <Icon name="close" onClick={() => clearFilter(status)} />
                                    </div>
                                ))}
                        </div>
                    </>
                )}
            </div>
            <div className={styles.dropdown}>
                <div className={styles.dropdownContent}>
                    <div className="pt-4 pb-2">
                        <div className="flex flex-col mb-4">
                            {options.map(status => (
                                <div
                                    className="cursor-pointer p-2 hover:bg-rose-50"
                                    onClick={() => toggleStatus(status)}
                                    key={status}
                                >
                                    <Checkbox
                                        checked={
                                            !!searchParams
                                                .get(filterKey)
                                                ?.split(',')
                                                .find(s => s === status)
                                        }
                                        onChange={() => toggleStatus(status)}
                                    >
                                        {t(status.toLowerCase())}
                                    </Checkbox>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="h-px bg-zinc-200"></div>
                    <div
                        className="mt-2 py-2 px-4 text-center cursor-pointer hover:bg-rose-50"
                        onClick={() => clearAllFilters()}
                    >
                        {t('clearAllLabel')}
                    </div>
                </div>
            </div>
        </div>
    );
};
