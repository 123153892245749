import { Breadcrumbs } from 'components/Breadcrumbs/Breadcrumbs';
import { Button } from 'components/Button/Button';
import { EmptyState } from 'components/EmptyState/EmptyState';
import { Icon } from 'components/Icon/Icon';
import { MemberTabs } from 'components/MemberTabs/MemberTabs';
import { UserPreferencesPreview } from 'components/UserPreferencesPreview/UserPreferencesPreview';
import { useNavigate, useParams } from 'react-router-dom';
import { AppRoutes } from 'Routes';
import { useUserStore } from 'stores/user.store';

import { MemberHeader } from '../../components/MemberHeader/MemberHeader';

import { PartnerImagePreview } from './_components/PartnerImagePreview/PartnerImagePreview';
import { PartnerInfoPreview } from './_components/PartnerInfoPreview/PartnerInfoPreview';

export const MemberPartner = () => {
    const partner = useUserStore(state => state.user?.partner);
    const user = useUserStore(state => state.user);
    const { id } = useParams();
    const navigate = useNavigate();

    const editPartner = () => {
        if (!id) return;
        navigate(AppRoutes.memberEditPartner.replace(':id', id));
    };

    return (
        <div id="partner">
            <Breadcrumbs crumbs={['dashboard', 'members', `${user?.firstName} ${user?.lastName}`]} />
            <MemberHeader>
                {partner && (
                    <Button onClick={() => editPartner()}>
                        <Icon name="edit" className="h-4 mr-2" /> Edit
                    </Button>
                )}
            </MemberHeader>
            <MemberTabs />
            {!partner && (
                <EmptyState
                    image="/images/no-partner.png"
                    title="No partner registered yet"
                    text="You can add the partner by clicking the button below."
                    button="+ Add partner"
                    onClick={() => editPartner()}
                />
            )}
            {partner && (
                <div className="flex flex-col gap-6">
                    <div className="flex gap-6 pd-4">
                        <PartnerImagePreview image={partner?.image} />
                        <PartnerInfoPreview />
                    </div>
                    <UserPreferencesPreview user={partner} />
                </div>
            )}
        </div>
    );
};
