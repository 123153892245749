import { gql } from '@apollo/client';

export const CREATE_CONTACT = gql`
    mutation contactCreate($input: ContactInput!, $member: ID, $company: ID) {
        contactCreate(input: $input, member: $member, company: $company) {
            contact {
                id
                name
                type
            }
        }
    }
`;

export const UPDATE_CONTACT = gql`
    mutation contactUpdate($input: ContactUpdateInput!, $contact: ID!) {
        contactUpdate(input: $input, contact: $contact) {
            contact {
                id
                name
                type
            }
        }
    }
`;

export const CONTACT_STATUS_UPDATE = gql`
    mutation contactStatusUpdate($status: ContactStatus!, $contact: ID!) {
        contactStatusUpdate(status: $status, contact: $contact) {
            status
        }
    }
`;
