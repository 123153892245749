import { InputMask } from '@react-input/mask';
import { Button } from 'components/Button/Button';
import { FormError } from 'components/FormError/FormError';
import { Input } from 'components/Input/Input';
import { Label } from 'components/Label/Label';
import { Modal } from 'components/Modal/Modal';
import { Portal } from 'components/Portal/Portal';
import dayjs from 'dayjs';
import { validateDate } from 'lib/utils';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

type UserSuspendModalProps = {
    isOpen: boolean;
    onClose: () => void;
    onSuspend: (data: any) => void;
};

export const UserSuspendModal: React.FC<UserSuspendModalProps> = ({ isOpen, onClose, onSuspend }) => {
    const { t } = useTranslation('member', { keyPrefix: 'userSettings' });
    const { t: f } = useTranslation('forms');

    const {
        register,
        handleSubmit,
        setError,
        formState: { errors, isValid },
    } = useForm({
        mode: 'onChange',
    });

    const onSubmit = (formData: any) => {
        const start = dayjs(formData.startDate, 'DD.MM.YYYY').format('YYYY-MM-DD');
        const end = dayjs(formData.endDate, 'DD.MM/YYYY').format('YYYY-MM-DD');
        const days = dayjs(end).diff(dayjs(start), 'day');
        if (days < 30) {
            setError('endDate', { type: 'manual', message: t('minSuspendPeriod') });
            return;
        }
        if (days > 730) {
            setError('endDate', { type: 'manual', message: t('maxSuspendPeriod') });
            return;
        }
        onSuspend({ from: start, until: end });
    };

    return (
        <Portal>
            <Modal isOpen={isOpen} onClose={onClose} maxWidth={512} uncloseable>
                <form className="flex flex-col gap-4" onSubmit={handleSubmit(onSubmit)}>
                    <div>
                        <div className="text-xl font-semibold text-zinc-700 mb-2">{t('suspendModalTitle')}</div>
                        <div className="text-sm text-zinc-500">{t('suspendModalText')}</div>
                    </div>
                    <div className="flex gap-6">
                        <div className="flex-1">
                            <Label>{t('startDate')}</Label>
                            <InputMask
                                component={Input}
                                placeholder={f('datePlaceholder')}
                                mask="__.__.____"
                                replacement={{ _: /\d/ }}
                                {...register('startDate', {
                                    validate: value => validateDate(value) || f('invalidDateError'),
                                    required: f('startDateRequired'),
                                })}
                                error={!!errors.startDate}
                            />
                            <FormError error={errors.startDate} />
                        </div>
                        <div className="flex-1">
                            <Label>{t('endDate')}</Label>
                            <InputMask
                                component={Input}
                                placeholder={f('datePlaceholder')}
                                mask="__.__.____"
                                replacement={{ _: /\d/ }}
                                {...register('endDate', {
                                    validate: value => validateDate(value) || f('invalidDateError'),
                                    required: f('endDateRequired'),
                                })}
                                error={!!errors.endDate}
                            />
                            <FormError error={errors.endDate} />{' '}
                        </div>
                    </div>
                    <div className="flex justify-end gap-2">
                        <Button variant="outline" onClick={onClose} type="button">
                            {t('cancelModalButton')}
                        </Button>
                        <Button type="submit" disabled={!isValid}>
                            {t('suspendModalButton')}
                        </Button>
                    </div>
                </form>
            </Modal>
        </Portal>
    );
};
