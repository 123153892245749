import { cn } from 'lib/utils';
import { NavLink } from 'react-router-dom';

export const Tabs = ({ children, className }: any) => {
    return (
        <div className={cn('py-4 flex', className)}>
            <div className="bg-rose-50 p-[5px] flex">{children}</div>
        </div>
    );
};

export const Tab = ({ children, to, end, active, replace = false, onClick }: any) => {
    if (!to)
        return (
            <div
                onClick={onClick}
                className={cn('p-3 py-[6px] text-zinc-700 text-sm hover:cursor-pointer', active && 'bg-white')}
            >
                {children}
            </div>
        );
    return (
        <NavLink
            to={to}
            replace={replace}
            className={({ isActive }) =>
                cn('p-3 py-[6px] text-zinc-700 text-sm hover:cursor-pointer', isActive && 'bg-white')
            }
            end={end}
        >
            {children}
        </NavLink>
    );
};
