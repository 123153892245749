import { gql } from '@apollo/client';

export const COMPANIES = gql`
    query companies(
        $search: String
        $status: [CompanyCompanyStatusChoices]
        $sort: CompanySortEnum
        $offset: Int
        $first: Int
    ) {
        companies(search: $search, status_In: $status, sort: $sort, offset: $offset, first: $first) {
            edges {
                node {
                    id
                    name
                    balance {
                        currency
                        amount
                    }
                    email
                    status
                    image {
                        url
                    }
                }
            }
            pageInfo {
                hasNextPage
            }
            totalCount
        }
    }
`;

export const COMPANY_BY_ID = gql`
    query company($id: ID!) {
        company(id: $id) {
            id
            companyId
            name
            phoneNumber
            address
            zipCode
            poBox
            uidNumber
            vatNumber
            balance {
                currency
                amount
            }
            email
            status
            city
            billToThisContact
            image {
                url
                alt
            }
            country {
                name
                code
            }
            members(first: 100) {
                edges {
                    node {
                        id
                        firstName
                        lastName
                        jobPosition
                        email
                        status
                        image {
                            url
                            alt
                        }
                    }
                }
            }
            contacts(first: 100) {
                edges {
                    node {
                        id
                        salutation
                        name
                        lastName
                        status
                        type
                        phoneNumber
                        secondaryPhoneNumber
                        email
                        secondaryEmail
                        address
                        zipCode
                        poBox
                        city
                        vatNumber
                        country {
                            name
                            code
                        }
                        billToThisContact
                        relatedCompany {
                            id
                            name
                        }
                    }
                }
            }
        }
    }
`;
