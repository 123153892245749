import { Card, CardSubtitle, CardTitle } from 'components/Card/Card';
import Checkbox from 'components/Checkbox/Checkbox';
import { FormError } from 'components/FormError/FormError';
import { Input } from 'components/Input/Input';
import { Label } from 'components/Label/Label';
import { Select } from 'components/Select/Select';
import { COUNTRIES } from 'lib/options';
import { useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useCompanyStore } from 'stores/company.store';

export const CompanyInfo = () => {
    const company = useCompanyStore(state => state.company);
    const {
        register,
        control,
        reset,
        formState: { errors },
    } = useFormContext();
    const { t: f } = useTranslation('forms');
    const { t } = useTranslation('company');

    useEffect(() => {
        if (company?.id) {
            const { balance, companyId, id, ...rest } = company;
            setTimeout(() => reset({ ...rest, country: rest.country.code }), 0);
        }
    }, [company, reset]);

    return (
        <Card className="flex-1">
            <CardTitle>{t('companyInfoTitle')}</CardTitle>
            <CardSubtitle>{t('companyInfoSubtitle')}</CardSubtitle>

            <div className="flex flex-col gap-6">
                <div className="flex gap-6">
                    <div className="flex-1">
                        <Label>{f('companyName')}</Label>
                        <Input
                            {...register('name', { required: t('errors.companyRequired') })}
                            placeholder={f('companyName')}
                            error={!!errors.name}
                        />
                        <FormError error={errors.name} />
                    </div>
                    <div className="flex-1" />
                </div>

                <div className="flex gap-6">
                    <div className="flex-1">
                        <Label>{f('email')}</Label>
                        <Input
                            {...register('email', { required: t('errors.emailRequired') })}
                            placeholder={f('email')}
                            error={!!errors.email}
                            disabled={!!company}
                        />
                        <FormError error={errors.email} />
                    </div>
                    <div className="flex-1">
                        <Label>{f('phone')}</Label>
                        <Input
                            {...register('phoneNumber', { required: t('errors.phoneRequired') })}
                            placeholder={f('phone')}
                            error={!!errors.phoneNumber}
                        />
                        <FormError error={errors.phoneNumber} />
                    </div>
                </div>

                <div className="flex gap-6">
                    <div className="flex-1">
                        <Label>{f('uidNumber')}</Label>
                        <Input
                            {...register('uidNumber', { required: t('errors.uidRequired') })}
                            placeholder={f('uidNumber')}
                            error={!!errors.uidNumber}
                        />
                        <FormError error={errors.uidNumber} />
                    </div>
                    <div className="flex-1">
                        <Label>{f('vat')}</Label>
                        <Input
                            {...register('vatNumber', { required: t('errors.vatRequired') })}
                            placeholder={f('vat')}
                            error={!!errors.vatNumber}
                        />
                        <FormError error={errors.vatNumber} />
                    </div>
                </div>

                <div className="flex gap-6">
                    <div className="flex-1">
                        <Label>{f('address')}</Label>
                        <Input
                            {...register('address', { required: t('errors.addressRequired') })}
                            placeholder={f('address')}
                            error={!!errors.address}
                        />
                        <FormError error={errors.address} />
                    </div>
                    <div className="flex-1 flex gap-6">
                        <div className="flex-1">
                            <Label>{f('zipCode')}</Label>
                            <Input
                                {...register('zipCode', { required: t('errors.zipRequired') })}
                                placeholder={f('zipCode')}
                                error={!!errors.zipCode}
                            />
                            <FormError error={errors.zipCode} />
                        </div>
                        <div className="flex-1">
                            <Label>{f('poBox')}</Label>
                            <Input {...register('poBox')} placeholder={f('poBox')} />
                        </div>
                    </div>
                </div>

                <div className="flex gap-6">
                    <div className="flex-1">
                        <Label>{f('city')}</Label>
                        <Input
                            {...register('city', { required: t('errors.cityRequired') })}
                            placeholder={f('city')}
                            error={!!errors.city}
                        />
                        <FormError error={errors.city} />
                    </div>
                    <div className="flex-1">
                        <Label>{f('country')}</Label>
                        <Controller
                            control={control}
                            name="country"
                            rules={{ required: t('errors.countryRequired') }}
                            render={({ field: { onChange } }) => (
                                <Select
                                    options={COUNTRIES}
                                    onChange={(val: any) => onChange(val.value)}
                                    error={!!errors.country}
                                    defaultValue={COUNTRIES.find(c => c.value === company?.country?.code)}
                                />
                            )}
                        />
                        <FormError error={errors.country} />
                    </div>
                </div>
                <div>
                    <Controller
                        control={control}
                        name="billToThisContact"
                        render={({ field: { value, onChange } }) => (
                            <Checkbox onChange={onChange} checked={value}>
                                {f('billToThisContact')}
                            </Checkbox>
                        )}
                    />
                </div>
            </div>
        </Card>
    );
};
