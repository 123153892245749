import { Tab, Tabs } from 'components/Tabs/Tabs';
import { useTranslation } from 'react-i18next';
import { AppRoutes } from 'Routes';

export const MembersTabs = () => {
    const { t } = useTranslation();
    return (
        <Tabs className="pt-8">
            <Tab to={AppRoutes.members} end>
                {t('members.tabMembers')}
            </Tab>
            <Tab to={AppRoutes.companies} end>
                {t('members.tabCompanies')}
            </Tab>
            <Tab to={AppRoutes.contacts} end>
                {t('members.tabContacts')}
            </Tab>
        </Tabs>
    );
};
