import { addChild, addPet, getUser } from 'services/users';
import { UserType } from 'types/user';
import { create } from 'zustand';

interface UserStore {
    user: UserType | null;
    loading: boolean;
    editLoading: boolean;
    setUser: (user: UserType | null) => void;
    getUser: (id: string) => void;
    addChild: (child: any, id: string) => any;
    addPet: (pet: any, id: string) => any;
}

export const useUserStore = create<UserStore>((set, get) => ({
    user: null,
    loading: false,
    editLoading: false,
    setUser: user => set({ user }),
    getUser: async (id: string) => {
        set({ loading: true });
        const { data } = await getUser(id);
        if (data.user) {
            set({ user: data.user });
        }
        set({ loading: false });
    },
    addChild: async (child: any, id: string) => {
        set({ editLoading: true });
        const result = await addChild(child, id);
        set({ editLoading: false });
        return result;
    },
    addPet: async (pet: any, id: string) => {
        set({ editLoading: true });
        const result = await addPet(pet, id);
        set({ editLoading: false });
        return result;
    },
}));
