import { Avatar } from 'components/Avatar/Avatar';
import { Balance } from 'components/Balance/Balance';
import { Button } from 'components/Button/Button';
import { Date } from 'components/Date/Date';
import { Modal } from 'components/Modal/Modal';
import { Portal } from 'components/Portal/Portal';
import { Table, TableHeader, TableRow, TableHead, TableCell, TableBody } from 'components/Table/Table';
import { useTranslation } from 'react-i18next';

type RemainderBalancesModalProps = {
    isOpen: boolean;
    onClose: () => void;
    members: any[];
};
export const RemainderBalancesModal: React.FC<RemainderBalancesModalProps> = ({ isOpen, onClose, members }) => {
    const { t } = useTranslation('dashboard');
    return (
        <Portal>
            <Modal isOpen={isOpen} onClose={onClose} maxWidth={800}>
                <div className="flex flex-col gap-6">
                    <div>
                        <div className="text-xl font-semibold text-zinc-700 mb-2">{t('remainderBalancesTitle')}</div>
                        <div className="text-sm text-zinc-500">{t('reminderBalancesModalSubtitle')}</div>
                    </div>
                    <div className="flex flex-col gap-4">
                        <Table className="border">
                            <TableHeader>
                                <TableRow>
                                    <TableHead className="flex gap-2 items-center">{t('nameLabel')}</TableHead>
                                    <TableHead>{t('remainderBalanceLabel')}</TableHead>
                                    <TableHead>{t('expiresLabel')}</TableHead>
                                </TableRow>
                            </TableHeader>
                            <TableBody>
                                {members?.length > 0 &&
                                    members.map((member: any) => (
                                        <TableRow key={member.id} className="cursor-pointer">
                                            <TableCell className="flex gap-2 items-center w-full">
                                                <Avatar image={member.image} size={32} />
                                                {member.firstName} {member.lastName}
                                            </TableCell>
                                            <TableCell>
                                                <Balance
                                                    balance={{
                                                        amount: member.remainderBalance,
                                                        currency: 'CHF',
                                                    }}
                                                />
                                            </TableCell>
                                            <TableCell>
                                                <Date date={member.membershipEnd} />
                                            </TableCell>
                                        </TableRow>
                                    ))}
                            </TableBody>
                        </Table>
                        <div className="flex justify-end gap-2">
                            <Button onClick={onClose}>{t('closeButton')}</Button>
                        </div>
                    </div>
                </div>
            </Modal>
        </Portal>
    );
};
