import { Button } from 'components/Button/Button';
import { Icon } from 'components/Icon/Icon';
import { Modal } from 'components/Modal/Modal';
import { Portal } from 'components/Portal/Portal';
import { PreviewLabel, PreviewValue } from 'components/PreviewFields/PreviewFields';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { AppRoutes } from 'Routes';

type ContactInfoModalProps = {
    onClose: () => void;
    onEdit?: () => void;
    contact: any;
};

export const ContactInfoModal: React.FC<ContactInfoModalProps> = ({ onClose, contact, onEdit }) => {
    const { t } = useTranslation('forms');

    if (!contact) return null;
    return (
        <Portal>
            <Modal isOpen={!!contact} onClose={onClose} maxWidth={800}>
                <div className="flex flex-col gap-8">
                    <div className="capitalize text-lg font-semibold">{contact.type.toLowerCase()}</div>

                    <div className="flex flex-col gap-8">
                        {contact.type !== 'COMPANY' && (
                            <div className="flex gap-6">
                                {contact.type === 'OTHER' && (
                                    <div className="flex-1">
                                        <PreviewLabel>{t('contactLabel')}</PreviewLabel>
                                        <PreviewValue className="capitalize">{contact?.label || '-'}</PreviewValue>
                                    </div>
                                )}
                                <div className="flex-1">
                                    <PreviewLabel>{t('salutation')}</PreviewLabel>
                                    <PreviewValue className="capitalize">
                                        {contact?.salutation?.toLowerCase() || '-'}
                                    </PreviewValue>
                                </div>
                            </div>
                        )}

                        <div className="flex gap-6">
                            <div className="flex-1">
                                {contact.type === 'COMPANY' ? (
                                    <PreviewLabel>{t('companyName')}</PreviewLabel>
                                ) : (
                                    <PreviewLabel>{t('firstName')}</PreviewLabel>
                                )}
                                <PreviewValue>{contact.name}</PreviewValue>
                            </div>
                            {contact.type !== 'COMPANY' && (
                                <div className="flex-1">
                                    <PreviewLabel>{t('lastName')}</PreviewLabel>
                                    <PreviewValue>{contact.lastName || '-'}</PreviewValue>
                                </div>
                            )}
                            {contact.type === 'COMPANY' && (
                                <div className="flex-1">
                                    <PreviewLabel>{t('vat')}</PreviewLabel>
                                    <PreviewValue>{contact.vatNumber || '-'}</PreviewValue>
                                </div>
                            )}
                        </div>

                        <div className="flex gap-6">
                            <div className="flex-1">
                                <PreviewLabel>{t('personalPhone')}</PreviewLabel>
                                <PreviewValue>{contact.phoneNumber}</PreviewValue>
                            </div>
                            <div className="flex-1">
                                <PreviewLabel>{t('businessPhone')}</PreviewLabel>
                                <PreviewValue>{contact.secondaryPhoneNumber || '-'}</PreviewValue>
                            </div>
                        </div>

                        <div className="flex gap-6">
                            <div className="flex-1">
                                <PreviewLabel>{t('email')}</PreviewLabel>
                                <PreviewValue>{contact.email}</PreviewValue>
                            </div>
                            <div className="flex-1">
                                <PreviewLabel>{t('secondaryEmail')}</PreviewLabel>
                                <PreviewValue>{contact.secondaryEmail || '-'}</PreviewValue>
                            </div>
                        </div>

                        <div className="flex gap-6">
                            <div className="flex-1">
                                <PreviewLabel>{t('address')}</PreviewLabel>
                                <PreviewValue>{contact.address || '-'}</PreviewValue>
                            </div>
                            <div className="flex-1 flex gap-6">
                                <div className="flex-1">
                                    <PreviewLabel>{t('zipCode')}</PreviewLabel>
                                    <PreviewValue>{contact.zipCode || '-'}</PreviewValue>
                                </div>
                                <div className="flex-1">
                                    <PreviewLabel>{t('poBox')}</PreviewLabel>
                                    <PreviewValue>{contact.poBox || '-'}</PreviewValue>
                                </div>
                            </div>
                        </div>

                        <div className="flex gap-6">
                            <div className="flex-1">
                                <PreviewLabel>{t('city')}</PreviewLabel>
                                <PreviewValue>{contact.city || '-'}</PreviewValue>
                            </div>
                            <div className="flex-1">
                                <PreviewLabel>{t('country')}</PreviewLabel>
                                <PreviewValue>{contact.country?.name || '-'}</PreviewValue>
                            </div>
                        </div>

                        <div className="flex gap-6">
                            <div className="flex-1">
                                <PreviewLabel>{t('relatedContacts')}</PreviewLabel>
                                <PreviewValue className="flex gap-2">
                                    {contact.relatedMember && (
                                        <>
                                            <Link
                                                to={AppRoutes.memberProfile.replace(':id', contact.relatedMember?.id)}
                                                className="text-rose-800 font-medium underline"
                                            >
                                                {contact.relatedMember?.firstName} {contact.relatedMember?.lastName}
                                            </Link>
                                            (member)
                                        </>
                                    )}
                                    {contact.relatedCompany && (
                                        <>
                                            <Link
                                                to={AppRoutes.companyProfile.replace(':id', contact.relatedCompany?.id)}
                                                className="text-rose-800 font-medium underline"
                                            >
                                                {contact.relatedCompany?.name}
                                            </Link>
                                            (company)
                                        </>
                                    )}
                                </PreviewValue>
                            </div>
                        </div>
                    </div>
                    <div className="flex justify-end gap-2">
                        <Button variant="outline" onClick={onClose}>
                            Cancel
                        </Button>
                        <Button onClick={() => onEdit && onEdit()}>
                            <Icon name="edit" className="h-4 mr-2" /> Edit
                        </Button>
                    </div>
                </div>
            </Modal>
        </Portal>
    );
};
