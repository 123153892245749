import { gql } from '@apollo/client';

export const CREATE_COMPANY = gql`
    mutation companyCreate($input: CompanyInput!) {
        companyCreate(input: $input) {
            company {
                id
                name
            }
        }
    }
`;

export const COMPANY_UPDATE = gql`
    mutation companyUpdate($input: CompanyUpdateInput!, $company: ID!) {
        companyUpdate(input: $input, company: $company) {
            company {
                id
                name
            }
        }
    }
`;

export const COMPANY_DELETE = gql`
    mutation companyDelete($company: ID!) {
        companyDelete(company: $company) {
            success
        }
    }
`;

export const MEMBER_REMOVE = gql`
    mutation memberRemove($member: ID!, $company: ID!) {
        companyMemberRemove(member: $member, company: $company) {
            success
        }
    }
`;

export const MEMBERS_ATTACH = gql`
    mutation membersAttach($members: [ID]!, $company: ID!) {
        companyMemberAdd(members: $members, company: $company) {
            success
        }
    }
`;

export const COMPANY_BLOCK_UNBLOCK = gql`
    mutation companyBlock($action: BlockUnblockEnum!, $company: ID!) {
        companyBlock(action: $action, company: $company) {
            status
        }
    }
`;
