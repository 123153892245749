import { Button } from 'components/Button/Button';
import Checkbox from 'components/Checkbox/Checkbox';
import { FormError } from 'components/FormError/FormError';
import { Input } from 'components/Input/Input';
import { Modal } from 'components/Modal/Modal';
import { Portal } from 'components/Portal/Portal';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

type EventCancelModalProps = {
    isOpen: boolean;
    onClose: () => void;
    onConfirm: (notify: boolean) => void;
};
export const EventCancelModal: React.FC<EventCancelModalProps> = ({ isOpen, onClose, onConfirm }) => {
    const { t } = useTranslation('events');
    const [notify, setNotify] = useState(false);
    const [check, setCheck] = useState('');
    const [error, setError] = useState(false);

    const cancelEvent = () => {
        if (check.toLocaleLowerCase() !== 'cancel') {
            setError(true);
        } else {
            onConfirm(notify);
        }
    };

    return (
        <Portal>
            <Modal isOpen={isOpen} onClose={onClose} maxWidth={512} uncloseable>
                <div className="flex flex-col gap-4">
                    <div>
                        <div className="text-xl font-semibold text-zinc-700 mb-2">{t('eventCancelModalTitle')}</div>
                        <div
                            className="text-sm text-zinc-500"
                            dangerouslySetInnerHTML={{ __html: t('eventCancelModalSubtitle') }}
                        />
                    </div>
                    <div>
                        <Input
                            placeholder={t('cancelEventCheckPlaceholder')}
                            className="uppercase placeholder:normal-case"
                            autoFocus
                            onChange={(e: any) => {
                                setCheck(e.target.value);
                                setError(false);
                            }}
                            error={error}
                        />
                        {error && <FormError error={{ message: t('cancelModalError') }} />}
                    </div>
                    <Checkbox className="text-sm text-zinc-500" checked={notify} onChange={() => setNotify(!notify)}>
                        {t('eventCancelModalNotifyButton')}
                    </Checkbox>
                    <div className="flex justify-end gap-2">
                        <Button variant="outline" onClick={onClose}>
                            {t('cancelModalButton')}
                        </Button>
                        <Button onClick={() => cancelEvent()}>{t('sureModalButton')}</Button>
                    </div>
                </div>
            </Modal>
        </Portal>
    );
};
