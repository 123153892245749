import { useQuery } from '@apollo/client';
import { Label } from 'components/Label/Label';
import { SelectAsync } from 'components/Select/Select';
import { normalizeArray } from 'lib/utils';
import { useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { USERS } from 'services/users/query';
import { useUserStore } from 'stores/user.store';
import { useDebouncedCallback } from 'use-debounce';

export const ReferencePerson = () => {
    const { t } = useTranslation('forms');
    const [defaultList, setDefaultList] = useState([]);
    const { data, refetch } = useQuery(USERS, { variables: { isMember: true } });
    const user = useUserStore(state => state.user);

    const { control, setValue } = useFormContext();

    useEffect(() => {
        if (user) setValue('referencePerson', user.referencePerson?.id);
    }, [user, setValue]);

    // Set the initial list of users only on first render
    useEffect(() => {
        if (defaultList.length === 0 && data?.users?.edges?.length > 0) {
            setDefaultList(
                normalizeArray(data.users)
                    .map((user: any) => ({
                        value: user.id,
                        label: `${user.firstName} ${user.lastName}`,
                    }))
                    .filter((u: any) => u.value !== user?.id),
            );
        }
    }, [data, defaultList, user]);

    // Load users on search, debounce to 500ms
    const loadOptions = useDebouncedCallback((inputValue: string, callback) => {
        refetch({ search: inputValue }).then(resp => {
            callback(() => {
                return normalizeArray(resp.data.users)
                    .map((user: any) => ({
                        value: user.id,
                        label: `${user.firstName} ${user.lastName}`,
                    }))
                    .filter((u: any) => u.value !== user?.id);
            });
        });
    }, 500);

    return (
        <div>
            <Label>{t('referencePerson')}</Label>
            <Controller
                control={control}
                name="referencePerson"
                render={({ field: { onChange } }) => (
                    <SelectAsync
                        defaultOptions={defaultList}
                        loadOptions={loadOptions}
                        onChange={(val: any) => onChange(val.value)}
                        defaultValue={
                            user?.referencePerson && {
                                value: user.referencePerson.id,
                                label: `${user.referencePerson.firstName} ${user.referencePerson.lastName}`,
                            }
                        }
                    />
                )}
            />
        </div>
    );
};
