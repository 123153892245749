import { Container } from 'components/Container/Container';
import { Loader } from 'components/Loader/Loader';
import { useEffect } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import { useEventStore } from 'stores/event.store';

export const EventLayout = () => {
    const { id } = useParams();
    const { loading, getEvent, setEvent } = useEventStore(state => state);

    useEffect(() => {
        if (id) getEvent(id);
        return () => setEvent(null);
    }, [id, getEvent, setEvent]);

    if (loading) return <Loader fullSize />;

    return (
        <Container>
            <Outlet />
        </Container>
    );
};
