import { Button } from 'components/Button/Button';
import { Modal } from 'components/Modal/Modal';
import { Portal } from 'components/Portal/Portal';
import { useTranslation } from 'react-i18next';

type ConfirmInvoicePaidModalProps = {
    isOpen: boolean;
    onClose: () => void;
    onConfirm: () => void;
};

export const ConfirmInvoicePaidModal: React.FC<ConfirmInvoicePaidModalProps> = ({ isOpen, onClose, onConfirm }) => {
    const { t } = useTranslation('documents');

    return (
        <Portal>
            <Modal isOpen={isOpen} onClose={onClose} maxWidth={512}>
                <div className="flex flex-col gap-4">
                    <div>
                        <div className="text-xl font-semibold text-zinc-700 mb-2">{t('markAsPaidTitle')}</div>
                        <div className="text-sm text-zinc-500">{t('markAsPaidSubtitle')}</div>
                    </div>
                    <div className="flex justify-end gap-2">
                        <Button variant="outline" onClick={onClose} type="button">
                            {t('cancelModalButton')}
                        </Button>
                        <Button type="button" onClick={onConfirm}>
                            {t('confirmModalButton')}
                        </Button>
                    </div>
                </div>
            </Modal>
        </Portal>
    );
};
