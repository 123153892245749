import { Card, CardSubtitle, CardTitle } from 'components/Card/Card';
import { Label } from 'components/Label/Label';
import { Textarea } from 'components/Textarea/Textarea';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

export const EventCommentsForm = () => {
    const { t } = useTranslation('events');
    const { t: f } = useTranslation('forms');
    const { register } = useFormContext();

    return (
        <Card className="flex flex-col">
            <div>
                <CardTitle>{t('eventCommentsTitle')}</CardTitle>
                <CardSubtitle>{t('eventCommentsSubtitle')}</CardSubtitle>
            </div>
            <div>
                <Label>{f('comments')}</Label>
                <Textarea {...register('comments')} />
            </div>
        </Card>
    );
};
