import { InputMask } from '@react-input/mask';
import { Card, CardSubtitle, CardTitle } from 'components/Card/Card';
import { FormError } from 'components/FormError/FormError';
import { Input } from 'components/Input/Input';
import { Label } from 'components/Label/Label';
import { Select } from 'components/Select/Select';
import { COUNTRIES, ROLES } from 'lib/options';
import { validateDate } from 'lib/utils';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useStaffStore } from 'stores/staff.store';

export const StaffPersonalInfo = () => {
    const member = useStaffStore(state => state.member);
    const {
        register,
        control,
        formState: { errors },
    } = useFormContext();
    const { t } = useTranslation('forms');
    const { t: s } = useTranslation('staff');

    return (
        <Card className="flex-1">
            <div>
                <CardTitle>{s('staffInfoTitle')}</CardTitle>
                <CardSubtitle>{s('staffInfoSubtitle')}</CardSubtitle>
            </div>
            <div className="flex flex-col gap-6">
                <div className="flex gap-6">
                    <div className="flex-1">
                        <Label>{t('email')}</Label>
                        <Input
                            {...register('email', { required: t('emailRequired') })}
                            placeholder={t('email')}
                            error={!!errors.email}
                            readOnly={!!member}
                        />
                        <FormError error={errors.email} />
                    </div>
                    <div className="flex-1">
                        <Label>{t('role')}</Label>
                        <Controller
                            control={control}
                            name="role"
                            rules={{ required: t('roleRequired') }}
                            render={({ field: { onChange } }) => (
                                <Select
                                    options={ROLES}
                                    onChange={(val: any) => onChange(val.value)}
                                    error={!!errors.role}
                                    defaultValue={ROLES.find(c => c.value === member?.staffRole)}
                                />
                            )}
                        />
                        <FormError error={errors.role} />
                    </div>
                </div>

                <div className="flex gap-6">
                    <div className="flex-1">
                        <Label>{t('firstName')}</Label>
                        <Input
                            {...register('firstName', { required: t('firstNameRequired') })}
                            placeholder={t('firstName')}
                            error={!!errors.firstName}
                        />
                        <FormError error={errors.firstName} />
                    </div>
                    <div className="flex-1">
                        <Label>{t('lastName')}</Label>
                        <Input
                            {...register('lastName', { required: t('lastNameRequired') })}
                            placeholder={t('lastName')}
                            error={!!errors.lastName}
                        />
                        <FormError error={errors.lastName} />
                    </div>
                </div>

                <div className="flex gap-6">
                    <div className="flex-1">
                        <Label>{t('preview.dateOfBirth')}</Label>
                        <InputMask
                            component={Input}
                            placeholder={t('datePlaceholder')}
                            mask="__.__.____"
                            replacement={{ _: /\d/ }}
                            error={!!errors.dateOfBirth}
                            {...register('dateOfBirth', {
                                validate: value => validateDate(value) || t('invalidDateError'),
                            })}
                        />
                        <FormError error={errors.dateOfBirth} />
                    </div>
                    <div className="flex-1">
                        <Label>{t('preview.jobPosition')}</Label>
                        <Input {...register('jobPosition')} placeholder={t('preview.jobPosition')} />
                    </div>
                </div>

                <div className="flex gap-6">
                    <div className="flex-1">
                        <Label>{t('phone')}</Label>
                        <Input
                            {...register('phoneNumber', { required: t('phoneNumberRequired') })}
                            placeholder={t('phone')}
                            error={!!errors.phoneNumber}
                        />
                        <FormError error={errors.phoneNumber} />
                    </div>
                    <div className="flex-1"></div>
                </div>

                <div className="flex gap-6">
                    <div className="flex-1">
                        <Label>{t('address')}</Label>
                        <Input {...register('address')} placeholder={t('address')} />
                    </div>
                    <div className="flex-1 flex gap-6">
                        <div className="flex-1">
                            <Label>{t('zipCode')}</Label>
                            <Input {...register('zipCode')} placeholder={t('zipCode')} />
                        </div>
                        <div className="flex-1">
                            <Label>{t('poBox')}</Label>
                            <Input {...register('poBox')} placeholder={t('poBox')} />
                        </div>
                    </div>
                </div>

                <div className="flex gap-6">
                    <div className="flex-1">
                        <Label>{t('city')}</Label>
                        <Input {...register('city')} placeholder={t('city')} />
                    </div>
                    <div className="flex-1">
                        <Label>{t('country')}</Label>
                        <Controller
                            control={control}
                            name="country"
                            render={({ field: { onChange } }) => (
                                <Select
                                    options={COUNTRIES}
                                    onChange={(val: any) => onChange(val.value)}
                                    defaultValue={COUNTRIES.find(c => c.value === member?.country?.code)}
                                />
                            )}
                        />
                    </div>
                </div>
            </div>
        </Card>
    );
};
