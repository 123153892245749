import { useQuery } from '@apollo/client';
import { Breadcrumbs } from 'components/Breadcrumbs/Breadcrumbs';
import { MemberHeader } from 'components/MemberHeader/MemberHeader';
import { MemberTabs } from 'components/MemberTabs/MemberTabs';
import { Pagination } from 'components/Pagination/Pagination';
import { Status } from 'components/Status/Status';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from 'components/Table/Table';
import dayjs from 'dayjs';
import { normalizeArray } from 'lib/utils';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { AppRoutes } from 'Routes';
import { USER_EVENTS } from 'services/users/query';
import { useUserStore } from 'stores/user.store';

const PAGE_SIZE = parseInt(localStorage.getItem('pageSize') as any) || 10;

export const MemberEvents = () => {
    const { user } = useUserStore(state => state);
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const { data, refetch } = useQuery(USER_EVENTS, {
        variables: {
            id: user?.id,
            first: PAGE_SIZE,
        },
    });
    const { t } = useTranslation('events');

    useEffect(() => {
        const page = searchParams.get('page') || '1';

        refetch({
            offset: (parseInt(page) - 1) * PAGE_SIZE,
        });
    });

    if (!user) return null;

    return (
        <div>
            <Breadcrumbs crumbs={['dashboard', 'invoices', `${user.firstName} ${user.lastName} events`]} />
            <MemberHeader />
            <MemberTabs />

            <Table className="border overflow-visible">
                <TableHeader>
                    <TableRow>
                        <TableHead>{t('table.nameLabel')}</TableHead>
                        <TableHead>{t('table.dateLabel')}</TableHead>
                        <TableHead>{t('table.typeLabel')}</TableHead>
                        <TableHead>{t('table.statusLabel')}</TableHead>
                    </TableRow>
                </TableHeader>
                <TableBody className="hover:cursor-pointer">
                    {data?.user?.allEvents?.edges?.length !== 0 &&
                        normalizeArray(data?.user?.allEvents)?.map((event: any) => (
                            <TableRow
                                key={event.id}
                                className="text-zinc-700 hover:bg-rose-50"
                                onClick={() => navigate(AppRoutes.eventPreview.replace(':id', event.id))}
                            >
                                <TableCell className="capitalize">{event.name}</TableCell>
                                <TableCell>{dayjs(event.date).format('DD.MM.YYYY')}</TableCell>
                                <TableCell>{t(event.type)}</TableCell>
                                <TableCell>
                                    <Status status={event.status} />
                                </TableCell>
                            </TableRow>
                        ))}
                </TableBody>
            </Table>
            {data?.user?.allEvents?.edges?.length === 0 && (
                <div className={'py-5 text-center text-zinc-700'}>{t('noResults')}</div>
            )}
            <Pagination pageInfo={data?.user?.allEvents?.pageInfo} total={data?.user?.allEvents?.totalCount} />
        </div>
    );
};
