import { Card, CardTitle } from 'components/Card/Card';
import { PreviewLabel, PreviewValue } from 'components/PreviewFields/PreviewFields';
import { useTranslation } from 'react-i18next';
import { useUserStore } from 'stores/user.store';

export const CompanyInfoPreview = () => {
    const user = useUserStore(state => state.user);
    const { t } = useTranslation('member', { keyPrefix: 'companyInfo' });
    const { t: f } = useTranslation('forms');

    return (
        <Card className="flex flex-col gap-8">
            <CardTitle>{t('title')}</CardTitle>
            <div className="flex flex-col gap-6">
                <div className="flex gap-6">
                    <div className="flex-1">
                        <PreviewLabel>{f('company')}</PreviewLabel>
                        <PreviewValue>{user?.company?.name || user?.companyName || '-'}</PreviewValue>
                    </div>
                    <div className="flex-1">
                        <PreviewLabel>{f('preview.jobTitle')}</PreviewLabel>
                        <PreviewValue>{user?.jobPosition || '-'}</PreviewValue>
                    </div>
                </div>
                <div>
                    <PreviewLabel>{f('jobDescription')}</PreviewLabel>
                    <PreviewValue>{user?.jobInfo || '-'}</PreviewValue>
                </div>
            </div>
        </Card>
    );
};
