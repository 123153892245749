import { getEvent } from 'services/events';
import { create } from 'zustand';

interface EventStore {
    event: any;
    loading: boolean;
    images: any[];
    documents: any[];
    invite: boolean;
    getEvent: (id: string) => void;
    setEvent: (event: any) => void;
    setImages: (images: any[]) => void;
    setDocuments: (documents: any[]) => void;
    setInvite: (invite: boolean) => void;
}

export const useEventStore = create<EventStore>((set, get) => ({
    event: null,
    loading: false,
    invite: false,
    images: [],
    documents: [],
    getEvent: async (id: string) => {
        if (!get().event) set({ loading: true });
        const { data } = await getEvent(id);
        if (data.event) set({ event: data.event });
        set({ loading: false });
    },
    setEvent: (ev: any) => {
        set({ event: ev });
    },
    setImages: (images: any[]) => {
        set({ images });
    },
    setDocuments: (documents: any[]) => {
        set({ documents });
    },
    setInvite: (invite: boolean) => {
        set({ invite });
    },
}));
