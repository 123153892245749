import { TooltipTrigger } from '@radix-ui/react-tooltip';
import { Icon } from 'components/Icon/Icon';
import { Tooltip, TooltipContent, TooltipProvider } from 'components/Tooltip/Tooltip';
import { cn } from 'lib/utils';

type AvatarsProps = {
    members: any;
    total?: number;
    show?: number;
    onClick?: () => void;
};

export const Avatars: React.FC<AvatarsProps> = ({ members, total, show = 8, onClick }) => {
    if (!members) return null;
    if (members.length === 0) return <div className="opacity-60">---</div>;
    return (
        <div className="flex gap-[3px]" onClick={onClick}>
            {members
                ?.slice(0, show)
                .map((member: any, index: any) => <SmallAvatar key={member.id} member={member} position={index} />)}
            {!!total && total > show && <div className="text-zinc-400 flex items-center ml-1">+{total - show}</div>}
        </div>
    );
};

const SmallAvatar = ({ member, position }: any) => {
    if (!member) return null;
    return (
        <TooltipProvider>
            <Tooltip delayDuration={0}>
                <TooltipTrigger>
                    <div
                        className={cn(
                            'bg-zinc-300 w-[34px] h-[40px] flex justify-center items-center overflow-hidden',
                            position === 0 && 'w-[40px]',
                        )}
                    >
                        {member.image ? (
                            <img
                                src={`${member.image?.url}?w=40&h=40&fit=facearea&facepad=3.5`}
                                alt={member.image?.alt}
                                className="object-cover object-center w-full h-full"
                            />
                        ) : (
                            <Icon name="user" className="text-zinc-50 text-xl h-[22px]" />
                        )}
                    </div>
                </TooltipTrigger>
                <TooltipContent sideOffset={-5} align="start">
                    {member.firstName} {member.lastName}
                </TooltipContent>
            </Tooltip>
        </TooltipProvider>
    );
};
