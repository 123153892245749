import { Loader } from 'components/Loader/Loader';
import { cn } from 'lib/utils';
import { useState } from 'react';
import { useEventStore } from 'stores/event.store';

import styles from './Styles.module.scss';

export const EventImages = () => {
    const [loading, setLoading] = useState(true);
    const event = useEventStore(state => state.event);

    if (!event || event.images?.length === 0) return null;

    return (
        <div
            className={cn(
                'h-[366px] mt-4 gap-4 grid',
                styles[`imageArea${event.images.length > 5 ? 5 : event.images.length}`],
            )}
        >
            {event?.images?.slice(0, 5).map((item: any, index: number) => (
                <div key={item.id} className="relative overflow-hidden" style={{ gridArea: `i${index}` }}>
                    {loading && (
                        <div
                            className={cn(
                                'absolute bg-zinc-300 w-full h-full flex justify-center items-center bg-opacity-50',
                            )}
                        >
                            <Loader />
                        </div>
                    )}
                    <img
                        src={`${item.image.url}?h=500`}
                        alt={item.image.alt}
                        className="object-cover w-full h-full object-center"
                        onLoad={() => setLoading(false)}
                    />
                </div>
            ))}
        </div>
    );
};
