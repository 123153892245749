import { Card, CardTitle } from 'components/Card/Card';
import { InfoItem } from 'components/InfoItem/InfoItem';
import { ViewButton } from 'components/ViewButton/ViewButton';
import { normalizeArray } from 'lib/utils';
import { useTranslation } from 'react-i18next';
import { AppRoutes } from 'Routes';
import { useUserStore } from 'stores/user.store';

export const MemberPreviewAssistant = () => {
    const user = useUserStore(state => state.user);
    const { t } = useTranslation('member');
    const assistant = normalizeArray(user?.contacts)?.find((c: any) => c.type === 'ASSISTANT');

    if (!user || !assistant) return null;
    return (
        <Card className="flex flex-col gap-8">
            <div className="flex justify-between">
                <CardTitle>{t('assistantTitle')}</CardTitle>
                <ViewButton to={AppRoutes.memberProfile.replace(':id', user.id)} />
            </div>
            <div className="border border-rose-100 p-6 bg-white flex flex-col gap-4">
                <div className="font-bold">
                    {assistant.name} {assistant.lastName}
                </div>
                <div className="h-px bg-rose-100"></div>
                <div className="flex flex-col gap-6 text-zinc-500">
                    <InfoItem icon="phone">{assistant.phoneNumber || '-'}</InfoItem>
                    <InfoItem icon="mail">{assistant.email || '-'}</InfoItem>
                </div>
            </div>
        </Card>
    );
};
