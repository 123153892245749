import { Button } from 'components/Button/Button';
import { Modal } from 'components/Modal/Modal';
import { Portal } from 'components/Portal/Portal';
import { useTranslation } from 'react-i18next';

type RemoveMemberModalProps = {
    isOpen: boolean;
    onClose: () => void;
    onConfirm: () => void;
};
export const RemoveMemberModal: React.FC<RemoveMemberModalProps> = ({ isOpen, onClose, onConfirm }) => {
    const { t } = useTranslation('company');
    return (
        <Portal>
            <Modal isOpen={isOpen} onClose={() => {}} maxWidth={512} uncloseable className="flex flex-col gap-4">
                <div>
                    <div className="text-lg font-semibold text-zinc-700 mb-2">{t('removeMemberTitle')}</div>
                    <div className="text-zinc-500 text-sm">{t('removeMemberText')}</div>
                </div>

                <div className="flex justify-end gap-2">
                    <Button variant="outline" onClick={onClose}>
                        {t('cancelButton')}
                    </Button>
                    <Button onClick={onConfirm}>{t('confirmButton')}</Button>
                </div>
            </Modal>
        </Portal>
    );
};
