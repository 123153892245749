import { Avatar } from 'components/Avatar/Avatar';
import { Card } from 'components/Card/Card';
import { Icon } from 'components/Icon/Icon';
import { ViewButton } from 'components/ViewButton/ViewButton';
import { cn, normalizeArray } from 'lib/utils';
import { useTranslation } from 'react-i18next';
import { AppRoutes } from 'Routes';
import { useCompanyStore } from 'stores/company.store';

export const CompanyPreviewMembers = () => {
    const company = useCompanyStore(state => state.company);
    const { t } = useTranslation('company');

    return (
        <Card className="flex flex-col gap-8">
            <div className="flex justify-between">
                <div className="text-lg font-semibold">{t('companyMembersTitle')}</div>
                <ViewButton to={AppRoutes.companyProfile.replace(':id', company?.id)} />
            </div>
            {company?.members?.edges.length > 0 && (
                <div className="grid grid-cols-2 gap-6">
                    {normalizeArray(company?.members).map((member: any) => (
                        <MemberBox key={member.id} member={member} status={company.status} />
                    ))}
                </div>
            )}
            {company?.members?.edges.length === 0 && t('noMembersAdded')}
        </Card>
    );
};

const MemberBox = ({ member, status }: any) => {
    return (
        <div className="border border-rose-100 p-6 bg-white flex gap-6">
            <div>
                <Avatar size={90} image={member.image} className={cn(status === 'BLOCKED' && 'opacity-50')} />
            </div>
            <div className="flex flex-col gap-4 flex-1">
                <div className="font-bold flex justify-between">
                    {member?.firstName} {member?.lastName}
                </div>
                <div className="bg-rose-100 h-px w-full"></div>
                <div className="flex gap-2 text-zinc-500">
                    <Icon name="company" />
                    <span>{member?.jobPosition}</span>
                </div>
            </div>
        </div>
    );
};
