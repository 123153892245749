import { Breadcrumbs } from 'components/Breadcrumbs/Breadcrumbs';
import { Button } from 'components/Button/Button';
import { Icon } from 'components/Icon/Icon';
import { CanceledLabel } from 'components/Labels/CanceledLabel';
import { DraftLabel } from 'components/Labels/DraftLabel';
import { PageTitle } from 'components/PageTitle/PageTitle';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { AppRoutes } from 'Routes';
import { useEventStore } from 'stores/event.store';

import { EventComments } from './_components/EventComments';
import { EventImages } from './_components/EventImages';
import { EventInfo } from './_components/EventInfo';

export const EventPreview = () => {
    const event = useEventStore(state => state.event);
    const navigate = useNavigate();
    const { t } = useTranslation('events');

    return (
        <>
            <Breadcrumbs crumbs={['dashboard', 'events', event?.name]} />
            <PageTitle className={'pt-8 flex items-center flex-1'}>
                <div className="flex-1 flex items-center gap-2">
                    <Icon name="back" className="h-6 text-2xl hover:cursor-pointer" onClick={() => navigate(-1)} />
                    <div>{event?.name}</div>
                    <DraftLabel status={event?.status} />
                    <CanceledLabel status={event?.status} />
                </div>

                {event?.status !== 'CANCELED' && event?.status !== 'CONCLUDED' && (
                    <Button asChild>
                        <Link to={AppRoutes.editEvent.replace(':id', event?.id)}>
                            <Icon name="edit" className="h-4 mr-2" />
                            {t('editButton')}
                        </Link>
                    </Button>
                )}
            </PageTitle>
            <div className="flex flex-col gap-6">
                <EventImages />
                <EventInfo />
                <EventComments />
            </div>
        </>
    );
};
