import { useQuery } from '@apollo/client';
import { Button } from 'components/Button/Button';
import { Card, CardTitle } from 'components/Card/Card';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from 'components/Table/Table';
import dayjs from 'dayjs';
import { normalizeArray } from 'lib/utils';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { USERS } from 'services/users/query';

import { ExpiringMembershipsModal } from './ExpiringMembershipsModal';

export const ExpiringMemberships = () => {
    const [isOpen, setIsOpen] = useState(false);
    const { data } = useQuery(USERS, {
        variables: {
            isMember: true,
            // first: 5,
            sort: 'NEAREST_MEMBERSHIP_END',
        },
    });
    const { t } = useTranslation('dashboard');
    return (
        <>
            <Card className="border flex flex-col gap-6 flex-1">
                <div className="flex justify-between h-6">
                    <CardTitle className="text-base">{t('expiringMembershipsTitle')}</CardTitle>
                    <Button asChild variant="link" className="h-6 hover:cursor-pointer">
                        <div onClick={() => setIsOpen(true)}>{t('viewAllButton')}</div>
                    </Button>
                </div>
                <div>
                    <Table className="border bg-white">
                        <TableHeader>
                            <TableRow>
                                <TableHead>{t('nameLabel')}</TableHead>
                                <TableHead>{t('expirationDateLabel')}</TableHead>
                            </TableRow>
                        </TableHeader>
                        <TableBody>
                            {normalizeArray(data?.users)?.length > 0 &&
                                normalizeArray(data?.users)
                                    .slice(0, 5)
                                    .map((member: any) => (
                                        <TableRow key={member.id}>
                                            <TableCell className="flex gap-2 items-center w-full">
                                                {member.firstName} {member.lastName}
                                            </TableCell>
                                            <TableCell>{dayjs(member.membershipEnd).format('DD.MM.YYYY')}</TableCell>
                                        </TableRow>
                                    ))}
                        </TableBody>
                    </Table>
                </div>
            </Card>
            <ExpiringMembershipsModal
                isOpen={isOpen}
                onClose={() => setIsOpen(false)}
                members={normalizeArray(data?.users)}
            />
        </>
    );
};
