import { Loader } from 'components/Loader/Loader';
import Cookies from 'js-cookie';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { AppRoutes } from 'Routes';

export const Logout = () => {
    const navigate = useNavigate();

    useEffect(() => {
        Cookies.remove('token');
        Cookies.remove('refreshToken');
        navigate(AppRoutes.login);
    }, [navigate]);

    return <Loader fullSize />;
};
