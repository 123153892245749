import { Button } from 'components/Button/Button';
import { FormError } from 'components/FormError/FormError';
import { Icon } from 'components/Icon/Icon';
import { Input } from 'components/Input/Input';
import { Label } from 'components/Label/Label';
import { Modal } from 'components/Modal/Modal';
import { Portal } from 'components/Portal/Portal';
import { Select } from 'components/Select/Select';
import { PET_TYPES } from 'lib/options';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useUserStore } from 'stores/user.store';

type AddPetModalProps = {
    isOpen: boolean;
    onClose: () => void;
    onConfirm: (child: any) => void;
    pet?: any;
    onRemove?: () => void;
};

export const AddPetModal: React.FC<AddPetModalProps> = ({ isOpen, onClose, onConfirm, pet, onRemove }) => {
    const loading = useUserStore(state => state.editLoading);
    const { t } = useTranslation('member');

    const {
        register,
        handleSubmit,
        control,
        reset,
        formState: { errors, isValid },
    } = useForm();

    useEffect(() => {
        if (pet) {
            const { id, ...rest } = pet;
            reset(rest);
        }
    }, [pet, reset]);

    const onSubmit = (formData: any) => {
        onConfirm(formData);
    };

    const closeModal = () => {
        onClose();
        reset();
    };

    return (
        <Portal>
            <Modal isOpen={isOpen} onClose={onClose} maxWidth={800} uncloseable>
                <div className="flex flex-col gap-8">
                    <div>
                        <div className="text-xl font-semibold text-zinc-700 mb-2">{t('addPet.title')}</div>
                        <div className="text-sm text-zinc-500">{t('addPet.subtitle')}</div>
                    </div>

                    <form>
                        <div className="flex flex-col gap-6">
                            <div className="flex gap-6">
                                <div className="flex-1">
                                    <Label>{t('addPet.name')}</Label>
                                    <Input
                                        {...register('name', {
                                            required: t('addPet.nameError'),
                                        })}
                                        placeholder={t('addPet.name')}
                                        error={!!errors.name}
                                    />
                                    <FormError error={errors.name} />
                                </div>
                                <div className="flex-1">
                                    <Label>{t('addPet.type')}</Label>
                                    <Controller
                                        control={control}
                                        name="type"
                                        rules={{ required: t('addPet.typeError') }}
                                        render={({ field: { onChange } }) => (
                                            <Select
                                                isSearchable={false}
                                                placeholder={t('addPet.typePlaceholder')}
                                                options={PET_TYPES}
                                                onChange={(val: any) => onChange(val.value)}
                                                defaultValue={PET_TYPES.find(g => pet?.type === g.value)}
                                                menuPortalTarget={document.body}
                                                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                                error={!!errors.type}
                                            />
                                        )}
                                    />
                                    <FormError error={errors.type} />
                                </div>
                            </div>

                            <div className="flex gap-6">
                                <div className="flex-1">
                                    <Label>{t('addPet.breed')}</Label>
                                    <Input
                                        {...register('breed', {
                                            required: t('addPet.breedError'),
                                        })}
                                        placeholder={t('addPet.breed')}
                                        error={!!errors.breed}
                                    />
                                    <FormError error={errors.breed} />
                                </div>
                                <div className="flex-1" />
                            </div>
                        </div>
                    </form>
                    <div className="flex justify-between gap-2 items-center">
                        {pet && (
                            <div className="text-sm text-rose-700 hover:cursor-pointer" onClick={onRemove}>
                                <Icon name="trash" />{' '}
                                <span className="underline font-medium">{t('familyInfo.removeButton')}</span>
                            </div>
                        )}
                        <div className="flex justify-end gap-2">
                            <Button variant="outline" type="button" onClick={() => closeModal()}>
                                {t('addPet.cancelButton')}
                            </Button>
                            <Button disabled={!isValid || loading} type="submit" onClick={handleSubmit(onSubmit)}>
                                {t('addPet.confirmButton')}
                            </Button>
                        </div>
                    </div>
                </div>
            </Modal>
        </Portal>
    );
};
