import { gql } from '@apollo/client';

export const ANNOUNCEMENT_CREATE = gql`
    mutation announcementCreate($input: AnnouncementInput!) {
        announcementCreate(input: $input) {
            announcement {
                id
            }
        }
    }
`;

export const ANNOUNCEMENT_UPDATE = gql`
    mutation announcementUpdate($announcement: ID!, $input: AnnouncementUpdateInput!) {
        announcementUpdate(announcement: $announcement, input: $input) {
            announcement {
                id
            }
        }
    }
`;
