import { gql } from '@apollo/client';

export const ADD_EVENT = gql`
    mutation eventCreate($input: EventInput!) {
        eventCreate(input: $input) {
            event {
                id
                name
            }
        }
    }
`;

export const EVENT_DELETE = gql`
    mutation eventDelete($event: ID!) {
        eventDelete(event: $event) {
            success
        }
    }
`;

export const EVENT_UPDATE = gql`
    mutation eventUpdate($input: EventUpdateInput!, $event: ID!) {
        eventUpdate(input: $input, event: $event) {
            event {
                id
                name
            }
        }
    }
`;

export const EVENT_CANCEL = gql`
    mutation eventCancel($event: ID!, $notify: Boolean) {
        eventCancel(event: $event, notifyMembers: $notify) {
            status
        }
    }
`;

export const EVENT_IMAGES_ADD = gql`
    mutation eventImageAdd($event: ID!, $input: [EventImageInput]!) {
        eventImageAdd(event: $event, input: $input) {
            event {
                id
            }
        }
    }
`;

export const EVENT_IMAGE_DELETE = gql`
    mutation eventImageDelete($image: ID!) {
        eventImageDelete(image: $image) {
            success
        }
    }
`;

export const EVENT_DOCUMENTS_ADD = gql`
    mutation eventDocumentAdd($event: ID!, $input: [EventDocumentInput]!) {
        eventDocumentAdd(event: $event, input: $input) {
            event {
                id
            }
        }
    }
`;

export const EVENT_DOCUMENT_DELETE = gql`
    mutation eventDocumentDelete($document: ID!) {
        eventDocumentDelete(document: $document) {
            success
        }
    }
`;

export const EVENT_INVITE = gql`
    mutation eventInvite($event: ID!, $members: [ID], $selectAll: Boolean) {
        eventInvite(event: $event, members: $members, selectAll: $selectAll) {
            event {
                id
            }
        }
    }
`

export const EVENT_CONFIRM_INVITE = gql`
    mutation eventConfirmInvite($event: ID!, $members: [ID], $selectAll: Boolean) {
        eventConfirmInvite(event: $event, members: $members, selectAll: $selectAll) {
            event {
                id
            }
        }
    }
`;

export const EVENT_REMOVE_INVITE = gql`
    mutation eventRemoveInvite($event: ID!, $members: [ID], $selectAll: Boolean) {
        eventRemoveInvite(event: $event, members: $members, selectAll: $selectAll) {
            event {
                id
            }
        }
    }
`