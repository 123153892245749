import { Icon } from 'components/Icon/Icon';
import { PageTitle } from 'components/PageTitle/PageTitle';
import { useNavigate } from 'react-router-dom';
import { useCompanyStore } from 'stores/company.store';

export const CompanyHeader = ({ children }: any) => {
    const company = useCompanyStore(state => state.company);
    const navigate = useNavigate();
    return (
        <PageTitle className={'pt-8 flex items-center flex-1'}>
            <div className="flex flex-1 h-10">
                <div className="flex-1">
                    <Icon name="back" className="h-6 text-2xl mr-2 hover:cursor-pointer" onClick={() => navigate(-1)} />

                    {company?.name}
                </div>
                <div>{children}</div>
            </div>
        </PageTitle>
    );
};
