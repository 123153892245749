import { useMutation } from '@apollo/client';
import { Breadcrumbs } from 'components/Breadcrumbs/Breadcrumbs';
import { Button } from 'components/Button/Button';
import { Container } from 'components/Container/Container';
import { Loader } from 'components/Loader/Loader';
import { PageTitle } from 'components/PageTitle/PageTitle';
import { useToast } from 'components/Toast/use-toast';
import dayjs from 'dayjs';
import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { AppRoutes } from 'Routes';
import { ADD_EVENT, EVENT_DOCUMENTS_ADD, EVENT_IMAGES_ADD } from 'services/events/mutation';
import { useEventStore } from 'stores/event.store';

import { EventCommentsForm } from './_components/EventCommentsForm';
import { EventFilesForm } from './_components/EventFilesForm';
import { EventInfoForm } from './_components/EventInfoForm';

export const NewEvent = () => {
    const [loading, setLoading] = useState(false);
    const { images, setImages, documents, setDocuments } = useEventStore(state => state);
    const [status, setStatus] = useState('DRAFT');
    const { t } = useTranslation('events');
    const [addEvent] = useMutation(ADD_EVENT);
    const [imagesAdd] = useMutation(EVENT_IMAGES_ADD);
    const [docsAdd] = useMutation(EVENT_DOCUMENTS_ADD);
    const { toast } = useToast();
    const navigate = useNavigate();

    const methods = useForm({
        mode: 'onChange',
    });

    const onSubmit = async (formData: any) => {
        formData.status = status;
        formData.date = formData.date ? dayjs(formData.date, 'DD.MM.YYYY').format('YYYY-MM-DD') : null;
        formData.capacity = parseInt(formData.capacity);

        try {
            setLoading(true);
            const eventResp = await addEvent({ variables: { input: formData } });
            if (eventResp.errors && eventResp.errors?.length > 0) {
                failedToast();
                return;
            }
            const eventId = eventResp.data.eventCreate?.event?.id;

            if (images.length > 0) {
                const imagesResp = await imagesAdd({ variables: { event: eventId, input: images } });
                if (imagesResp.errors && imagesResp.errors?.length > 0) {
                    failedToast();
                    return;
                }
                setImages([]);
            }

            if (documents.length > 0) {
                const docsResp = await docsAdd({ variables: { event: eventId, input: documents } });
                if (docsResp.errors && docsResp.errors?.length > 0) {
                    failedToast();
                    return;
                }
                setDocuments([]);
            }

            toast({
                title: t('createSuccess'),
                variant: 'success',
            });
            if (eventId) {
                navigate(status === 'DRAFT' ? AppRoutes.events : AppRoutes.eventPreview.replace(':id', eventId));
            }
        } catch (err) {
            console.error(err);
            failedToast();
        } finally {
            setLoading(false);
        }
    };

    const failedToast = () => {
        toast({
            title: t('createFailed'),
            description: t('createFailedDescription'),
            variant: 'warning',
        });
    };

    return (
        <Container>
            {loading && <Loader onUpdate className="fixed" />}
            <Breadcrumbs crumbs={['dashboard', 'events', 'new event']} />
            <PageTitle className="py-8" withBack>
                {t('newEventTitle')}
            </PageTitle>

            <FormProvider {...methods}>
                <form onSubmit={methods.handleSubmit(onSubmit)}>
                    <div className="flex flex-col gap-6">
                        <EventInfoForm />
                        <EventFilesForm />
                        <EventCommentsForm />
                    </div>
                    <div className="flex justify-end gap-2 mt-8">
                        <Button type="submit" variant="outline" onClick={() => setStatus('DRAFT')} disabled={loading}>
                            {t('saveDraftButton')}
                        </Button>
                        <Button type="submit" onClick={() => setStatus('UPCOMING')} disabled={loading}>
                            {t('saveEventButton')}
                        </Button>
                    </div>
                </form>
            </FormProvider>
        </Container>
    );
};
