import { cn } from 'lib/utils';

export const Card = ({ className, children }: any) => {
    return <div className={cn('bg-zinc-50 p-6', className)}>{children}</div>;
};

export const CardTitle = ({ className, children }: any) => {
    return <div className={cn('text-lg font-semibold text-zinc-700 mb-2', className)}>{children}</div>;
};

export const CardSubtitle = ({ className, children }: any) => {
    return <div className={cn('text-zinc-500 mb-8', className)}>{children}</div>;
};
