import { Card } from 'components/Card/Card';
import { Icon } from 'components/Icon/Icon';
import { ViewButton } from 'components/ViewButton/ViewButton';
import { useTranslation } from 'react-i18next';
import { AppRoutes } from 'Routes';
import { useCompanyStore } from 'stores/company.store';

export const CompanyPreviewInfo = () => {
    const company = useCompanyStore(state => state.company);
    const { t } = useTranslation('company');
    return (
        <Card className="flex flex-col gap-8">
            <div className="flex justify-between">
                <div className="text-lg font-semibold">{t('companyInfoTitle')}</div>
                <ViewButton to={AppRoutes.companyProfile.replace(':id', company?.id)} />
            </div>
            <div className="bg-white p-6 border border-rose-100">
                <div className="font-bold">{company?.name}</div>
                <div className="bg-rose-100 h-px w-full my-4"></div>
                <div className="flex flex-col gap-6">
                    <div className="flex gap-2 items-center">
                        <Icon name="phone" className="h-4" />
                        <span className="text-zinc-500">{company?.phoneNumber}</span>
                    </div>
                    <div className="flex gap-2 items-center">
                        <Icon name="mail" className="h-4" />
                        <span className="text-zinc-500">{company?.email}</span>
                    </div>
                </div>
            </div>
        </Card>
    );
};
