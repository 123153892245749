import { useQuery } from '@apollo/client';
import { Avatar } from 'components/Avatar/Avatar';
import { Button } from 'components/Button/Button';
import Checkbox from 'components/Checkbox/Checkbox';
import { Modal } from 'components/Modal/Modal';
import { Portal } from 'components/Portal/Portal';
import { SearchFilter } from 'components/SearchFilter/SearchFilter';
import { SortFilter } from 'components/SortFilter/SortFilter';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from 'components/Table/Table';
import { normalizeArray } from 'lib/utils';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { USERS } from 'services/users/query';

const SORT_OPTIONS = [
    { value: 'A_Z', label: 'A-Z' },
    { value: 'Z_A', label: 'Z-A' },
    { value: 'MOST_RECENT', label: 'Most recent' },
    { value: 'LEAST_RECENT', label: 'Least recent' },
];

type AttachMemberModalProps = {
    isOpen: boolean;
    onClose: () => void;
    onAttach: (members: string[]) => void;
    existingMembers: string[];
};
export const AttachMemberModal: React.FC<AttachMemberModalProps> = ({ isOpen, onClose, onAttach, existingMembers }) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [selectedMembers, setSelectedMembers] = useState<string[]>([]);
    const [availableMembers, setAvailableMembers] = useState<string[]>([]);
    const { t } = useTranslation('company');
    const { data, refetch } = useQuery(USERS, { variables: { isMember: true, first: 100 } });

    useEffect(() => {
        if (data) {
            setAvailableMembers(normalizeArray(data.users).filter((u: any) => !existingMembers.includes(u.id)));
        }
    }, [data, existingMembers]);

    useEffect(() => {
        const search = searchParams.get('search');
        const sort = searchParams.get('sort');

        refetch({
            search: search,
            sort: sort,
        });
    }, [searchParams, refetch]);

    const toggleMember = (id: string) => {
        if (selectedMembers.includes(id)) {
            setSelectedMembers(selectedMembers.filter(member => member !== id));
        } else {
            setSelectedMembers([...selectedMembers, id]);
        }
    };

    const attachMembers = () => {
        if (!selectedMembers) return;
        onAttach(selectedMembers);
        searchParams.delete('search');
        searchParams.delete('sort');
        setSearchParams(searchParams);
        setSelectedMembers([]);
    };

    const closeModal = () => {
        searchParams.delete('search');
        searchParams.delete('sort');
        setSearchParams(searchParams);
        setSelectedMembers([]);
        onClose();
    };

    return (
        <Portal>
            <Modal isOpen={isOpen} onClose={onClose} maxWidth={800} className="flex flex-col" uncloseable>
                <div>
                    <div className="text-lg font-semibold text-zinc-700 mb-2">{t('attachMemberTitle')}</div>
                    <div className="text-zinc-500 text-sm">{t('attachMemberText')}</div>
                </div>
                <div className="mt-6 flex gap-2">
                    <SearchFilter placeholder="Search by name..." />
                    <SortFilter options={SORT_OPTIONS} />
                </div>
                <div className="mt-4 overflow-auto">
                    <Table className="border">
                        <TableHeader>
                            <TableRow>
                                <TableHead className="w-8" />
                                <TableHead>{t('tableName')}</TableHead>
                            </TableRow>
                        </TableHeader>
                        <TableBody>
                            {availableMembers?.map((user: any) => (
                                <TableRow key={user.id}>
                                    <TableCell>
                                        <Checkbox
                                            onChange={() => toggleMember(user.id)}
                                            checked={selectedMembers.includes(user.id)}
                                        />
                                    </TableCell>
                                    <TableCell className="flex gap-2 items-center pl-0">
                                        <Avatar image={user.image} size={32} />
                                        {user.firstName} {user.lastName}
                                    </TableCell>
                                </TableRow>
                            ))}
                            {availableMembers.length === 0 && (
                                <TableRow>
                                    <TableCell colSpan={2} className="text-center">
                                        {t('noResults')}
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </div>
                <div className="flex justify-end gap-2 mt-2">
                    <Button variant="outline" onClick={() => closeModal()}>
                        {t('cancelButton')}
                    </Button>
                    <Button disabled={selectedMembers.length === 0} onClick={() => attachMembers()}>
                        {t('continueButton')}
                    </Button>
                </div>
            </Modal>
        </Portal>
    );
};
