import { FC, useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';

type ModalProps = {
    children?: React.ReactNode;
};

export const Portal: FC<ModalProps> = ({ children }) => {
    const el = useRef(document.createElement('div'));
    const mount = document.getElementById('popup-portal');

    useEffect(() => {
        const current = el.current;
        if (mount) {
            mount!.appendChild(current);
        }

        return () => {
            if (mount) mount!.removeChild(current);
        };
    }, [el, mount]);

    return createPortal(children, el.current);
};
