import { useMutation } from '@apollo/client';
import { Button } from 'components/Button/Button';
import { Card, CardSubtitle, CardTitle } from 'components/Card/Card';
import { MemberBox } from 'components/MemberBox/MemberBox';
import { useToast } from 'components/Toast/use-toast';
import { normalizeArray } from 'lib/utils';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MEMBERS_ATTACH, MEMBER_REMOVE } from 'services/companies/mutation';
import { ADD_MEMBER } from 'services/users/mutation';
import { useCompanyStore } from 'stores/company.store';

import { AttachMemberModal } from './AttachMemberModal';
import { CreateMemberModal } from './CreateMemberModal';
import { RemoveMemberModal } from './RemoveMemberModal';

export const CompanyMembers = () => {
    const [open, setOpen] = useState(false);
    const [attachOpen, setAttachOpen] = useState(false);
    const [newMemberOpen, setNewMemberOpen] = useState(false);
    const [memberToDelete, setMemberToDelete] = useState('');
    const [memberRemove] = useMutation(MEMBER_REMOVE);
    const [membersAttach] = useMutation(MEMBERS_ATTACH);
    const [memberCreate] = useMutation(ADD_MEMBER);
    const { company, getCompany } = useCompanyStore(state => state);
    const { t } = useTranslation('company');
    const { toast } = useToast();

    const removeMember = (id: string) => {
        setMemberToDelete(id);
        setOpen(true);
    };

    const cancelDelete = () => {
        setMemberToDelete('');
        setOpen(false);
    };

    const confirmDelete = async () => {
        if (!memberToDelete) return;
        try {
            const { data, errors } = await memberRemove({
                variables: { member: memberToDelete, company: company?.id },
            });
            if (data.companyMemberRemove?.success) {
                setOpen(false);
                setMemberToDelete('');
                getCompany(company.id);
                toast({
                    title: t('removeMemberSuccess'),
                    variant: 'success',
                });
            }
            if (errors && errors?.length > 0) {
                failedToast(errors[0].message);
            }
        } catch (_) {
            failedToast();
        }
    };

    const attachMembers = async (members: string[]) => {
        if (!members.length) return;
        try {
            const { data, errors } = await membersAttach({ variables: { members: members, company: company?.id } });
            if (data.companyMemberAdd?.success) {
                setAttachOpen(false);
                getCompany(company.id);
                toast({
                    title: t('attachMemberSuccess'),
                    variant: 'success',
                });
            }
            if (errors && errors?.length > 0) {
                failedToast(errors[0].message);
            }
        } catch (_) {
            failedToast();
        }
    };

    const createMember = async (formData: any) => {
        if (!formData) return;
        try {
            const { data, errors } = await memberCreate({ variables: { input: formData } });
            if (data.memberCreate?.user) {
                attachMembers([data.memberCreate.user.id]);
                setNewMemberOpen(false);
            }
            if (errors && errors?.length > 0) {
                failedToast(errors[0].message);
            }
        } catch (_) {
            failedToast();
        }
    };

    const failedToast = (message?: string) => {
        toast({
            title: t('failToastTitle'),
            description: message || t('failToastText'),
            variant: 'warning',
        });
    };

    return (
        <>
            <Card>
                <CardTitle>{t('companyMembersTitle')}</CardTitle>
                <CardSubtitle>{t('companyMembersSubtitle')}</CardSubtitle>
                <div className="grid grid-cols-2 gap-6">
                    {normalizeArray(company?.members)?.map((member: any) => (
                        <MemberBox key={member.id} member={member} onRemove={() => removeMember(member.id)} />
                    ))}
                </div>
                <div className="flex gap-2 mt-8">
                    <Button variant="outline" onClick={() => setAttachOpen(true)} type="button">
                        {t('connectProfileButton')}
                    </Button>
                    <Button variant="outline" onClick={() => setNewMemberOpen(true)} type="button">
                        {t('createMemberButton')}
                    </Button>
                </div>
            </Card>
            <RemoveMemberModal isOpen={open} onClose={cancelDelete} onConfirm={confirmDelete} />
            <AttachMemberModal
                isOpen={attachOpen}
                onClose={() => setAttachOpen(false)}
                onAttach={attachMembers}
                existingMembers={normalizeArray(company?.members)?.map((m: any) => m.id)}
            />
            <CreateMemberModal isOpen={newMemberOpen} onClose={() => setNewMemberOpen(false)} onCreate={createMember} />
        </>
    );
};
