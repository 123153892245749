import { useMutation } from '@apollo/client';
import { AddNewContact } from 'components/AddNewContact/AddNewContact';
import { Breadcrumbs } from 'components/Breadcrumbs/Breadcrumbs';
import { CompanyHeader } from 'components/CompanyHeader/CompanyHeader';
import { CompanyTabs } from 'components/CompanyTabs/CompanyTabs';
import { ContactInfoModal } from 'components/ContactInfoModal/ContactInfoModal';
import { EditContactModal } from 'components/ContactsModal/EditContactModal';
import { EmptyState } from 'components/EmptyState/EmptyState';
import { StatusFilter } from 'components/StatusFilter/StatusFilter';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from 'components/Table/Table';
import { TableActions } from 'components/TableActions/TableActions';
import { useToast } from 'components/Toast/use-toast';
import { cn, normalizeArray } from 'lib/utils';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { CONTACT_STATUS_UPDATE } from 'services/contacts/mutation';
import { useCompanyStore } from 'stores/company.store';

const STATUSES = ['ACTIVE', 'ARCHIVED'];

export const CompanyContacts = () => {
    const [status, setStatus] = useState(['ACTIVE', 'ARCHIVED']);
    const [selectedContact, setSelectedContact] = useState(null);
    const [editContact, setEditContact] = useState(null);
    const [searchParams] = useSearchParams();
    const [statusUpdate] = useMutation(CONTACT_STATUS_UPDATE);
    const { company, getCompany } = useCompanyStore(state => state);
    const { t } = useTranslation('translation');
    const { t: c } = useTranslation('company');
    const { toast } = useToast();

    useEffect(() => {
        const status = searchParams.get('status')?.split(',');
        if (status) {
            setStatus(status);
        } else {
            setStatus(['ACTIVE', 'ARCHIVED']);
        }
    }, [searchParams]);

    const edit = () => {
        setEditContact(selectedContact);
        setSelectedContact(null);
    };

    const handleStatusChange = (contact: any) => {
        if (!contact) return;
        statusUpdate({
            variables: { contact: contact.id, status: contact.status === 'ACTIVE' ? 'ARCHIVED' : 'ACTIVE' },
        }).then(r => {
            if (r.data.contactStatusUpdate?.status) {
                toast({
                    title: c('statusUpdateSuccess'),
                    variant: 'success',
                });
                if (company?.id) getCompany(company?.id);
            } else {
                toast({
                    title: c('saveErrorTitle'),
                    description: c('saveErrorMessage'),
                    variant: 'warning',
                });
            }
        });
    };

    return (
        <div>
            <Breadcrumbs crumbs={['members', 'companies', `${company?.name}`]} />
            <CompanyHeader />
            <CompanyTabs />
            {!!normalizeArray(company?.contacts)?.length && (
                <div className="flex justify-between mb-4">
                    <StatusFilter options={STATUSES} />
                    <AddNewContact types={['company', 'assistant', 'other']} company={company?.id} />
                </div>
            )}
            {normalizeArray(company?.contacts)?.length === 0 ? (
                <EmptyState
                    image="/images/no-contacts.png"
                    title="No contacts registered yet"
                    text="You can add a new contact by clicking the button below"
                >
                    <AddNewContact types={['company', 'assistant', 'other']} company={company?.id} />
                </EmptyState>
            ) : (
                <Table className="border overflow-visible">
                    <TableHeader>
                        <TableRow>
                            <TableHead>{t('contacts.typeLabel')}</TableHead>
                            <TableHead>{t('contacts.nameLabel')}</TableHead>
                            <TableHead>{t('contacts.phoneLabel')}</TableHead>
                            <TableHead>{t('contacts.billingLabel')}</TableHead>
                            <TableHead />
                        </TableRow>
                    </TableHeader>
                    <TableBody className="hover:cursor-pointer">
                        {company?.contacts?.edges?.length !== 0 &&
                            normalizeArray(company?.contacts)
                                ?.filter((c: any) => status.includes(c.status))
                                .map((contact: any) => (
                                    <TableRow
                                        key={contact.id}
                                        className={cn(contact.status === 'ARCHIVED' && 'text-zinc-400')}
                                    >
                                        <TableCell className="capitalize" onClick={() => setSelectedContact(contact)}>
                                            {contact.type.toLowerCase()}
                                        </TableCell>
                                        <TableCell onClick={() => setSelectedContact(contact)}>
                                            {contact.name} {contact.lastName}
                                        </TableCell>
                                        <TableCell onClick={() => setSelectedContact(contact)}>
                                            {contact.phoneNumber}
                                        </TableCell>
                                        <TableCell onClick={() => setSelectedContact(contact)}>
                                            {contact.billToThisContact ? 'Yes' : '-'}
                                        </TableCell>
                                        <TableCell className="py-3 justify-end flex">
                                            <TableActions
                                                status={contact.status}
                                                onEdit={() => setEditContact(contact)}
                                                onStatusChange={() => handleStatusChange(contact)}
                                            />
                                        </TableCell>
                                    </TableRow>
                                ))}
                    </TableBody>
                </Table>
            )}
            <ContactInfoModal onClose={() => setSelectedContact(null)} contact={selectedContact} onEdit={edit} />
            <EditContactModal onClose={() => setEditContact(null)} contact={editContact} company={company?.id} />
        </div>
    );
};
