import { useMutation } from '@apollo/client';
import { Breadcrumbs } from 'components/Breadcrumbs/Breadcrumbs';
import { Button } from 'components/Button/Button';
import { Container } from 'components/Container/Container';
import { PageTitle } from 'components/PageTitle/PageTitle';
import { useToast } from 'components/Toast/use-toast';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { AppRoutes } from 'Routes';
import { CREATE_COMPANY, MEMBERS_ATTACH } from 'services/companies/mutation';

import { CompanyInfo } from './_components/CompanyInfo/CompanyInfo';
import { NewCompanyMembers } from './_components/CompanyMembers/NewCompanyMembers';
import { CompanyProfile } from './_components/CompanyProfile/CompanyProfile';

export const NewCompany = () => {
    const methods = useForm();
    const { t } = useTranslation('company');
    const navigate = useNavigate();
    const { toast } = useToast();
    const [searchParams] = useSearchParams();
    const [saveCompany] = useMutation(CREATE_COMPANY);
    const [attachMember] = useMutation(MEMBERS_ATTACH);

    const onSubmit = (data: any) => {
        data.status = 'ACTIVE';
        if (!data.billToThisContact) data.billToThisContact = false;

        saveCompany({ variables: { input: data } })
            .then((res: any) => {
                if (res.data.companyCreate.company) {
                    handleSuccess(res.data.companyCreate.company);
                } else {
                    handleError();
                }
            })
            .catch(e => {
                handleError();
            });
    };

    const handleSuccess = (company: any) => {
        const memberId = searchParams.get('member');

        if (memberId) {
            attachMember({ variables: { company: company.id, members: [memberId] } }).then((res: any) => {
                if (res.data.companyMemberAdd?.success) {
                    toast({
                        description: t('saveSuccessMessage'),
                        variant: 'success',
                    });
                    navigate(AppRoutes.companyPreview.replace(':id', company.id));
                } else {
                    handleError();
                }
            });
        } else {
            toast({
                description: t('saveSuccessMessage'),
                variant: 'success',
            });
            navigate(AppRoutes.companyPreview.replace(':id', company.id));
        }
    };

    const handleError = () => {
        toast({
            title: t('saveErrorTitle'),
            description: t('saveErrorMessage'),
            variant: 'warning',
        });
    };

    return (
        <Container>
            <Breadcrumbs crumbs={['dashboard', 'companies', 'new company']} />
            <PageTitle className="py-8" withBack>
                {t('newCompanyTitle')}
            </PageTitle>

            <FormProvider {...methods}>
                <form onSubmit={methods.handleSubmit(onSubmit)}>
                    <div className="flex flex-col gap-6">
                        <div className="flex gap-6">
                            <CompanyProfile />
                            <CompanyInfo />
                        </div>
                        <NewCompanyMembers />
                    </div>
                    <div className="flex justify-end py-8">
                        <Button>{t('saveCompanyButton')}</Button>
                    </div>
                </form>
            </FormProvider>
        </Container>
    );
};
