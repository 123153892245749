import { Card, CardTitle } from 'components/Card/Card';
import { PreviewLabel, PreviewValue } from 'components/PreviewFields/PreviewFields';
import dayjs from 'dayjs';
import { useUserStore } from 'stores/user.store';

export const PartnerInfoPreview = () => {
    const partner = useUserStore(state => state.user?.partner);
    return (
        <Card className="flex-1 flex flex-col gap-8">
            <CardTitle>Personal information</CardTitle>

            <div className="flex flex-col gap-6">
                <div className="flex gap-6">
                    <div className="flex-1">
                        <PreviewLabel>First name</PreviewLabel>
                        <PreviewValue>{partner?.firstName}</PreviewValue>
                        {partner?.isSpouse && <span className="text-rose-300 text-sm">Spouse</span>}
                    </div>
                    <div className="flex-1">
                        <PreviewLabel>Last name</PreviewLabel>
                        <PreviewValue>{partner?.lastName}</PreviewValue>
                    </div>
                </div>
                <div className="flex gap-6">
                    <div className="flex-1">
                        <PreviewLabel>Salutation</PreviewLabel>
                        <PreviewValue className="capitalize">{partner?.salutation.toLowerCase()}.</PreviewValue>
                    </div>
                    <div className="flex-1">
                        <PreviewLabel>Date of birth</PreviewLabel>
                        <PreviewValue>{dayjs(partner?.dateOfBirth).format('DD.MM.YYYY')}</PreviewValue>
                    </div>
                </div>

                <div className="flex gap-6">
                    <div className="flex-1">
                        <PreviewLabel>Company</PreviewLabel>
                        <PreviewValue>{partner?.company}</PreviewValue>
                    </div>
                    <div className="flex-1">
                        <PreviewLabel>Job title</PreviewLabel>
                        <PreviewValue>{partner?.jobTitle}</PreviewValue>
                    </div>
                </div>
            </div>
        </Card>
    );
};
