import { ApolloClient, ApolloLink, createHttpLink, fromPromise, InMemoryCache } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import Cookies from 'js-cookie';
import { REFRESH_TOKEN } from 'services/auth/mutation';

let isRefreshing = false;
let pendingRequests: Function[] = [];

const httpLink = createHttpLink({
    uri: process.env.REACT_APP_API_URL,
});

const setIsRefreshing = (value: boolean) => {
    isRefreshing = value;
};

const addPendingRequest = (pendingRequest: Function) => {
    pendingRequests.push(pendingRequest);
};

const renewTokenApiClient = new ApolloClient({
    link: ApolloLink.from([httpLink]),
    cache: new InMemoryCache(),
});

const resolvePendingRequests = () => {
    pendingRequests.map(callback => callback());
    pendingRequests = [];
};

const refreshToken = async () => {
    const refreshToken = Cookies.get('refreshToken');

    const resp = await renewTokenApiClient.mutate({ mutation: REFRESH_TOKEN, variables: { token: refreshToken } });

    const { errors } = resp;
    if (errors) {
        console.info('Refresh token error:', errors);
    }

    const accessToken = resp.data.refreshToken.token;

    // localStorage.setItem(LocalStorageKey.ACCESS_TOKEN, accessToken);
    Cookies.set('token', accessToken);
    return accessToken;
};

export const expiredTokenError = onError(({ graphQLErrors, forward, operation }) => {
    if (graphQLErrors) {
        for (const e of graphQLErrors) {
            const err = e as any;

            if (err.message === 'Signature has expired' || err.message === 'Error decoding signature') {
                // Cookies.remove('token');
                // window.location.replace('/login');
                if (!isRefreshing) {
                    setIsRefreshing(true);
                    return fromPromise(
                        refreshToken().catch(() => {
                            // If token refresh fails try to resolve pending requests and clear local storage
                            resolvePendingRequests();
                            setIsRefreshing(false);
                            // localStorage.removeItem(LocalStorageKey.REFRESH_TOKEN);
                            // localStorage.removeItem(LocalStorageKey.ACCESS_TOKEN);
                            Cookies.remove('token');
                            Cookies.remove('refreshToken');
                            window.location.replace('/login');

                            return forward(operation);
                        }),
                    ).flatMap(() => {
                        // After successful refresh is done call all pending requests
                        resolvePendingRequests();
                        setIsRefreshing(false);

                        return forward(operation);
                    });
                } else {
                    // Queue all pending requests if refresh is in progress
                    return fromPromise(
                        new Promise(resolve => {
                            addPendingRequest(resolve);
                        }),
                    ).flatMap(() => {
                        return forward(operation);
                    });
                }
            }
        }
    }
});
