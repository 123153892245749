import { FutureAnniversary } from 'components/AnniversaryBoxes/FutureAnniversary';
import { PastAnniversary } from 'components/AnniversaryBoxes/PastAnniversary';
import { Button } from 'components/Button/Button';
import { CardSubtitle, CardTitle } from 'components/Card/Card';
import { Modal } from 'components/Modal/Modal';
import { Portal } from 'components/Portal/Portal';
import { Tab, Tabs } from 'components/Tabs/Tabs';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

type AnniversariesModalProps = {
    isOpen: boolean;
    onClose: () => void;
    current: any[];
    past: any[];
    focusOn: string | null;
};
export const AnniversariesModal: React.FC<AnniversariesModalProps> = ({ isOpen, onClose, current, past, focusOn }) => {
    const [activeTab, setActiveTab] = useState('PRESENT');
    const { t } = useTranslation('dashboard');

    useEffect(() => {
        if (focusOn) setActiveTab(focusOn);
    }, [focusOn]);

    return (
        <Portal>
            <Modal isOpen={isOpen} onClose={onClose} maxWidth={800} className="min-h-[60vh] flex flex-col">
                <CardTitle>{t('anniversariesTitle')}</CardTitle>
                <CardSubtitle className="mb-6">{t('anniversariesModalText')}</CardSubtitle>
                <Tabs className="pt-0">
                    <Tab active={activeTab === 'PRESENT'} onClick={() => setActiveTab('PRESENT')}>
                        {t('tabPresent')}
                    </Tab>
                    <Tab active={activeTab === 'PAST'} onClick={() => setActiveTab('PAST')}>
                        {t('tabPast')}
                    </Tab>
                </Tabs>
                <div className="flex-1">
                    {activeTab === 'PRESENT' && <PresentTab anniversaries={current} />}
                    {activeTab === 'PAST' && <PastTab anniversaries={past} />}
                </div>
                <div className="flex justify-end mt-6">
                    <Button variant="outline" onClick={onClose}>
                        {t('closeButton')}
                    </Button>
                </div>
            </Modal>
        </Portal>
    );
};

const PresentTab = ({ anniversaries }: any) => {
    const [today, setToday] = useState<any>([]);
    const [future, setFuture] = useState<any>([]);
    const { t } = useTranslation('dashboard');

    useEffect(() => {
        if (anniversaries) {
            setToday(anniversaries.filter((a: any) => a.daysNumber === 0));
            setFuture(anniversaries.filter((a: any) => a.daysNumber > 0));
        }
    }, [anniversaries]);

    if (anniversaries?.length === 0)
        return <div className="text-zinc-500 text-center">{t('noPresentAnniversaries')}</div>;

    return (
        <div className="flex flex-col gap-6">
            {today.length > 0 && (
                <div className="flex flex-col gap-4">
                    <div className="font-bold">{t('todayTitle')}</div>
                    <div className="flex flex-col gap-2">
                        {today.map((a: any) => (
                            <FutureAnniversary key={a.id} data={a} />
                        ))}
                    </div>
                </div>
            )}
            {future.length > 0 && (
                <div className="flex flex-col gap-4">
                    <div className="font-bold">{t('futureTitle')}</div>
                    <div className="flex flex-col gap-2">
                        {future.map((a: any) => (
                            <FutureAnniversary key={a.id} data={a} />
                        ))}
                    </div>
                </div>
            )}
        </div>
    );
};

const PastTab = ({ anniversaries }: any) => {
    const { t } = useTranslation('dashboard');

    if (anniversaries?.length === 0) return <div className="text-zinc-500 text-center">{t('noPastAnniversaries')}</div>;

    return (
        <div className="flex flex-col gap-2">
            {anniversaries.map((a: any, index: number) => (
                <PastAnniversary key={index} data={a} />
            ))}
        </div>
    );
};
