import { Avatars } from 'components/Avatars/Avatars';
import { FullEvent } from 'components/FullEvent/FullEvent';
import { Icon } from 'components/Icon/Icon';
import dayjs from 'dayjs';
import { normalizeArray } from 'lib/utils';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { AppRoutes } from 'Routes';

export const DashboardEventsGrid = ({ events }: any) => {
    return (
        <div className="grid gap-6 grid-cols-3">
            {events?.map((event: any) => <EventGridItem key={event.id} event={event} />)}
        </div>
    );
};

const EventGridItem = ({ event }: any) => {
    const navigate = useNavigate();
    const { t } = useTranslation('dashboard');
    const goToEvent = (id: string) => {
        navigate(AppRoutes.eventPreview.replace(':id', id));
    };

    return (
        <div className="bg-white p-4 border">
            <div className="flex gap-4 justify-between">
                <div className="font-bold truncate overflow-hidden">{event.name}</div>
                <div onClick={() => goToEvent(event.id)} className="hover:cursor-pointer hover:text-rose-700">
                    <Icon name="right-arrow" className="text-xl" />
                </div>
            </div>
            <div className="text-sm text-zinc-500">
                <div className="my-[6px] ">{dayjs(event.date).format('DD.MM.YYYY')}</div>
                <div className="flex">
                    <div className="flex gap-4">
                        <div>
                            {t('confirmed')} {event.confirmed?.totalCount}
                        </div>
                        <div>|</div>
                        <div>
                            {t('capacity')} {event.capacity}
                        </div>
                    </div>
                    <FullEvent event={event} />
                </div>
            </div>
            <div className="mt-5">
                <Avatars members={normalizeArray(event?.invited)} total={event.invited.totalCount} />
            </div>
        </div>
    );
};
