import { useMutation } from '@apollo/client';
import { Button } from 'components/Button/Button';
import { Icon } from 'components/Icon/Icon';
import { useToast } from 'components/Toast/use-toast';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EVENT_IMAGE_DELETE, EVENT_IMAGES_ADD } from 'services/events/mutation';
import { useEventStore } from 'stores/event.store';

export const EventImages = ({ canEdit }: any) => {
    const [totalImages, setTotalImages] = useState(0);
    const { event, getEvent, setImages, images } = useEventStore(state => state);
    const [imageDelete] = useMutation(EVENT_IMAGE_DELETE);
    const [imagesAdd, { loading: imagesLoading }] = useMutation(EVENT_IMAGES_ADD);
    const { t } = useTranslation('events');
    const { toast } = useToast();
    const fileInputRef = useRef<any>();

    useEffect(() => {
        setTotalImages((event?.images?.length || 0) + images?.length);
    }, [event, images, setTotalImages]);

    const deleteImage = (id: string) => {
        if (!id) return;
        imageDelete({ variables: { image: id } }).then(resp => {
            if (resp?.data?.eventImageDelete?.success) {
                toast({
                    title: t('imageDeleteSuccess'),
                    variant: 'success',
                });
                getEvent(event.id);
            } else {
                toast({
                    title: t('createFailed'),
                    description: t('createFailedDescription'),
                    variant: 'warning',
                });
            }
        });
    };

    const removeImageFromQueue = (image: any) => {
        const list = images.filter((i: any) => i.image !== image);
        setImages([...list]);
    };

    const handleChange = (files: any) => {
        if (files.length <= 0 || event?.images?.length === 5) return;
        const filesAllowed = 5 - event?.images?.length;

        if (files.length > filesAllowed) {
            toast({
                title: t('maxPictures'),
                variant: 'warning',
            });
        } else {
            const list = [];
            for (const f of files) list.push({ image: f, name: f.name });

            if (event?.id) addImages(list);
            else setImages([...images, ...list]);
        }
    };

    const addImages = (list: any) => {
        imagesAdd({ variables: { event: event.id, input: list } }).then(resp => {
            if (resp.data.eventImageAdd.event) {
                getEvent(event.id);
            }
        });
    };
    return (
        <div>
            <div className="text-zinc-700 mb-2">{t('picturesLabel')}</div>
            <div className="flex gap-4">
                {event?.images?.map((item: any) => (
                    <EventImage
                        key={item.id}
                        image={item.image}
                        onDelete={() => deleteImage(item.id)}
                        deleteable={canEdit}
                    />
                ))}
                {images?.map((item: any, i: number) => (
                    <EventImagePreview
                        key={i}
                        image={URL.createObjectURL(item.image)}
                        onDelete={() => removeImageFromQueue(item.image)}
                    />
                ))}
            </div>
            <input
                multiple
                type="file"
                hidden
                onChange={ev => handleChange(ev.target.files)}
                ref={fileInputRef}
                accept="image/png, image/jpeg, image/jpg, image/heic, image/heif"
            />
            {totalImages < 5 && canEdit && (
                <div className="mt-4">
                    <Button
                        disabled={imagesLoading}
                        variant="outline"
                        type="button"
                        onClick={() => fileInputRef.current.click()}
                    >
                        {t('addPicturesButton')}
                    </Button>
                </div>
            )}
        </div>
    );
};

const EventImage = ({ image, onDelete, deleteable }: any) => {
    if (!image) return null;
    return (
        <div className="relative w-[140px] h-[140px] bg-zinc-200">
            <img src={`${image.url}?w=140&h=140&fit=crop`} alt={image.alt} />
            {deleteable && (
                <Button className="absolute top-2 right-2 w-8 h-8 text-base" onClick={onDelete} type="button">
                    <Icon name="trash" className="h-5" />
                </Button>
            )}
        </div>
    );
};

const EventImagePreview = ({ image, onDelete }: any) => {
    if (!image) return null;
    return (
        <div className="relative w-[140px] h-[140px] bg-zinc-200">
            <img src={`${image}`} alt="" className="h-full w-full object-cover opacity-50" />
            <Button className="absolute top-2 right-2 w-8 h-8 text-base" onClick={onDelete} type="button">
                <Icon name="trash" className="h-5" />
            </Button>
        </div>
    );
};
