import { Avatar } from 'components/Avatar/Avatar';
import { Balance } from 'components/Balance/Balance';
import { Button } from 'components/Button/Button';
import { Card } from 'components/Card/Card';
import { Icon } from 'components/Icon/Icon';
import { Label } from 'components/Label/Label';
import { PreviewUploadImage } from 'components/PreviewUploadImage/PreviewUploadImage';
import { UserSettings } from 'components/UserSettings/UserSettings';
import { useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useUserStore } from 'stores/user.store';

export const MemberInfo = () => {
    const { user, getUser } = useUserStore(state => state);
    const { t } = useTranslation('member');
    const fileInputRef = useRef<any>();
    const { setValue } = useFormContext();
    const [image, setImage] = useState<any>(null);

    const handleChange = (event: any) => {
        if (event.target.files.length <= 0) return;
        setValue('image', event.target.files[0]);
        setImage(URL.createObjectURL(event.target.files[0]));
    };

    return (
        <Card className="flex flex-col gap-4 pb-2 relative">
            <div className="relative">
                <Avatar image={user?.image} status={user?.status} />
                <PreviewUploadImage image={image} />
                <Button
                    type="button"
                    className="absolute bottom-2 right-2 w-8 h-8"
                    onClick={() => fileInputRef.current.click()}
                >
                    <Icon name="upload" />
                </Button>
                <input
                    multiple={false}
                    type="file"
                    hidden
                    onChange={handleChange}
                    ref={fileInputRef}
                    accept="image/png, image/jpeg, image/jpg, image/heic, image/heif"
                />
            </div>
            <div>
                <Label>{t('userId')}</Label>
                <div className="text-rose-700">{user?.memberId || '-'}</div>
            </div>
            <div>
                <Label>{t('userType')}</Label>
                <div className="text-rose-700">{user?.memberType ? t(user?.memberType) : t('INDIVIDUAL')}</div>
            </div>
            <div className="flex-1">
                <Label>{t('balance')}</Label>
                <div className="text-rose-700">
                    <Balance balance={user?.balance} />
                </div>
            </div>
            <div>
                {user && (
                    <UserSettings
                        actions={['BLOCK', 'DELETE', 'PASSWORD_RESET']}
                        user={user}
                        onChange={() => getUser(user?.id)}
                    />
                )}
            </div>
        </Card>
    );
};
