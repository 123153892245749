import { Avatar } from 'components/Avatar/Avatar';
import { Button } from 'components/Button/Button';
import { Card } from 'components/Card/Card';
import { Icon } from 'components/Icon/Icon';
import { PreviewUploadImage } from 'components/PreviewUploadImage/PreviewUploadImage';
import { useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useUserStore } from 'stores/user.store';

export const PartnerImage = () => {
    const partner = useUserStore(state => state.user?.partner);

    const fileInputRef = useRef<any>();
    const { setValue } = useFormContext();
    const [image, setImage] = useState<any>(null);

    const handleChange = (event: any) => {
        if (event.target.files.length <= 0) return;
        setValue('image', event.target.files[0]);
        setImage(URL.createObjectURL(event.target.files[0]));
    };

    return (
        <div>
            <Card>
                <div className="relative">
                    <Avatar image={partner?.image} />
                    <PreviewUploadImage image={image} />
                    <Button
                        type="button"
                        className="absolute bottom-2 right-2 w-8 h-8"
                        onClick={() => fileInputRef.current.click()}
                    >
                        <Icon name="upload" />
                    </Button>
                    <input
                        multiple={false}
                        type="file"
                        hidden
                        onChange={handleChange}
                        ref={fileInputRef}
                        accept="image/png, image/jpeg, image/jpg, image/heic, image/heif"
                    />
                </div>
            </Card>
        </div>
    );
};
