import { cn } from 'lib/utils';
import { IconsId } from 'types/icons';

type IconProps = {
    name: IconsId;
    className?: string;
    refProp?: any;
    onClick?: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
};

export const Icon: React.FC<IconProps> = ({ name, className, onClick, refProp = null }) => (
    <i ref={refProp} onClick={onClick} className={cn(`icon-${name}`, className)} />
);
