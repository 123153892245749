import { cn } from 'lib/utils';
import ReactSelect, { GroupBase, Props } from 'react-select';
import AsyncSelect, { AsyncProps } from 'react-select/async';
import CreatableSelect, { CreatableProps } from 'react-select/creatable';

import styles from './Select.module.scss';

type SelectProps<Option, IsMulti extends boolean = false, Group extends GroupBase<Option> = GroupBase<Option>> = Props<
    Option,
    IsMulti,
    Group
> & {
    error?: string | boolean;
};

const customStyles = {
    option: (styles: any, { isSelected, isDisabled }: any) => {
        return {
            ...styles,
            backgroundColor: isSelected ? '#fff1f2' : 'white',
            color: '#3F3F46',
            opacity: isDisabled && 0.5,
        };
    },
};

export const Select = <Option, IsMulti extends boolean = false, Group extends GroupBase<Option> = GroupBase<Option>>(
    props: SelectProps<Option, IsMulti, Group>,
) => {
    const { error, className, ...rest } = props;

    return (
        <div className="relative">
            <ReactSelect
                menuPlacement="auto"
                className={cn(
                    styles.select,
                    error && `${styles.error} ring-orange-700 ring-offset-2 ring-2`,
                    className,
                )}
                classNamePrefix="react-select"
                classNames={{
                    indicatorSeparator: state => 'hidden',
                }}
                styles={customStyles}
                {...rest}
            />
        </div>
    );
};

export const SelectCreatable = <
    Option,
    IsMulti extends boolean = false,
    Group extends GroupBase<Option> = GroupBase<Option>,
>(
    props: CreatableProps<Option, IsMulti, Group> & { error?: string | boolean },
) => {
    const { error, className, ...rest } = props;

    return (
        <div className="relative">
            <CreatableSelect
                menuPlacement="auto"
                className={cn(styles.select, error && styles.error, className)}
                classNamePrefix="react-select"
                classNames={{
                    multiValueLabel: state => 'bg-rose-50',
                    multiValueRemove: state => 'bg-rose-50',
                }}
                styles={customStyles}
                {...rest}
            />
        </div>
    );
};

export const SelectAsync = <
    Option,
    IsMulti extends boolean = false,
    Group extends GroupBase<Option> = GroupBase<Option>,
>(
    props: AsyncProps<Option, IsMulti, Group> & { error?: string | boolean },
) => {
    const { error, className, ...rest } = props;

    return (
        <div className="relative">
            <AsyncSelect
                menuPlacement="auto"
                className={cn(styles.select, error && styles.error, className)}
                classNamePrefix="react-select"
                classNames={{
                    multiValueLabel: state => 'bg-rose-50',
                    multiValueRemove: state => 'bg-rose-50',
                }}
                styles={customStyles}
                {...rest}
            />
        </div>
    );
};
