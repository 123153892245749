import { Button } from 'components/Button/Button';
import { Icon } from 'components/Icon/Icon';
import { cn } from 'lib/utils';
import { useTranslation } from 'react-i18next';

import styles from './SaveInfoBanner.module.scss';

export const SaveInfoBanner = ({ onDiscard, onSave }: any) => {
    const { t } = useTranslation('translation', { keyPrefix: 'saveInfoBanner' });
    return (
        <div
            className={cn(
                styles.saveInfoBanner,
                'p-6 bg-zinc-100 w-[500px] fixed top-10 z-10 m-auto left-0 right-0 flex justify-between',
            )}
        >
            <div className="flex items-center gap-2 text-zinc-700 font-semibold">
                <Icon name="alert" className="h-5" />
                <div>{t('text')}</div>
            </div>
            <div className="flex gap-2">
                <Button variant="outline" onClick={onDiscard}>
                    {t('discardButton')}
                </Button>
                <Button onClick={onSave}>{t('saveButton')}</Button>
            </div>
        </div>
    );
};
