import { useMutation } from '@apollo/client';
import { Button } from 'components/Button/Button';
import { Icon } from 'components/Icon/Icon';
import { Input } from 'components/Input/Input';
import { useToast } from 'components/Toast/use-toast';
import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { EVENT_DOCUMENT_DELETE } from 'services/events/mutation';
import { useEventStore } from 'stores/event.store';

export const EventDocuments = () => {
    const { event, documents, setDocuments, getEvent } = useEventStore(state => state);
    const [documentDelete] = useMutation(EVENT_DOCUMENT_DELETE);
    const fileInputRef = useRef<HTMLInputElement>(null);
    const { t } = useTranslation('events');
    const { toast } = useToast();

    useEffect(() => {
        return () => setDocuments([]);
    }, [setDocuments]);

    const handleChange = (files: any) => {
        const list = [];
        for (const f of files) list.push({ document: f, name: f.name.split('.')[0] });
        setDocuments([...documents, ...list]);
    };

    const updateDocument = (doc: any, e: any) => {
        const d = documents.findIndex((d: any) => d.document === doc.document);
        if (d !== -1) {
            const list = documents;
            list[d].name = e;
            setDocuments([...list]);
        }
    };

    const removeDocFromQueue = (doc: any) => {
        const list = documents.filter((i: any) => i.document !== doc.document);
        setDocuments([...list]);
        if (fileInputRef.current) fileInputRef.current.value = '';
    };

    const deleteDocument = (id: string) => {
        if (!id) return;
        documentDelete({ variables: { document: id } }).then(resp => {
            if (resp.data?.eventDocumentDelete.success) {
                toast({
                    title: t('docDeleteSuccess'),
                    variant: 'success',
                });
                getEvent(event.id);
            } else {
                toast({
                    title: t('createFailed'),
                    description: t('createFailedDescription'),
                    variant: 'warning',
                });
            }
        });
    };

    return (
        <div>
            <div className="text-zinc-700 mb-2">{t('documentsLabel')}</div>
            <div className="flex flex-col gap-4 w-[50%]">
                {event?.documents?.map((document: any) => (
                    <DocumentItem key={document.id} document={document} onDelete={() => deleteDocument(document.id)} />
                ))}
                {documents.map((document: any, i: number) => (
                    <DocumentPreview
                        key={i}
                        document={document}
                        onChange={(e: any) => updateDocument(document, e.target.value)}
                        onDelete={() => removeDocFromQueue(document)}
                    />
                ))}
            </div>
            <input
                multiple
                type="file"
                hidden
                onChange={ev => handleChange(ev.target.files)}
                ref={fileInputRef}
                accept="application/pdf"
            />
            <div className="mt-4">
                <Button variant="outline" onClick={() => fileInputRef.current?.click()} type="button">
                    {t('addDocumentsButton')}
                </Button>
            </div>
        </div>
    );
};

const DocumentItem = ({ document, onDelete }: any) => {
    const getFilename = () => {
        const a = document.document.url.split('/');
        return a[a.length - 1];
    };

    if (!document) return null;
    return (
        <div className="flex gap-4 h-[40px]">
            <div className="py-[10px] px-3 bg-rose-50 border border-rose-200 font-medium flex justify-between gap-2">
                <div className="flex gap-2">
                    <Icon name="file" />
                    <div className="text-sm max-w-[120px] overflow-hidden truncate">{getFilename()}</div>
                </div>
                <div onClick={onDelete}>
                    <Icon name="close" className="text-zinc-500 hover:cursor-pointer" />
                </div>{' '}
            </div>
            <Input defaultValue={document.name} readOnly />
        </div>
    );
};

const DocumentPreview = ({ document, onChange, onDelete }: any) => {
    if (!document) return null;
    return (
        <div className="flex gap-4 h-[40px]">
            <div className="py-[10px] px-3 bg-rose-50 border border-rose-200 font-medium flex justify-between gap-2">
                <div className="flex gap-2">
                    <Icon name="file" />
                    <div className="text-sm w-[120px] overflow-hidden truncate">{document.document.name}</div>
                </div>
                <div onClick={onDelete}>
                    <Icon name="close" className="text-zinc-500 hover:cursor-pointer" />
                </div>
            </div>
            <Input defaultValue={document.name} onChange={onChange} />
        </div>
    );
};
