import { Icon } from 'components/Icon/Icon';
import { IconsId } from 'types/icons';

export const InfoItem = ({ icon, children }: { icon: IconsId; children: any }) => {
    return (
        <div className="flex gap-2 items-center">
            <Icon name={icon} className="h-5 text-zinc-700" />
            <div>{children}</div>
        </div>
    );
};
