import { Icon } from 'components/Icon/Icon';
import { BlockedLabel } from 'components/Labels/BlockedLabel';
import { PageTitle } from 'components/PageTitle/PageTitle';
import { useNavigate } from 'react-router-dom';
import { useStaffStore } from 'stores/staff.store';

export const StaffHeader = ({ children }: any) => {
    const member = useStaffStore(state => state.member);
    const navigate = useNavigate();
    return (
        <PageTitle className="pt-8 flex items-center flex-1">
            <div className="flex flex-1 h-10">
                <div className="flex-1 flex items-center">
                    <Icon name="back" className="h-6 text-2xl mr-2 hover:cursor-pointer" onClick={() => navigate(-1)} />
                    <div>
                        {member?.firstName} {member?.lastName}
                    </div>
                    <BlockedLabel status={member?.status} className="ml-2" />
                </div>
                <div>{children}</div>
            </div>
        </PageTitle>
    );
};
