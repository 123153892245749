import { useQuery } from '@apollo/client';
import { Avatars } from 'components/Avatars/Avatars';
import { Card, CardTitle } from 'components/Card/Card';
import { Icon } from 'components/Icon/Icon';
import { cn, normalizeArray } from 'lib/utils';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { USERS } from 'services/users/query';

import { DraftMembersModal } from './DraftMembersModal';

export const DraftMembers = () => {
    const [isOpen, setIsOpen] = useState(false);
    const { t } = useTranslation('dashboard');
    const { data } = useQuery(USERS, {
        variables: {
            status: ['DRAFT'],
            first: 100,
            isMember: true,
        },
    });

    return (
        <>
            <div onClick={() => setIsOpen(true)}>
                <Card
                    className={cn(
                        'transition-colors border',
                        data?.users?.totalCount && 'hover:bg-rose-50 hover:border-rose-200 hover:cursor-pointer ',
                    )}
                >
                    <div className="flex justify-between">
                        <CardTitle className="text-base">{t('draftTitle')}</CardTitle>
                        <Icon name="user-draft" className="text-2xl" />
                    </div>
                    <div className="text-2xl font-semibold">{data?.users?.totalCount || 0}</div>
                    <div className="flex justify-end mt-4 h-[40px]">
                        <Avatars members={normalizeArray(data?.users)} total={data?.users?.totalCount} />
                    </div>
                </Card>
            </div>

            <DraftMembersModal isOpen={isOpen} onClose={() => setIsOpen(false)} members={normalizeArray(data?.users)} />
        </>
    );
};
