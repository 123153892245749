import { getCompany } from 'services/companies';
import { create } from 'zustand';

interface CompanyStore {
    company: any;
    loading: boolean;
    getCompany: (id: string) => void;
    setCompany: (comp: any) => void;
}

export const useCompanyStore = create<CompanyStore>((set, get) => ({
    company: null,
    loading: false,
    getCompany: async (id: string) => {
        if (!get().company) {
            set({ loading: true });
        }
        const { data } = await getCompany(id);
        if (data.company) {
            set({ company: data.company });
        }
        set({ loading: false });
    },
    setCompany: (comp: any) => {
        set({ company: comp });
    },
}));
