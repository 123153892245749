import { cn } from 'lib/utils';
import * as React from 'react';

export interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
    small?: boolean;
    error?: string | boolean;
}

const errorClasses = 'ring-orange-700 ring-offset-2 ring-2 bg-orange-50';

const Input = React.forwardRef<HTMLInputElement, InputProps>(
    ({ className, type, small, error, readOnly, ...props }, ref) => {
        return (
            <input
                type={type}
                className={cn(
                    'flex h-10 w-full border border-input bg-background px-3 py-2 ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-rose-300 focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50',
                    small && 'h-9 text-sm ',
                    error && errorClasses,
                    readOnly && 'bg-transparent border-transparent pl-0 pointer-events-none',
                    className,
                )}
                ref={ref}
                {...props}
            />
        );
    },
);
Input.displayName = 'Input';

export { Input };
