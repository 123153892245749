import { ApolloClient, ApolloLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import createUploadLink from 'apollo-upload-client/createUploadLink.mjs';
import Cookies from 'js-cookie';

import { expiredTokenError } from './refreshTokenError';
const httpLink = createUploadLink({
    uri: process.env.REACT_APP_API_URL,
});
// const httpLink = createHttpLink({
//     uri: process.env.REACT_APP_API_URL,
// });

const authLink = setContext((_, { headers }) => {
    // get the authentication token from local storage if it exists
    // const token = localStorage.getItem('token');
    const token = Cookies.get('token');
    // return the headers to the context so httpLink can read them
    return {
        headers: {
            ...headers,
            authorization: token ? `JWT ${token}` : '',
        },
    };
});

export const client = new ApolloClient({
    // link: authLink.concat(httpLink),
    link: ApolloLink.from([expiredTokenError, authLink, httpLink]),
    cache: new InMemoryCache(),
    defaultOptions: {
        mutate: {
            errorPolicy: 'all',
        },
        query: {
            fetchPolicy: 'no-cache',
            errorPolicy: 'all',
        },
    },
});
