import { Badge } from 'components/Badge/Badge';
import { useTranslation } from 'react-i18next';

export const FullEvent = ({ event }: any) => {
    const { t } = useTranslation('events');
    if (event.capacity === event.confirmed?.totalCount) {
        return <Badge className="hover:bg-zinc-700 h-[20px]">{t('full')}</Badge>;
    }
    return null;
};
