import { cn } from 'lib/utils';

function TypographyH1({ children }: any) {
    return <h1 className="scroll-m-20 text-4xl font-extrabold tracking-tight lg:text-5xl">{children}</h1>;
}

function TypographyH2({ children, className }: any) {
    return <h2 className={cn('scroll-m-20 text-3xl font-semibold tracking-tight', className)}>{children}</h2>;
}

function TypographyH3({ children }: any) {
    return <h3 className="scroll-m-20 text-2xl font-semibold tracking-tight">{children}</h3>;
}

export const Typography = {
    H1: TypographyH1,
    H2: TypographyH2,
    H3: TypographyH3,
};
