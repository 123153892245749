import { Card, CardSubtitle, CardTitle } from 'components/Card/Card';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useEventStore } from 'stores/event.store';

import { EventDocuments } from './EventDocuments';
import { EventImages } from './EventImages';

export const EventFilesForm = () => {
    const [canEdit, setCanEdit] = useState(true);
    const { event } = useEventStore(state => state);
    const { t } = useTranslation('events');

    useEffect(() => {
        if (event) {
            if (event.status === 'CONCLUDED' || event.status === 'CANCELED') {
                setCanEdit(false);
            }
        }
    }, [event]);

    return (
        <Card>
            <CardTitle>{t('filesTitle')}</CardTitle>
            <CardSubtitle>{t('filesSubtitle')}</CardSubtitle>

            <div className="flex flex-col gap-6">
                <EventImages canEdit={canEdit} />
                <EventDocuments />
            </div>
        </Card>
    );
};
