import { Icon } from 'components/Icon/Icon';
import { BlockedLabel } from 'components/Labels/BlockedLabel';
import { SuspendedLabel } from 'components/Labels/SuspendedLabel';
import { Loader } from 'components/Loader/Loader';
import { cn } from 'lib/utils';
import { useState } from 'react';

export const Avatar = ({ image, size = 180, status, className }: any) => {
    return (
        <div
            className={cn(`bg-zinc-300 w-[180px] h-[180px] flex justify-center items-center relative`, className)}
            style={{ width: `${size}px`, height: `${size}px` }}
        >
            {image ? (
                <UserImage
                    image={image}
                    size={size}
                    className={cn(status === 'BLOCKED' && 'opacity-50', status === 'SUSPENDED' && 'opacity-50')}
                />
            ) : (
                <Icon
                    name="user"
                    className={cn(
                        'text-8xl text-zinc-50',
                        size > 32 && 'text-4xl',
                        size <= 32 && 'text-base',
                        size > 150 && 'text-8xl',
                    )}
                />
            )}

            <SuspendedLabel status={status} />
            <div className="absolute bottom-2 right-2">
                <BlockedLabel status={status} />
            </div>
        </div>
    );
};

const UserImage = ({ image, size = 180, className }: any) => {
    const [loading, setLoading] = useState(true);

    return (
        <>
            {loading && size > 32 && (
                <div
                    className={cn(
                        'absolute bg-zinc-300 w-[180px] h-[180px] flex justify-center items-center bg-opacity-50',
                        size && `w-[${size}px] h-[${size}px]`,
                    )}
                >
                    <Loader />
                </div>
            )}
            <img
                src={`${image.url}?fit=facearea&facepad=3.5&w=${size}&h=${size}`}
                alt={image.alt}
                onLoad={() => setLoading(false)}
                className={className}
            />
        </>
    );
};
