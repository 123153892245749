import { Button } from 'components/Button/Button';
import { ContactsModal } from 'components/ContactsModal/ContactsModal';
import { useState } from 'react';

export const AddNewContact = ({ types, member, company }: any) => {
    const [isOpen, setIsOpen] = useState(false);
    return (
        <>
            <Button onClick={() => setIsOpen(true)}>+ Add new contact</Button>
            <ContactsModal
                isOpen={isOpen}
                onClose={() => setIsOpen(false)}
                types={types}
                member={member}
                company={company}
            />
        </>
    );
};
