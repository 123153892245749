import { Button } from 'components/Button/Button';

type EmptyStateProps = {
    image?: string;
    title: string;
    text: string;
    button?: string;
    onClick?: () => void;
    children?: React.ReactNode;
};
export const EmptyState: React.FC<EmptyStateProps> = ({ image, title, text, button, onClick, children }) => {
    return (
        <div className="flex flex-1 flex-col items-center gap-6 justify-center pt-20">
            {image && <img src={image} alt="No contacts" />}
            <div className="text-zinc-700 w-[400px] text-center flex flex-col gap-2">
                <div className="font-semibold text-3xl">{title}</div>
                <div>{text}</div>
            </div>
            {button && (
                <div>
                    <Button onClick={onClick}>{button}</Button>
                </div>
            )}
            <div>{children}</div>
        </div>
    );
};
