import { Icon } from 'components/Icon/Icon';
import { useTranslation } from 'react-i18next';
import { IconsId } from 'types/icons';

export const PreferenceBox = ({ icon, title, list }: { icon: IconsId; title: string; list?: any[] }) => {
    const { t } = useTranslation('member');
    return (
        <div className="flex-1 border border-rose-100 p-6 bg-white flex flex-col gap-5">
            <div className="text-zinc-400 font-bold text-sm flex gap-2 items-center">
                <Icon name={icon} className="h-5 text-base text-zinc-700" /> {title}
            </div>
            <div className="bg-rose-100 h-px"></div>
            {
                <div className="flex gap-2 flex-wrap">
                    {list &&
                        list.length <= 3 &&
                        list.map(item => (
                            <div key={item.id} className="bg-rose-100 text-zinc-700 py-1 px-2">
                                {item.name}
                            </div>
                        ))}
                    {list && list.length > 3 && (
                        <>
                            {list.slice(0, 2).map(item => (
                                <div key={item.id} className="bg-rose-100 text-zinc-700 py-1 px-2">
                                    {item.name}
                                </div>
                            ))}
                            <div className="bg-rose-100 text-zinc-700 py-1 px-2">
                                + {list.length - 2} {t('preferences.others')}
                            </div>
                        </>
                    )}
                    {list && list.length === 0 && '-'}
                </div>
            }
        </div>
    );
};
