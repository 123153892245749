import { gql } from '@apollo/client';

export const ADD_MEMBER = gql`
    mutation addMember($input: MemberCreateInput!) {
        memberCreate(input: $input) {
            user {
                id
                firstName
                lastName
                username
                email
            }
        }
    }
`;

export const UPDATE_MEMBER = gql`
    mutation updateMember($input: MemberUpdateInput!, $user: ID!) {
        memberUpdate(input: $input, user: $user) {
            user {
                id
                firstName
                lastName
                username
                email
            }
        }
    }
`;

export const ADD_FOOD = gql`
    mutation addFood($name: String!) {
        userPreferenceCreate(name: $name) {
            preference {
                id
                name
            }
        }
    }
`;

export const ADD_FOOD_RESTRICTION = gql`
    mutation addFoodRestriction($name: String!) {
        userDietRestrictionCreate(name: $name) {
            diet {
                id
                name
            }
        }
    }
`;

export const ADD_FOOD_ALLERGY = gql`
    mutation addFoodAllergy($name: String!) {
        userAllergyCreate(name: $name) {
            allergy {
                id
                name
            }
        }
    }
`;

export const ADD_CHILD = gql`
    mutation addChild($input: ChildInput!, $user: ID!) {
        memberChildCreate(input: $input, user: $user) {
            child {
                id
                firstName
                lastName
                dateOfBirth
                gender
            }
        }
    }
`;

export const EDIT_CHILD = gql`
    mutation memberChildUpdate($input: ChildUpdateInput!, $child: ID!) {
        memberChildUpdate(input: $input, child: $child) {
            child {
                id
            }
        }
    }
`;

export const DELETE_CHILD = gql`
    mutation memberChildDelete($child: ID!) {
        memberChildDelete(child: $child) {
            success
        }
    }
`;

export const ADD_PET = gql`
    mutation addPet($input: PetInput!, $user: ID!) {
        memberPetCreate(input: $input, user: $user) {
            pet {
                id
                name
                type
                breed
            }
        }
    }
`;

export const EDIT_PET = gql`
    mutation memberPetUpdate($input: PetUpdateInput!, $pet: ID!) {
        memberPetUpdate(input: $input, pet: $pet) {
            pet {
                id
            }
        }
    }
`;

export const DELETE_PET = gql`
    mutation memberPetDelete($pet: ID!) {
        memberPetDelete(pet: $pet) {
            success
        }
    }
`;

export const BLOCK_MEMBER = gql`
    mutation blockUser($action: BlockUnblockEnum!, $user: ID!) {
        userBlock(action: $action, user: $user) {
            status
        }
    }
`;

export const RESET_PASSWORD = gql`
    mutation resetPassword($email: String!) {
        resetPassword(email: $email) {
            success
        }
    }
`;

export const CONFIRM_RESET_PASSWORD = gql`
    mutation confirmResetPassword($token: String!, $password: String!, $uuid: UUID!) {
        resetPasswordConfirm(token: $token, password: $password, uuid: $uuid) {
            success
        }
    }
`;

export const USER_DELETE = gql`
    mutation userDelete($user: ID!) {
        userDelete(user: $user) {
            success
        }
    }
`;

export const PARTNER_CREATE = gql`
    mutation partnerCreate($input: PartnerInput!, $user: ID!) {
        partnerCreate(input: $input, user: $user) {
            partner {
                id
                firstName
            }
        }
    }
`;

export const PARTNER_UPDATE = gql`
    mutation partnerUpdate($input: PartnerUpdateInput!, $partner: ID!) {
        partnerUpdate(input: $input, partner: $partner) {
            partner {
                id
                firstName
            }
        }
    }
`;

export const EXPORT_BIBLE = gql`
    mutation exportBible {
        exportMemberBible {
            file
        }
    }
`;

export const EXPORT_CONSUMPTIONS = gql`
    mutation exportConsumptions($transactions: [ID]!) {
        exportConsumptions(transactions: $transactions) {
            file
        }
    }
`;

export const STAFF_CREATE = gql`
    mutation staffCreate($input: StaffInput!) {
        staffCreate(input: $input) {
            user {
                id
                isStaff
            }
        }
    }
`;

export const STAFF_UPDATE = gql`
    mutation staffUpdate($input: StaffUpdateInput!, $user: ID!) {
        staffUpdate(input: $input, user: $user) {
            user {
                id
                isStaff
            }
        }
    }
`;

export const MEMBER_PUBLISH_UNPUBLISH = gql`
    mutation publishUnpublish($action: UserPublishEnum!, $user: ID!) {
        userPublish(action: $action, user: $user) {
            status
        }
    }
`;

export const MEMBER_SUSPEND = gql`
    mutation memberSuspend($member: ID!, $from: Date!, $until: Date!) {
        memberSuspend(member: $member, suspendedFrom: $from, suspendedUntil: $until) {
            status
        }
    }
`;

export const MEMBER_UNSUSPEND = gql`
    mutation memberUnsuspend($member: ID!) {
        memberUnsuspend(member: $member) {
            status
        }
    }
`;

export const MEMBER_TERMINATE = gql`
    mutation memberTerminate($member: ID!) {
        memberTerminate(member: $member) {
            user {
                id
            }
        }
    }
`;

export const EMAIL_NEGATIVE_BALANCES = gql`
    mutation sendEmailNegativeBalance($members: [ID]!) {
        sendEmailNegativeBalance(members: $members) {
            success
        }
    }
`;

export const EMAIL_EXPIRING_MEMBERSHIP = gql`
    mutation sendEmailExpiringMembership($members: [ID]!) {
        sendEmailExpiringMembership(members: $members) {
            success
        }
    }
`;

export const INVOICE_MARK_AS_PAID = gql`
    mutation invoiceMarkAsPaid($invoice: ID!) {
        invoiceMarkAsPaid(invoice: $invoice) {
            status
        }
    }
`;

export const INVOICE_SEND_REMINDER = gql`
    mutation invoiceSendReminder($invoice: ID!) {
        invoiceSendReminder(invoice: $invoice) {
            success
        }
    }
`;

export const MANUAL_BALANCE_EDIT = gql`
    mutation manualBalanceEdit($input: ManualBalanceEntryInput!) {
        manualBalanceEntryCreate(input: $input) {
            balanceEntry {
                id
                amount
                note
            }
        }
    }
`;

export const TOPUP_INVOICE_SEND = gql`
    mutation sendEmailTopUpBalance($amount: Float!, $member: ID!) {
        sendEmailTopUpBalance(amount: $amount, member: $member) {
            success
        }
    }
`;

export const DEDUCT_FROM_REMAINDER = gql`
    mutation transactionDeductFromUpdate($transaction: ID!) {
        transactionDeductFromUpdate(transaction: $transaction) {
            transaction {
                id
            }
        }
    }
`;
