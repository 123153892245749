import clsx from 'clsx';
import { forwardRef } from 'react';

import { Icon } from '../Icon/Icon';

import styles from './Checkbox.module.scss';

interface Props extends React.HTMLAttributes<HTMLInputElement> {
    label?: string;
    checked?: boolean;
    disabled?: boolean;
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
    children?: string | React.ReactNode;
}
/**
 * Checkbox component
 *
 * @param {string} [id] - The id of the checkbox
 * @param {string} [label] - The label of the checkbox
 * @param {boolean} [checked] - Whether the checkbox is checked
 * @param {boolean} [disabled] - Whether the checkbox is disabled
 * @param {Function} [onChange] - The function to call when the checkbox is changed
 * @param {ReactNode} [children] - The children of the checkbox
 * @param {React.HTMLAttributes<HTMLInputElement>} props - Other props passed to the checkbox
 */
const Checkbox = forwardRef<HTMLInputElement, Props>(
    ({ id, label, checked = false, className, onChange, disabled, children, ...props }, ref) => (
        <div className={clsx(styles.checkbox, className)}>
            <div className={styles.wrapper}>
                <input
                    ref={ref}
                    id={id}
                    checked={checked}
                    className={clsx(checked && styles.checked)}
                    onChange={onChange}
                    {...props}
                    type="checkbox"
                    disabled={disabled}
                />
                {checked && <Icon name="check" />}
            </div>

            {label && <label htmlFor={id}>{label}</label>}
            {children && <div>{children}</div>}
        </div>
    ),
);

export default Checkbox;
