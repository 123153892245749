import { Icon } from 'components/Icon/Icon';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from 'components/Tooltip/Tooltip';
import { useTranslation } from 'react-i18next';

export const ShareholderBadge = () => {
    const { t } = useTranslation('translation');
    return (
        <TooltipProvider>
            <Tooltip delayDuration={0}>
                <TooltipTrigger>
                    <Icon name="shareholder" className="text-2xl text-yellow-500 h-6" />
                </TooltipTrigger>
                <TooltipContent sideOffset={-5} align="start">
                    {t('tooltips.shareholder')}
                </TooltipContent>
            </Tooltip>
        </TooltipProvider>
    );
};
