import { useMutation } from '@apollo/client';
import { Breadcrumbs } from 'components/Breadcrumbs/Breadcrumbs';
import { Button } from 'components/Button/Button';
import { CompanyHeader } from 'components/CompanyHeader/CompanyHeader';
import { CompanyTabs } from 'components/CompanyTabs/CompanyTabs';
import { useToast } from 'components/Toast/use-toast';
import { CompanyInfo } from 'pages/NewCompany/_components/CompanyInfo/CompanyInfo';
import { CompanyMembers } from 'pages/NewCompany/_components/CompanyMembers/CompanyMembers';
import { CompanyProfile } from 'pages/NewCompany/_components/CompanyProfile/CompanyProfile';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { COMPANY_UPDATE } from 'services/companies/mutation';
import { useCompanyStore } from 'stores/company.store';

export const EditCompany = () => {
    const { company, getCompany } = useCompanyStore(state => state);
    const methods = useForm();
    const [updateCompany] = useMutation(COMPANY_UPDATE);
    const { id } = useParams();
    const { t } = useTranslation('company');
    const { toast } = useToast();

    const onSubmit = (data: any) => {
        const { email, status, __typename, ...rest } = data;
        if (rest.image?.url) delete rest.image;
        updateCompany({ variables: { input: rest, company: id } })
            .then((res: any) => {
                if (res.data.companyUpdate.company) {
                    toast({
                        description: t('updateSuccessMessage'),
                        variant: 'success',
                    });
                    if (id) getCompany(id);
                }
            })
            .catch(e => {
                toast({
                    title: t('saveErrorTitle'),
                    description: t('saveErrorMessage'),
                    variant: 'warning',
                });
            });
    };

    return (
        <>
            <Breadcrumbs crumbs={['members', 'companies', company?.name]} />
            <CompanyHeader />
            <CompanyTabs />

            <FormProvider {...methods}>
                <form className="flex flex-col gap-6" onSubmit={methods.handleSubmit(onSubmit)}>
                    <div className="flex gap-6">
                        <CompanyProfile />
                        <CompanyInfo />
                    </div>
                    <CompanyMembers />
                    <div className="pb-8 flex justify-end gap-2">
                        <Button>{t('saveCompanyButton')}</Button>
                    </div>
                </form>
            </FormProvider>
        </>
    );
};
