import { Avatar } from 'components/Avatar/Avatar';
import { Card, CardTitle } from 'components/Card/Card';
import { Icon } from 'components/Icon/Icon';
import { InfoItem } from 'components/InfoItem/InfoItem';
import { ViewButton } from 'components/ViewButton/ViewButton';
import dayjs from 'dayjs';
import { cn, normalizeArray } from 'lib/utils';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AppRoutes } from 'Routes';
import { useUserStore } from 'stores/user.store';

import { PreferenceBox } from './PreferenceBox';

export const MemberPreviewPartner = () => {
    const [open, setOpen] = useState(false);
    const user = useUserStore(state => state.user);
    const { t } = useTranslation('member');
    const { t: f } = useTranslation('forms');

    const partnerData = normalizeArray(user?.contacts)?.find((c: any) => c.type === 'PARTNER') || null;

    if (!user || !user.partner) return null;

    return (
        <div>
            <Card className="flex flex-col gap-8">
                <div className="flex justify-between">
                    <CardTitle>{t('partnerTitle')}</CardTitle>
                    <ViewButton to={`${AppRoutes.memberPartner.replace(':id', user.id)}#partner`} />
                </div>
                <div className="border border-rose-100 p-6 bg-white flex gap-6">
                    <div>
                        <Avatar image={user.partner?.image} size={128} />
                    </div>
                    <div className="flex flex-col gap-4 flex-1">
                        <div className="font-bold">
                            {user.partner?.firstName} {user.partner?.lastName}
                        </div>
                        <div className="h-px bg-rose-100"></div>
                        <div className="flex gap-12">
                            <div className="flex flex-col gap-6 text-zinc-500">
                                <InfoItem icon="phone">{partnerData?.phoneNumber || '-'}</InfoItem>
                                <InfoItem icon="mail">{partnerData?.email || '-'}</InfoItem>
                            </div>
                            <div className="flex flex-col gap-6 text-zinc-500">
                                <InfoItem icon="company">{user.partner?.jobTitle}</InfoItem>
                                <InfoItem icon="cake">{dayjs(user.partner?.dateOfBirth).fromNow(true)} old</InfoItem>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="text-sm flex justify-between hover:cursor-pointer" onClick={() => setOpen(!open)}>
                    <div>{t('partnerPreferences')}</div>
                    <Icon name="carret-down" className={cn(open && '-scale-100')} />
                </div>
            </Card>
            <div className={cn('overflow-hidden max-h-0 transition-all duration-300', open && 'max-h-[400px]')}>
                <Card className="flex flex-col gap-6 mt-2">
                    <div className="flex gap-6">
                        <PreferenceBox icon="heart" title={f('likes')} list={normalizeArray(user.partner?.likes)} />
                        <PreferenceBox
                            icon="dislike"
                            title={f('dislikes')}
                            list={normalizeArray(user.partner?.dislikes)}
                        />
                    </div>
                    <div className="flex gap-6">
                        <PreferenceBox
                            icon="salad"
                            title={f('dietRestrictions')}
                            list={normalizeArray(user.partner?.dietRestrictions)}
                        />
                        <PreferenceBox
                            icon="wheat"
                            title={f('allergies')}
                            list={normalizeArray(user.partner?.allergies)}
                        />
                    </div>
                </Card>
            </div>
        </div>
    );
};
