import { Button } from 'components/Button/Button';
import { Icon } from 'components/Icon/Icon';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

const PAGE_SIZE = parseInt(localStorage.getItem('pageSize') as any) || 10;

type PaninationProps = {
    pageInfo: any;
    total: number;
};


/**
 * Renders a pagination component.
 *
 * @param {object} pageInfo - The page info object which contains the `hasNextPage` property.
 * @param {number} total - The total number of items.
 *
 * @returns {React.ReactElement} The pagination component.
 *
 * @example
 * <Pagination pageInfo={pageInfo} total={total} />
 */
export const Pagination: React.FC<PaninationProps> = ({ pageInfo, total }) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [hasBackPage, setHasBackPage] = useState(false);

    useEffect(() => {
        const curPage = searchParams.get('page');
        if (curPage && parseInt(curPage) > 1) {
            setHasBackPage(true);
        } else {
            setHasBackPage(false);
        }
    }, [searchParams]);
    const pageChange = (direction: string) => {
        const params = new URLSearchParams(searchParams);
        const curPage = params.get('page') || '1';
        if (direction === 'next') {
            params.set('page', (parseInt(curPage) + 1).toString());
        } else {
            params.set('page', (parseInt(curPage) - 1).toString());
        }
        setSearchParams(params);
    };

    const goToFirstPage = () => {
        const params = new URLSearchParams(searchParams);
        params.set('page', '1');
        setSearchParams(params);
    };

    const goToLastPage = () => {
        const params = new URLSearchParams(searchParams);
        params.set('page', Math.ceil(total / PAGE_SIZE).toString());
        setSearchParams(params);
    };

    if (!pageInfo?.hasNextPage && !hasBackPage) return null;

    return (
        <div className="flex">
            <div className="flex-1"></div>
            <div className="flex justify-end  gap-2 my-4">
                <div className="flex gap-6 mr-14 ">
                    <div className="text-sm text-zinc-700 self-center">
                        Showing {PAGE_SIZE} of {total}
                    </div>
                    <div className="w-[1px] h-5 bg-zinc-300 self-center"></div>
                    <div className="text-sm text-zinc-700 self-center">
                        Page {searchParams.get('page') || '1'} of {Math.ceil(total / PAGE_SIZE)}
                    </div>
                </div>

                <Button
                    variant="outline"
                    className="text-zinc-700 text-base w-8 h-8"
                    disabled={!hasBackPage}
                    onClick={() => goToFirstPage()}
                >
                    <Icon name="double-arrow-left" className="h-5" />
                </Button>
                <Button
                    variant={'outline'}
                    onClick={() => pageChange('prev')}
                    disabled={!hasBackPage}
                    className="text-zinc-700 text-base w-8 h-8"
                >
                    <Icon name="left-arrow" className="h-5" />
                </Button>
                <Button
                    variant={'outline'}
                    onClick={() => pageChange('next')}
                    disabled={!pageInfo?.hasNextPage}
                    className="text-zinc-700 text-base w-8 h-8"
                >
                    <Icon name="right-arrow" className="h-5" />
                </Button>
                <Button
                    variant="outline"
                    className="text-zinc-700 text-base w-8 h-8"
                    disabled={!pageInfo?.hasNextPage}
                    onClick={() => goToLastPage()}
                >
                    <Icon name="double-arrow-right" className="h-5" />
                </Button>
            </div>
        </div>
    );
};
