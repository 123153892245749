import { Button } from 'components/Button/Button';
import { Modal } from 'components/Modal/Modal';
import { Portal } from 'components/Portal/Portal';
import { useTranslation } from 'react-i18next';

type ConfirmCompanyModalProps = {
    isOpen: boolean;
    onClose: () => void;
    onConfirm: () => void;
};
export const ConfirmCompanyModal: React.FC<ConfirmCompanyModalProps> = ({ isOpen, onClose, onConfirm }) => {
    const { t } = useTranslation('member', { keyPrefix: 'companyMembership' });

    return (
        <Portal>
            <Modal isOpen={isOpen} onClose={onClose} maxWidth={512} uncloseable>
                <div className="flex flex-col gap-4">
                    <div>
                        <div className="text-xl font-semibold text-zinc-700 mb-2">{t('confirmModalTitle')}</div>
                        <div className="text-sm text-zinc-500">{t('confirmModalSubtitle')}</div>
                    </div>
                    <div className="flex justify-end gap-2">
                        <Button variant="outline" onClick={onClose}>
                            {t('cancelButton')}
                        </Button>
                        <Button onClick={onConfirm}>{t('confirmButton')}</Button>
                    </div>
                </div>
            </Modal>
        </Portal>
    );
};
