import { Card, CardSubtitle, CardTitle } from 'components/Card/Card';
import { FormError } from 'components/FormError/FormError';
import { Input } from 'components/Input/Input';
import { Label } from 'components/Label/Label';
import { Textarea } from 'components/Textarea/Textarea';
import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useUserStore } from 'stores/user.store';

import { CompanyMembership } from '../CompanyMembership/CompanyMembership';

// import { CompaniesDropdown } from '../CompaniesDropdown/CompaniesDropdown';

export const CompanyInfo = () => {
    const { user, getUser } = useUserStore(state => state);
    const {
        register,
        setValue,
        formState: { errors },
    } = useFormContext();
    const { t } = useTranslation('member', { keyPrefix: 'companyInfo' });
    const { t: f } = useTranslation('forms');

    // Pre-fill fields if in edit mode
    useEffect(() => {
        if (user) {
            setValue('companyName', user.companyName);
            setValue('jobPosition', user.jobPosition);
            setValue('jobInfo', user.jobInfo);
        }
    }, [user, setValue]);

    const updateUser = () => {
        if (!user?.id) return;
        getUser(user.id);
    };

    return (
        <Card>
            <CardTitle>{t('title')}</CardTitle>
            <CardSubtitle>{t('subtitle')}</CardSubtitle>
            <div className="flex flex-col gap-6">
                <div className="flex gap-6">
                    <div className="flex-1">
                        <Label>{f('company')}</Label>
                        {user?.company ? (
                            <Input defaultValue={user.company?.name} readOnly />
                        ) : (
                            <Input placeholder={f('company')} {...register('companyName')} />
                        )}
                    </div>
                    <div className="flex-1">
                        <Label>{f('jobPosition')}</Label>
                        <Input
                            placeholder={f('jobPosition')}
                            {...register('jobPosition', { required: 'Enter job position' })}
                            error={!!errors.jobPosition}
                        />
                        <FormError error={errors.jobPosition} />
                    </div>
                </div>
                <div>
                    <Label>{f('jobDescription')}</Label>
                    <Textarea placeholder={f('jobDescription')} {...register('jobInfo')} />
                </div>
            </div>
            {user && (
                <div className="mt-8">
                    <CompanyMembership user={user} onUpdate={updateUser} />
                </div>
            )}
        </Card>
    );
};
