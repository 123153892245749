import { useMutation, useQuery } from '@apollo/client';
import { Avatars } from 'components/Avatars/Avatars';
import { Card, CardTitle } from 'components/Card/Card';
import { Icon } from 'components/Icon/Icon';
import { useToast } from 'components/Toast/use-toast';
import { cn, normalizeArray } from 'lib/utils';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TOPUP_INVOICE_SEND } from 'services/users/mutation';
import { USERS } from 'services/users/query';
import { UserType } from 'types/user';

import { NegativeBalancesModal } from './NegativeBalancesModal';
import { TopupConfirmModal } from './TopupConfirmModal';
import { TopupInvoiceModal } from './TopupInvoiceModal';

export const NegativeBalances = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [topupOpen, setTopupOpen] = useState(false);
    const [confirmModal, setConfirmModal] = useState(false);
    const [topupMember, setTopupMember] = useState<UserType | null>(null);
    const [topupAmount, setTopupAmount] = useState(0);
    const { t } = useTranslation('dashboard');
    const { toast } = useToast();
    const { data, refetch } = useQuery(USERS, {
        variables: {
            hasNegativeBalance: true,
            first: 10,
            isMember: true,
        },
    });
    const [sendTopupInvoiceMutation, { loading }] = useMutation(TOPUP_INVOICE_SEND);

    const generateTopup = (member: UserType) => {
        setTopupMember(member);
        setTopupOpen(true);
        setIsOpen(false);
    };

    const closeTopupModal = () => {
        setTopupOpen(false);
        setTopupMember(null);
        setTopupAmount(0);
    };

    const sendInvoice = (amount: number) => {
        setTopupAmount(amount);
        setTopupOpen(false);
        setConfirmModal(true);
    };

    const cancelTopup = () => {
        setTopupMember(null);
        setConfirmModal(false);
    };

    const confirmTopup = async () => {
        const resp = await sendTopupInvoiceMutation({
            variables: {
                member: topupMember?.id,
                amount: Number(topupAmount),
            },
        });
        if (resp?.data?.sendEmailTopUpBalance?.success) {
            toast({
                title: t('topupSuccess'),
                variant: 'success',
            });
            cancelTopup();
            refetch();
        } else {
            toast({
                title: t('failToastTitle'),
                description: t('failToastSubtitle'),
                variant: 'warning',
            });
        }
    };

    return (
        <>
            <div onClick={() => setIsOpen(true)}>
                <Card
                    className={cn(
                        'transition-colors border',
                        data?.users?.totalCount && 'hover:bg-rose-50 hover:border-rose-200 hover:cursor-pointer ',
                    )}
                >
                    <div className="flex justify-between">
                        <CardTitle className="text-base">{t('negativeBalancesTitle')}</CardTitle>
                        <Icon name="user-negative" className="text-2xl" />
                    </div>
                    <div className="text-2xl font-semibold">{data?.users?.totalCount || 0}</div>
                    <div className="flex justify-end mt-4 h-[40px]">
                        <Avatars members={normalizeArray(data?.users)} />
                    </div>
                </Card>
            </div>
            <NegativeBalancesModal
                isOpen={isOpen}
                onClose={() => setIsOpen(false)}
                members={normalizeArray(data?.users)}
                onTopup={(member: any) => generateTopup(member)}
            />
            <TopupInvoiceModal
                member={topupMember}
                isOpen={topupOpen}
                onClose={closeTopupModal}
                onSendInvoice={sendInvoice}
            />
            <TopupConfirmModal
                isOpen={confirmModal}
                onClose={cancelTopup}
                onConfirm={confirmTopup}
                member={topupMember}
                amount={topupAmount}
                loading={loading}
            />
        </>
    );
};
