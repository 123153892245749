import { useMutation } from '@apollo/client';
import { Button } from 'components/Button/Button';
import { Input } from 'components/Input/Input';
import { Label } from 'components/Label/Label';
import { useToast } from 'components/Toast/use-toast';
import { Typography } from 'components/Typograhpy/Typography';
import { EMAIL_REGEX } from 'lib/utils';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { AppRoutes } from 'Routes';
import { RESET_PASSWORD } from 'services/users/mutation';

export const ForgotPassword = () => {
    const { t } = useTranslation('auth');
    const [resetPassword] = useMutation(RESET_PASSWORD);
    const { toast } = useToast();
    const navigate = useNavigate();
    const {
        register,
        handleSubmit,
        reset,
        formState: { isValid },
    } = useForm();

    const onSubmit = async (data: any) => {
        await resetPassword({ variables: { email: data.email } });
        toast({
            title: t('forgotPassSuccessTitle'),
            description: t('forgotPassSuccessText'),
            variant: 'success',
        });
        reset();
        navigate(AppRoutes.login, { replace: true });
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)} className="w-full max-w-[460px] flex flex-col gap-8">
            <div className="flex flex-col gap-4">
                <Typography.H3 className="text-zinc-700">{t('forgotPasswordTitle')}</Typography.H3>
                <div className="text-sm">{t('forgotPasswordSubtitle')}</div>
            </div>
            <div>
                <div>
                    <Label>{t('email')}</Label>
                    <Input placeholder={t('email')} {...register('email', { required: true, pattern: EMAIL_REGEX })} />
                </div>
            </div>
            <div className="flex justify-end">
                <Button disabled={!isValid}>{t('forgotPasswordButton')}</Button>
            </div>
        </form>
    );
};
