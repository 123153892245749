import { Button } from 'components/Button/Button';
import { Modal } from 'components/Modal/Modal';
import { Portal } from 'components/Portal/Portal';
import { useTranslation } from 'react-i18next';

type ConfirmConsumptionsModalProps = {
    isOpen: boolean;
    onClose: () => void;
    onConfirm: () => void;
    isLoading?: boolean;
};
export const ConfirmConsumptionsModal: React.FC<ConfirmConsumptionsModalProps> = ({
    isOpen,
    onClose,
    onConfirm,
    isLoading = false,
}) => {
    const { t } = useTranslation('documents');

    return (
        <Portal>
            <Modal isOpen={isOpen} onClose={onClose} maxWidth={512} className="flex flex-col gap-4">
                <div>
                    <div className="text-xl font-semibold text-zinc-700 mb-2">{t('exportConsumptionsTitle')}</div>
                    <div className="text-sm text-zinc-500">{t('exportConsumptionsText')}</div>
                </div>
                <div className="flex justify-end gap-2">
                    <Button variant="outline" onClick={onClose} disabled={isLoading}>
                        {t('closeModalButton')}
                    </Button>
                    <Button onClick={onConfirm} disabled={isLoading}>{t('sureAndExportButton')}</Button>
                </div>
            </Modal>
        </Portal>
    );
};
