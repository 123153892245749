import { Card, CardTitle } from 'components/Card/Card';
import { Icon } from 'components/Icon/Icon';
import { ViewButton } from 'components/ViewButton/ViewButton';
import { normalizeArray } from 'lib/utils';
import { useTranslation } from 'react-i18next';
import { AppRoutes } from 'Routes';
import { useUserStore } from 'stores/user.store';

import { PreferenceBox } from './PreferenceBox';

export const MemberPreviewPreferences = () => {
    const user = useUserStore(state => state.user);
    const { t: f } = useTranslation('forms');
    const { t } = useTranslation('member');

    if (!user) return null;

    return (
        <Card className="flex flex-col gap-8">
            <div className="flex justify-between">
                <CardTitle>{t('preferences.title')}</CardTitle>
                <ViewButton to={`${AppRoutes.memberProfile.replace(':id', user.id)}#preferences`} />
            </div>
            <div className="flex flex-col gap-6">
                <div className="flex gap-6">
                    <PreferenceBox icon="heart" title={f('likes')} list={normalizeArray(user.likes)} />
                    <PreferenceBox icon="dislike" title={f('dislikes')} list={normalizeArray(user.dislikes)} />
                </div>
                <div className="flex gap-6">
                    <PreferenceBox
                        icon="salad"
                        title={f('dietRestrictions')}
                        list={normalizeArray(user.dietRestrictions)}
                    />
                    <PreferenceBox icon="wheat" title={f('allergies')} list={normalizeArray(user.allergies)} />
                </div>

                <div className="flex-1 border border-rose-100 p-6 bg-white flex flex-col gap-5">
                    <div className="text-zinc-400 font-bold text-sm flex gap-2 items-center">
                        <Icon name="message" className="h-5 text-base text-zinc-700" /> {f('comments')}
                    </div>
                    <div className="bg-rose-100 h-px"></div>
                    <div className="flex gap-2 flex-wrap">{user?.preferenceComment || '-'}</div>
                </div>
            </div>
        </Card>
    );
};
