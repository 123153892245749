import { useQuery } from '@apollo/client';
import { Avatar } from 'components/Avatar/Avatar';
import { Button } from 'components/Button/Button';
import Checkbox from 'components/Checkbox/Checkbox';
import { Modal } from 'components/Modal/Modal';
import { Portal } from 'components/Portal/Portal';
import { SearchFilter } from 'components/SearchFilter/SearchFilter';
import { SortFilter } from 'components/SortFilter/SortFilter';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from 'components/Table/Table';
import { normalizeArray } from 'lib/utils';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { USERS } from 'services/users/query';
import { useEventStore } from 'stores/event.store';

const PAGE_SIZE = 100;
const SORT_OPTIONS = [
    { value: 'A_Z', label: 'A-Z' },
    { value: 'Z_A', label: 'Z-A' },
    { value: 'MOST_RECENT', label: 'Most recent' },
    { value: 'LEAST_RECENT', label: 'Least recent' },
];

type EventInviteMembersModalProps = {
    isOpen: boolean;
    onClose: () => void;
    onInvite: (members: string[]) => void;
    onMemberSelect: (id: string) => void;
    selectedMembers: string[];
    inviteAllMembers: () => void;
};
export const EventInviteMembersModal: React.FC<EventInviteMembersModalProps> = ({
    isOpen,
    onClose,
    onInvite,
    onMemberSelect,
    selectedMembers,
    inviteAllMembers,
}) => {
    const { event } = useEventStore(state => state);
    const [searchParams] = useSearchParams();
    const { t } = useTranslation('events');

    const { data, refetch } = useQuery(USERS, { variables: { isMember: true, first: PAGE_SIZE, status: ['ACTIVE'] } });

    useEffect(() => {
        const search = searchParams.get('search');
        const sort = searchParams.get('sort');

        refetch({
            search: search,
            sort: sort,
        });
    }, [searchParams, refetch]);

    return (
        <Portal>
            <Modal isOpen={isOpen} onClose={onClose} maxWidth={800} className="flex flex-col gap-6">
                <div>
                    <div className="text-xl font-semibold text-zinc-700 mb-2">{t('selectMembersTitle')}</div>
                    <div className="text-sm text-zinc-500">{t('selectMembersSubtitle')}</div>
                </div>
                <div>
                    <div className="flex gap-4 mb-4">
                        <SearchFilter placeholder={t('searchPlaceholder')} />
                        <SortFilter options={SORT_OPTIONS} />
                    </div>
                    <div>
                        <Table className="border">
                            <TableHeader>
                                <TableRow>
                                    <TableHead className="flex gap-2 items-center justify-between">
                                        <div className="flex gap-2">{t('nameLabel')}</div>
                                        <div
                                            className="text-rose-700 underline hover:cursor-pointer"
                                            onClick={inviteAllMembers}
                                        >
                                            {t('selectAllMembers')}
                                        </div>
                                    </TableHead>
                                </TableRow>
                            </TableHeader>
                            <TableBody className="hover:cursor-pointer">
                                {data?.users?.edges.length > 0 &&
                                    normalizeArray(data.users).map((member: any) => {
                                        if (
                                            normalizeArray(event?.invited)
                                                ?.map((e: any) => e.id)
                                                .includes(member.id)
                                        ) {
                                            return (
                                                <TableRow
                                                    key={member.id}
                                                    className="text-zinc-700 hover:bg-rose-50 opacity-50"
                                                >
                                                    <TableCell className="flex gap-2 items-center">
                                                        <Checkbox checked onChange={() => {}} />
                                                        <Avatar image={member.image} size={32} />
                                                        {member.firstName} {member.lastName}
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        }
                                        return (
                                            <TableRow key={member.id} className="text-zinc-700 hover:bg-rose-50">
                                                <TableCell
                                                    className="flex gap-2 items-center"
                                                    onClick={() => onMemberSelect(member.id)}
                                                >
                                                    <Checkbox
                                                        onChange={() => onMemberSelect(member.id)}
                                                        checked={selectedMembers.includes(member.id)}
                                                    />
                                                    <Avatar image={member.image} size={32} />
                                                    {member.firstName} {member.lastName}
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                            </TableBody>
                        </Table>
                        {data?.users?.edges?.length === 0 && (
                            <div className={'py-5 text-center text-zinc-700'}>{t('noUsers')}</div>
                        )}
                    </div>
                </div>
                <div className="flex justify-end gap-2">
                    <Button variant="outline" onClick={onClose}>
                        {t('closeButtonLabel')}
                    </Button>
                    <Button disabled={selectedMembers.length === 0} onClick={() => onInvite(selectedMembers)}>
                        {t('inviteMembersModalButton')}
                    </Button>
                </div>
            </Modal>
        </Portal>
    );
};
