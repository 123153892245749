import clsx from 'clsx';
import { Icon } from 'components/Icon/Icon';
import { cn } from 'lib/utils';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import styles from './SortFilter.module.scss';

type SortFilterType = {
    options: any;
};
export const SortFilter: React.FC<SortFilterType> = ({ options }) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [active, setActive] = useState(false);
    const sortRef = useRef<any>(null);
    const { t } = useTranslation('translation', { keyPrefix: 'filters' });

    const handleClickOutside = (event: any) => {
        if (sortRef.current && !sortRef.current.contains(event.target)) {
            setActive(false);
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleClickOutside, true);

        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, []);

    const setSorting = (sort: string) => {
        const params = new URLSearchParams(searchParams);
        params.set('sort', sort);
        setSearchParams(params);
        setActive(false);
    };

    const clearFilter = () => {
        const params = new URLSearchParams(searchParams);
        params.delete('sort');
        setSearchParams(params);
        setActive(false);
    };

    return (
        <div className={clsx(styles.orderFilter, active && styles.active)} ref={sortRef}>
            <div
                className={cn(
                    styles.dashed,
                    'flex items-center gap-3 text-black outline-zinc-300 py-1 px-3 text-sm h-8 hover:cursor-pointer',
                )}
            >
                <div className="flex gap-3 items-center" onClick={() => setActive(!active)}>
                    <Icon name="up-down" />
                    <div>{t('sortFilter')}</div>
                </div>
                {searchParams.get('sort') && (
                    <>
                        <div className={styles.divider}></div>
                        <div className={styles.selectedFilter}>
                            {t(searchParams.get('sort')?.toLowerCase() as string)}
                            <Icon name="close" onClick={() => clearFilter()} />
                        </div>
                    </>
                )}
            </div>
            <div className={styles.dropdown}>
                <div className={styles.dropdownContent}>
                    <div className="pt-4 pb-2">
                        <div className="flex flex-col mb-4 mx-0">
                            {options.map((option: any) => (
                                <div
                                    className={styles.dropdownItem}
                                    onClick={() => setSorting(option.value)}
                                    key={option.value}
                                >
                                    {t(option.value.toLowerCase())} {option.suffix && <span>{option.suffix}</span>}
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="h-px bg-zinc-200"></div>
                    <div
                        className="mt-2 py-2 px-4 text-center cursor-pointer hover:bg-rose-50"
                        onClick={() => clearFilter()}
                    >
                        {t('clearAllLabel')}
                    </div>
                </div>
            </div>
        </div>
    );
};
