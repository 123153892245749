import { Avatars } from 'components/Avatars/Avatars';
import { normalizeArray } from 'lib/utils';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useEventStore } from 'stores/event.store';

import { EventInviteesModal } from './EventInviteesModal';

export const EventInvitedMembers = () => {
    const [isOpen, setIsOpen] = useState(false);
    const event = useEventStore(state => state.event);
    const [tab, setTab] = useState('CONFIRMED');
    const { t } = useTranslation('events');

    const openList = (tab: string) => {
        setTab(tab);
        setIsOpen(true);
    };

    if (!event) return null;

    return (
        <>
            <div className="flex gap-8">
                <div className="flex flex-col gap-3 min-w-[215px]">
                    <div className="text-sm font-medium text-zinc-700 opacity-60">{t('confirmedLabel')}</div>
                    <Avatars
                        members={normalizeArray(event.confirmed)}
                        total={event.confirmed.totalCount}
                        show={5}
                        onClick={() => openList('CONFIRMED')}
                    />
                </div>
                <div className="w-px h-[70px] bg-zinc-700 opacity-60"></div>
                <div className="flex flex-col gap-3">
                    <div className="text-sm font-medium text-zinc-700 opacity-60">{t('invitedLabel')}</div>
                    <Avatars
                        members={normalizeArray(event.invited)}
                        total={event.invited.totalCount}
                        show={5}
                        onClick={() => openList('INVITED')}
                    />
                </div>
            </div>

            <EventInviteesModal isOpen={isOpen} onClose={() => setIsOpen(false)} initialTab={tab} onTabChange={setTab}/>
        </>
    );
};
