import { Avatar } from 'components/Avatar/Avatar';
import { Balance } from 'components/Balance/Balance';
import { Card } from 'components/Card/Card';
import { Label } from 'components/Label/Label';
import { UserSettings } from 'components/UserSettings/UserSettings';
import { useTranslation } from 'react-i18next';
import { useUserStore } from 'stores/user.store';

export const MemberInfoPreview = () => {
    const { user, getUser } = useUserStore(state => state);
    const { t } = useTranslation('member');

    return (
        <Card className="flex flex-col gap-4 pb-2 relative">
            <div className="relative">
                <Avatar image={user?.image} status={user?.status} />
            </div>
            <div>
                <Label>{t('userId')}</Label>
                <div className="text-rose-700">{user?.memberId || '-'}</div>
            </div>
            <div>
                <Label>{t('userType')}</Label>
                <div className="text-rose-700">{user?.memberType ? t(user?.memberType) : t('INDIVIDUAL')}</div>
            </div>
            <div className="flex-1">
                <Label>{t('balance')}</Label>
                <div className="text-rose-700">
                    <Balance balance={user?.balance} />
                </div>
            </div>
            <div>
                {user && (
                    <UserSettings
                        actions={['BLOCK', 'DELETE', 'PASSWORD_RESET']}
                        user={user}
                        onChange={() => getUser(user?.id)}
                    />
                )}
            </div>
        </Card>
    );
};
