import { useQuery } from '@apollo/client';
import { Breadcrumbs } from 'components/Breadcrumbs/Breadcrumbs';
import { ContactInfoModal } from 'components/ContactInfoModal/ContactInfoModal';
import { EditContactModal } from 'components/ContactsModal/EditContactModal';
import { Container } from 'components/Container/Container';
import { Loader } from 'components/Loader/Loader';
import { MembersTabs } from 'components/MembersTabs/MembersTabs';
import { PageTitle } from 'components/PageTitle/PageTitle';
import { Pagination } from 'components/Pagination/Pagination';
import { SearchFilter } from 'components/SearchFilter/SearchFilter';
import { StatusFilter } from 'components/StatusFilter/StatusFilter';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from 'components/Table/Table';
// import { TableActions } from 'components/TableActions/TableActions';
import { normalizeArray } from 'lib/utils';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { CONTACTS } from 'services/contacts/query';

const TYPES = ['MEMBER', 'COMPANY', 'ASSISTANT', 'PARTNER', 'OTHER'];
const PAGE_SIZE = parseInt(localStorage.getItem('pageSize') as any) || 10;

export const ContactsList = () => {
    const [selectedContact, setSelectedContact] = useState(null);
    const [editContact, setEditContact] = useState(null);
    const { data, loading, refetch } = useQuery(CONTACTS, {
        variables: {
            first: PAGE_SIZE,
        },
    });
    const [searchParams] = useSearchParams();
    const { t } = useTranslation('translation');

    useEffect(() => {
        fetchAgain();
    }, [searchParams]); // eslint-disable-line

    const fetchAgain = () => {
        const type = searchParams.get('status')?.split(',');
        const page = searchParams.get('page') || '1';
        const search = searchParams.get('search');

        refetch({
            search: search,
            type: type,
            offset: (parseInt(page) - 1) * PAGE_SIZE,
        });
    };

    const edit = () => {
        setEditContact(selectedContact);
        setSelectedContact(null);
    };

    if (loading) return <Loader fullSize />;

    return (
        <Container>
            <Breadcrumbs crumbs={['dashboard', 'members', 'contacts']} />
            <MembersTabs />
            <PageTitle>{t('contacts.title')}</PageTitle>

            <div className="flex justify-between pb-4">
                <div className="flex gap-4 flex-wrap">
                    <SearchFilter placeholder={t('contacts.searchPlaceholder')} />
                    <StatusFilter options={TYPES} label={t('filters.typeFilter')} />
                </div>
            </div>

            <Table className="border overflow-visible">
                <TableHeader>
                    <TableRow>
                        <TableHead>{t('contacts.typeLabel')}</TableHead>
                        <TableHead>{t('contacts.nameLabel')}</TableHead>
                        <TableHead>{t('contacts.phoneLabel')}</TableHead>
                        <TableHead>{t('contacts.relatedLabel')}</TableHead>
                        <TableHead />
                    </TableRow>
                </TableHeader>
                <TableBody className="hover:cursor-pointer">
                    {data?.contacts.edges.length > 0 &&
                        normalizeArray(data.contacts).map((contact: any) => (
                            <TableRow
                                key={contact.id}
                                onClick={() => setSelectedContact(contact)}
                                className="hover:bg-rose-50"
                            >
                                <TableCell className="capitalize">{contact.type.toLowerCase()}</TableCell>
                                <TableCell>
                                    {contact.name} {contact.lastName}
                                </TableCell>
                                <TableCell>{contact.phoneNumber}</TableCell>
                                <TableCell>
                                    {contact.relatedMember?.firstName} {contact?.relatedMember?.lastName}
                                </TableCell>
                                <TableCell className="py-3 justify-end flex">{/* <TableActions /> */}</TableCell>
                            </TableRow>
                        ))}
                </TableBody>
            </Table>
            {data?.contacts.edges.length === 0 && (
                <div className={'py-5 text-center text-zinc-700'}>{t('members.noResults')}</div>
            )}
            <Pagination pageInfo={data?.contacts.pageInfo} total={data?.contacts.totalCount} />
            <ContactInfoModal onClose={() => setSelectedContact(null)} contact={selectedContact} onEdit={edit} />
            <EditContactModal onClose={() => setEditContact(null)} contact={editContact} onSave={() => fetchAgain()} />
        </Container>
    );
};
