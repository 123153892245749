import { Avatar } from 'components/Avatar/Avatar';
import { cn } from 'lib/utils';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { AppRoutes } from 'Routes';

export const PastAnniversary = ({ data }: any) => {
    const { t } = useTranslation('dashboard');
    const navigate = useNavigate();

    const goToProfile = () => {
        const id = data.relatedMember?.id || data.id;
        if (data.personType === 'Partner') {
            navigate(AppRoutes.memberPartner.replace(':id', id));
        } else {
            navigate(AppRoutes.memberPreview.replace(':id', id));
        }
    };

    const getDays = () => {
        switch (data.daysNumber) {
            case 0:
                return t('today');
            case 1:
                return t('yesterday');
            default:
                return t('xxDaysAgo', { days: data.daysNumber });
        }
    };

    const getText = () => {
        switch (data.personType) {
            case 'User':
                return t(`PAST_${data.anniversaryType}`, {
                    name: `${data.firstName} ${data.lastName}`,
                    year: data.yearNumber,
                    days: getDays(),
                });
            case 'Partner':
                return t(`PAST_PARTNER_${data.anniversaryType}`, {
                    name: `${data.relatedMember.firstName} ${data.relatedMember.lastName}`,
                    partnerName: `${data.firstName}`,
                    age: data.yearNumber,
                    days: getDays(),
                });
            default:
                return t(`PAST_CHILD_${data.anniversaryType}`, {
                    name: `${data.relatedMember.firstName} ${data.relatedMember.lastName}`,
                    gender: data.gender === 'MALE' ? t('son') : t('daughter'),
                    childFirstName: data.firstName,
                    age: data.yearNumber,
                    days: getDays(),
                });
        }
    };

    if (!data) return null;
    return (
        <div
            className={cn(
                'p-5 bg-white border border-zinc-200 flex gap-3 items-center text-zinc-700 hover:bg-rose-50 hover:border-rose-200 hover:cursor-pointer transition-colors',
            )}
            onClick={() => goToProfile()}
        >
            <Avatar image={data?.image} size={45} className="shrink-0" />
            <div dangerouslySetInnerHTML={{ __html: getText() }} className="leading-5" />
        </div>
    );
};
