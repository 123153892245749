import { FormError } from 'components/FormError/FormError';
import { Input } from 'components/Input/Input';
import { Label } from 'components/Label/Label';
import { Select } from 'components/Select/Select';
import { COUNTRIES } from 'lib/options';
import { useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useUserStore } from 'stores/user.store';

export const CompanyContact = () => {
    const user = useUserStore(state => state.user);
    const { t } = useTranslation('forms');
    const {
        register,
        control,
        unregister,
        formState: { errors },
    } = useFormContext();

    useEffect(() => {
        return () => unregister();
    }, [unregister]);

    return (
        <div className="flex flex-col gap-6">
            <div className="flex gap-6">
                <div className="flex-1">
                    <Label>{t('companyName')}</Label>
                    <Input
                        placeholder={t('companyName')}
                        {...register('name', {
                            required: t('companyNameRequired'),
                        })}
                        defaultValue={user?.company?.name}
                        error={!!errors.name}
                    />
                    <FormError error={errors.name} />
                </div>
                <div className="flex-1"></div>
            </div>

            <div className="flex gap-6">
                <div className="flex-1">
                    <Label>{t('vat')}</Label>
                    <Input placeholder={t('vat')} {...register('vatNumber')} defaultValue={user?.company?.vatNumber} />
                </div>
                <div className="flex-1">
                    <Label>{t('phone')}</Label>
                    <Input
                        {...register('phoneNumber', {
                            required: t('phoneNumberRequired'),
                        })}
                        placeholder={t('phone')}
                        error={!!errors.phoneNumber}
                    />
                    <FormError error={errors.phoneNumber} />
                </div>
            </div>

            <div className="flex gap-6">
                <div className="flex-1">
                    <Label>{t('email')}</Label>
                    <Input
                        {...register('email', {
                            required: t('emailRequired'),
                        })}
                        placeholder={t('email')}
                        error={!!errors.email}
                    />
                    <FormError error={errors.email} />
                </div>
                <div className="flex-1">
                    <Label>{t('secondaryEmail')}</Label>
                    <Input {...register('secondaryEmail')} placeholder={t('secondaryEmail')} />
                </div>
            </div>

            <div className="flex gap-6">
                <div className="flex-1">
                    <Label>{t('address')}</Label>
                    <Input {...register('address')} placeholder={t('address')} />
                </div>
                <div className="flex gap-6 flex-1">
                    <div className="flex-1">
                        <Label>{t('zipCode')}</Label>
                        <Input {...register('zipCode')} placeholder={t('zipCode')} />
                    </div>
                    <div className="flex-1">
                        <Label>{t('poBox')}</Label>
                        <Input {...register('poBox')} placeholder={t('poBox')} />
                    </div>
                </div>
            </div>

            <div className="flex gap-6">
                <div className="flex-1">
                    <Label> {t('city')}</Label>
                    <Input {...register('city')} placeholder={t('city')} />
                </div>
                <div className="flex-1">
                    <Label>{t('country')}</Label>
                    <Controller
                        control={control}
                        name="country"
                        render={({ field: { onChange, value } }) => (
                            <Select
                                options={COUNTRIES}
                                onChange={(val: any) => onChange(val.value)}
                                menuPortalTarget={document.body}
                                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                value={COUNTRIES.find(c => c.value === value)}
                            />
                        )}
                    />
                </div>
            </div>
        </div>
    );
};
