import { useQuery } from '@apollo/client';
import { Breadcrumbs } from 'components/Breadcrumbs/Breadcrumbs';
import { Button } from 'components/Button/Button';
import { Container } from 'components/Container/Container';
import { FullEvent } from 'components/FullEvent/FullEvent';
import { PageTitle } from 'components/PageTitle/PageTitle';
import { Pagination } from 'components/Pagination/Pagination';
import { SearchFilter } from 'components/SearchFilter/SearchFilter';
import { SortFilter } from 'components/SortFilter/SortFilter';
import { Status } from 'components/Status/Status';
import { StatusFilter } from 'components/StatusFilter/StatusFilter';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from 'components/Table/Table';
import dayjs from 'dayjs';
import { normalizeArray } from 'lib/utils';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { AppRoutes } from 'Routes';
import { EVENTS } from 'services/events/query';

const PAGE_SIZE = parseInt(localStorage.getItem('pageSize') as any) || 10;
const STATUS = ['UPCOMING', 'CONCLUDED', 'CANCELED', 'DRAFT'];
const CAPACITY = ['AVAILABLE', 'FULL'];
const TYPE = ['MEMBER', 'PARTNER', 'FAMILY', 'FRIEND'];
const SORT = [
    { value: 'A_Z', label: 'A-Z' },
    { value: 'Z_A', label: 'Z-A' },
    { value: 'MOST_RECENT', label: 'Most recent' },
    { value: 'LEAST_RECENT', label: 'Least recent' },
];

export const EventsList = () => {
    const [searchParams] = useSearchParams();
    const { data, refetch } = useQuery(EVENTS, { variables: { first: PAGE_SIZE } });
    const navigate = useNavigate();
    const { t } = useTranslation('events');

    useEffect(() => {
        const search = searchParams.get('search');
        const sort = searchParams.get('sort');
        const status = searchParams.get('status')?.split(',');
        const type = searchParams.get('type')?.split(',');
        const capacity = searchParams.get('capacity')?.split(',');
        const page = searchParams.get('page') || '1';

        const getCapacity = () => {
            if (capacity?.length === 2) return;
            if (capacity?.length === 1) {
                return capacity[0] === 'FULL' ? true : false;
            }
        };

        refetch({
            search: search,
            sort: sort,
            status: status,
            type: type,
            isFullCapacity: getCapacity(),
            offset: (parseInt(page) - 1) * PAGE_SIZE,
        });
    }, [searchParams, refetch]);

    const previewEvent = (id: string) => {
        navigate(AppRoutes.eventPreview.replace(':id', id));
    };

    return (
        <Container>
            <Breadcrumbs crumbs={['dashboard', 'events']} />
            <PageTitle>{t('title')}</PageTitle>

            <div className="flex justify-between pb-4 gap-4">
                <div className="flex gap-4 flex-wrap">
                    <SearchFilter placeholder={t('searchPlaceholder')} />
                    <StatusFilter options={STATUS} />
                    <StatusFilter options={TYPE} label={t('typeLabel')} filterKey="type" />
                    <StatusFilter options={CAPACITY} label={t('capacityLabel')} filterKey="capacity" />
                    <SortFilter options={SORT} />
                </div>
                <div className="flex gap-2">
                    <Button asChild>
                        <Link to={AppRoutes.newEvent}>{t('createEventButton')}</Link>
                    </Button>
                </div>
            </div>

            <Table className="border">
                <TableHeader>
                    <TableRow>
                        <TableHead>{t('table.nameLabel')}</TableHead>
                        <TableHead>{t('table.dateLabel')}</TableHead>
                        <TableHead className="hidden md:table-cell">{t('table.typeLabel')}</TableHead>
                        <TableHead className="hidden md:table-cell">{t('table.confirmedCapacityLabel')}</TableHead>
                        <TableHead>{t('table.statusLabel')}</TableHead>
                    </TableRow>
                </TableHeader>
                <TableBody className="hover:cursor-pointer">
                    {data?.events.edges.length > 0 &&
                        normalizeArray(data?.events).map((event: any) => (
                            <TableRow
                                key={event.id}
                                className="text-zinc-700 hover:bg-rose-50"
                                onClick={() => previewEvent(event.id)}
                            >
                                <TableCell>{event.name}</TableCell>
                                <TableCell>{dayjs(event.date).format('DD.MM.YYYY')}</TableCell>
                                <TableCell className="hidden md:table-cell">{t(event.type)}</TableCell>
                                <TableCell className="hidden md:table-cell">
                                    {event.confirmed?.totalCount}/{event.capacity}
                                    <FullEvent event={event} />
                                </TableCell>
                                <TableCell>
                                    <Status status={event.status} />
                                </TableCell>
                            </TableRow>
                        ))}
                </TableBody>
            </Table>
            {data?.events.edges.length === 0 && (
                <div className={'py-5 text-center text-zinc-700'}>{t('noResults')}</div>
            )}
            <Pagination pageInfo={data?.events?.pageInfo} total={data?.events?.totalCount} />
        </Container>
    );
};
