import { InputMask } from '@react-input/mask';
import { Card, CardSubtitle, CardTitle } from 'components/Card/Card';
import Checkbox from 'components/Checkbox/Checkbox';
import { FormError } from 'components/FormError/FormError';
import { Input } from 'components/Input/Input';
import { Label } from 'components/Label/Label';
import { Select } from 'components/Select/Select';
import dayjs from 'dayjs';
import { SALUTATIONS } from 'lib/options';
import { validateDate } from 'lib/utils';
import { useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useUserStore } from 'stores/user.store';

export const PartnerInfo = () => {
    const { t } = useTranslation('partner', { keyPrefix: 'personalInfo' });
    const { t: f } = useTranslation('forms');
    const partner = useUserStore(state => state.user?.partner);
    const {
        register,
        control,
        setValue,
        formState: { errors },
    } = useFormContext();

    // Pre-fill the fields if partner exists
    useEffect(() => {
        if (partner) {
            setValue('firstName', partner.firstName);
            setValue('lastName', partner.lastName);
            setValue('salutation', partner.salutation);
            setValue('dateOfBirth', partner.dateOfBirth && dayjs(partner.dateOfBirth).format('DD.MM.YYYY'));
            setValue('company', partner.company);
            setValue('jobTitle', partner.jobTitle);
            setValue('isSpouse', partner.isSpouse);
        }
    }, [partner, setValue]);

    return (
        <Card className="flex-1">
            <CardTitle>{t('title')}</CardTitle>
            <CardSubtitle>{t('subtitle')}</CardSubtitle>

            <div className="flex flex-col gap-6">
                <div className="flex gap-6">
                    <div className="flex-1">
                        <Label>{f('firstName')}</Label>
                        <Input {...register('firstName')} placeholder={f('firstName')} />
                        <div className="flex gap-2 items-center text-zinc-700 text-sm mt-2">
                            <Controller
                                control={control}
                                name="isSpouse"
                                render={({ field: { onChange, value } }) => (
                                    <Checkbox onChange={onChange} checked={value}>
                                        {f('spouseLabel')}
                                    </Checkbox>
                                )}
                            />
                        </div>
                    </div>
                    <div className="flex-1">
                        <Label>{f('lastName')}</Label>
                        <Input {...register('lastName')} placeholder={f('lastName')} />
                    </div>
                </div>

                <div className="flex gap-6">
                    <div className="flex-1">
                        <Label>{f('salutation')}</Label>
                        <Controller
                            control={control}
                            name="salutation"
                            rules={{ required: f('salutationRequired') }}
                            render={({ field: { onChange } }) => (
                                <Select
                                    isSearchable={false}
                                    placeholder={f('selectPlaceholder')}
                                    options={SALUTATIONS}
                                    onChange={(val: any) => onChange(val.value)}
                                    error={!!errors.salutation}
                                    defaultValue={SALUTATIONS.find(s => s.value === partner?.salutation)}
                                />
                            )}
                        />
                    </div>
                    <div className="flex-1">
                        <Label>{f('dateOfBirth')}</Label>
                        <InputMask
                            component={Input}
                            placeholder={f('datePlaceholder')}
                            mask="__.__.____"
                            replacement={{ _: /\d/ }}
                            error={!!errors.dateOfBirth}
                            {...register('dateOfBirth', {
                                validate: value => validateDate(value) || f('invalidDateError'),
                                required: f('dateOfBirthRequired'),
                            })}
                        />
                        <FormError error={errors.dateOfBirth} />
                    </div>
                </div>

                <div className="flex gap-6">
                    <div className="flex-1">
                        <Label>{f('company')}</Label>
                        <Input {...register('company')} placeholder={f('company')} />
                    </div>
                    <div className="flex-1">
                        <Label>{f('jobTitle')}</Label>
                        <Input {...register('jobTitle')} placeholder={f('jobTitle')} />
                    </div>
                </div>
            </div>
        </Card>
    );
};
