let items = [];
for (var hour = 0; hour < 24; hour++) {
    items.push([hour, 0]);
    items.push([hour, 15]);
    items.push([hour, 30]);
    items.push([hour, 45]);
}

const date = new Date();
const formatter = new Intl.DateTimeFormat('de-CH', {
    hour: 'numeric',
    minute: 'numeric',
    hour12: false,
});

export const TIME_RANGE = items.map(time => {
    const [hour, minute] = time;
    date.setHours(hour);
    date.setMinutes(minute);

    return { value: formatter.format(date), label: `${formatter.format(date)}` };
});
