import { useMutation } from '@apollo/client';
import { Breadcrumbs } from 'components/Breadcrumbs/Breadcrumbs';
import { Button } from 'components/Button/Button';
import { StaffHeader } from 'components/StaffHeader/StaffHeader';
import { useToast } from 'components/Toast/use-toast';
import dayjs from 'dayjs';
import { StaffInfo } from 'pages/NewStaff/_components/StaffInfo';
import { StaffPersonalInfo } from 'pages/NewStaff/_components/StaffPersonalInfo';
import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { AppRoutes } from 'Routes';
import { STAFF_UPDATE } from 'services/users/mutation';
import { useStaffStore } from 'stores/staff.store';

export const EditStaff = () => {
    const { member, getMember } = useStaffStore(state => state);
    const methods = useForm();
    const [updateStaff] = useMutation(STAFF_UPDATE);
    const { t } = useTranslation('staff');
    const { toast } = useToast();
    const navigate = useNavigate();

    useEffect(() => {
        if (member) {
            const data = {
                email: member.email,
                role: member.staffRole,
                firstName: member.firstName,
                lastName: member.lastName,
                dateOfBirth: member.dateOfBirth && dayjs(member.dateOfBirth).format('DD.MM.YYYY'),
                jobPosition: member.jobPosition,
                phoneNumber: member.phoneNumber,
                address: member.address,
                zipCode: member.zipCode,
                poBox: member.poBox,
                city: member.city,
                country: member.country?.code,
            };
            methods.reset(data);
        }
    }, [member, methods]);

    const onSubmit = (formData: any) => {
        formData.dateOfBirth = formData.dateOfBirth && dayjs(formData.dateOfBirth, 'DD.MM.YYYY').format('YYYY-MM-DD');
        if (formData.role) {
            formData.staffRole = formData.role;
            delete formData.role;
        }
        const { email, ...rest } = formData;
        updateStaff({ variables: { user: member?.id, input: rest } })
            .then((res: any) => {
                if (res.data.staffUpdate.user) {
                    toast({
                        title: t('updateSuccess'),
                        variant: 'success',
                    });
                    getMember(res.data.staffUpdate.user.id);
                    navigate(AppRoutes.staffProfile.replace(':id', res.data.staffUpdate.user.id));
                }
            })
            .catch(e => {
                toast({
                    title: t('updateErrorTitle'),
                    description: t('updateErrorMessage'),
                    variant: 'warning',
                });
            });
    };

    return (
        <>
            <Breadcrumbs crumbs={['dashboard', 'staff', `${member?.firstName} ${member?.lastName}`]} />
            <StaffHeader />

            <FormProvider {...methods}>
                <form onSubmit={methods.handleSubmit(onSubmit)}>
                    <div className="flex gap-6">
                        <StaffInfo />
                        <StaffPersonalInfo />
                    </div>
                    <div className="flex justify-end py-8">
                        <Button>{t('saveButton')}</Button>
                    </div>
                </form>
            </FormProvider>
        </>
    );
};
