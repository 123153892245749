import { Card, CardSubtitle, CardTitle } from 'components/Card/Card';
import { useTranslation } from 'react-i18next';
import { useEventStore } from 'stores/event.store';

export const EventComments = () => {
    const event = useEventStore(state => state.event);
    const { t } = useTranslation('events');

    if (!event || !event.comments) return null;
    return (
        <Card>
            <CardTitle>{t('eventCommentsTitle')}</CardTitle>
            <CardSubtitle>{t('eventCommentsSubtitle')}</CardSubtitle>
            <div>{event.comments}</div>
        </Card>
    );
};
