import { Card, CardTitle } from 'components/Card/Card';
import { PreviewLabel, PreviewValue } from 'components/PreviewFields/PreviewFields';
import { useTranslation } from 'react-i18next';
import { useCompanyStore } from 'stores/company.store';

export const CompanyInfoPreview = () => {
    const company = useCompanyStore(state => state.company);
    const { t: c } = useTranslation('company');
    const { t } = useTranslation('forms');

    return (
        <Card className="flex-1 flex flex-col gap-8">
            <CardTitle>{c('companyInfoTitle')}</CardTitle>
            <div className="flex flex-col gap-6">
                <div className="flex gap-6">
                    <div className="flex-1">
                        <PreviewLabel>{t('companyName')}</PreviewLabel>
                        <PreviewValue>{company?.name}</PreviewValue>
                    </div>
                </div>

                <div className="flex gap-6">
                    <div className="flex-1">
                        <PreviewLabel>{t('preview.email')}</PreviewLabel>
                        <PreviewValue>{company?.email}</PreviewValue>
                    </div>
                    <div className="flex-1">
                        <PreviewLabel>{t('preview.phone')}</PreviewLabel>
                        <PreviewValue>{company?.phoneNumber}</PreviewValue>
                    </div>
                </div>

                <div className="flex gap-6">
                    <div className="flex-1">
                        <PreviewLabel>{t('uidNumber')}</PreviewLabel>
                        <PreviewValue>{company?.uidNumber}</PreviewValue>
                    </div>
                    <div className="flex-1">
                        <PreviewLabel>{t('vat')}</PreviewLabel>
                        <PreviewValue>{company?.vatNumber}</PreviewValue>
                    </div>
                </div>

                <div className="flex gap-6">
                    <div className="flex-1">
                        <PreviewLabel>{t('address')}</PreviewLabel>
                        <PreviewValue>{company?.address}</PreviewValue>
                    </div>
                    <div className="flex-1 flex gap-6">
                        <div className="flex-1">
                            <PreviewLabel>{t('zipCode')}</PreviewLabel>
                            <PreviewValue>{company?.zipCode}</PreviewValue>
                        </div>
                        <div className="flex-1">
                            <PreviewLabel>{t('poBox')}</PreviewLabel>
                            <PreviewValue>{company?.poBox || '-'}</PreviewValue>
                        </div>
                    </div>
                </div>

                <div className="flex gap-6">
                    <div className="flex-1">
                        <PreviewLabel>{t('city')}</PreviewLabel>
                        <PreviewValue>{company?.city}</PreviewValue>
                    </div>
                    <div className="flex-1">
                        <PreviewLabel>{t('country')}</PreviewLabel>
                        <PreviewValue>{company?.country?.name}</PreviewValue>
                    </div>
                </div>
            </div>
        </Card>
    );
};
