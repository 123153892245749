import { Breadcrumbs } from 'components/Breadcrumbs/Breadcrumbs';
import { Button } from 'components/Button/Button';
import { Icon } from 'components/Icon/Icon';
import { Loader } from 'components/Loader/Loader';
import { StaffHeader } from 'components/StaffHeader/StaffHeader';
import { useNavigate, useParams } from 'react-router-dom';
import { AppRoutes } from 'Routes';
import { useStaffStore } from 'stores/staff.store';

import { StaffInfoPreview } from './_components/StaffInfoPreview';
import { StaffPersonalInfoPreview } from './_components/StaffPersonalInfoPreview';

export const StaffProfile = () => {
    const { member, loading } = useStaffStore(state => state);
    const { id } = useParams();
    const navigate = useNavigate();

    const editStaff = () => {
        if (!id) return;
        navigate(AppRoutes.editStaff.replace(':id', id));
    };

    if (loading && !member) return <Loader fullSize />;

    return (
        <>
            <Breadcrumbs crumbs={['dashboard', 'staff', `${member?.firstName} ${member?.lastName}`]} />

            <StaffHeader>
                <Button onClick={editStaff}>
                    <Icon name="edit" className="h-4 mr-2" />
                    Edit
                </Button>
            </StaffHeader>

            <div className="flex gap-6">
                <StaffInfoPreview />
                <StaffPersonalInfoPreview />
            </div>
        </>
    );
};
