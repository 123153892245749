import { Container } from 'components/Container/Container';
import { Loader } from 'components/Loader/Loader';
import { useEffect } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import { useUserStore } from 'stores/user.store';

export const DocumentsLayout = () => {
    const { getUser, setUser, loading } = useUserStore(state => state);
    const { id } = useParams();

    useEffect(() => {
        if (id) getUser(id);
        return () => setUser(null);
    }, [id, getUser, setUser]);

    if (loading) return <Loader fullSize />;

    return (
        <Container>
            <Outlet />
        </Container>
    );
};
