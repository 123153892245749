import { Button } from 'components/Button/Button';
import { Modal } from 'components/Modal/Modal';
import { Portal } from 'components/Portal/Portal';
import { useTranslation } from 'react-i18next';

type ConfirmInviteesModalProps = {
    isOpen: boolean;
    onClose: () => void;
    onConfirm: () => void;
};
export const ConfirmInviteesModal: React.FC<ConfirmInviteesModalProps> = ({ isOpen, onClose, onConfirm }) => {
    const { t } = useTranslation('events');
    return (
        <Portal>
            <Modal isOpen={isOpen} onClose={onClose} maxWidth={512} uncloseable className="flex flex-col gap-4">
                <div>
                    <div className="text-xl font-semibold text-zinc-700 mb-2">{t('confirmInviteesTitle')}</div>
                    <div className="text-sm text-zinc-500">{t('confirmInviteesSubtitle')}</div>
                </div>
                <div className="flex justify-end gap-2">
                    <Button variant="outline" onClick={onClose}>
                        {t('cancelButton')}
                    </Button>
                    <Button onClick={onConfirm}>{t('confirmButton')}</Button>
                </div>
            </Modal>
        </Portal>
    );
};
