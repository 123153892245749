import { cn } from 'lib/utils';

import styles from './Loader.module.scss';

type LoaderProps = {
    fullSize?: boolean;
    onUpdate?: boolean;
    className?: string;
};

export const Loader: React.FC<LoaderProps> = ({ fullSize, onUpdate, className }) => {
    return (
        <div
            className={cn(
                fullSize && styles.fullsize,
                onUpdate &&
                    'absolute w-full h-full left-0 top-0 flex justify-center items-center bg-zinc-50 z-10 bg-opacity-50',
                className,
            )}
        >
            <span className={styles.loader}></span>
        </div>
    );
};
