import { useQuery } from '@apollo/client';
import { Balance } from 'components/Balance/Balance';
import { Breadcrumbs } from 'components/Breadcrumbs/Breadcrumbs';
import { Button } from 'components/Button/Button';
import { Container } from 'components/Container/Container';
import { Loader } from 'components/Loader/Loader';
import { MembersTabs } from 'components/MembersTabs/MembersTabs';
import { PageTitle } from 'components/PageTitle/PageTitle';
import { Pagination } from 'components/Pagination/Pagination';
import { SearchFilter } from 'components/SearchFilter/SearchFilter';
import { SortFilter } from 'components/SortFilter/SortFilter';
import { Status } from 'components/Status/Status';
import { StatusFilter } from 'components/StatusFilter/StatusFilter';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from 'components/Table/Table';
import { normalizeArray } from 'lib/utils';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { AppRoutes } from 'Routes';
import { COMPANIES } from 'services/companies/query';

const PAGE_SIZE = parseInt(localStorage.getItem('pageSize') as any) || 10;

const STATUSES = ['ACTIVE', 'INACTIVE', 'SUSPENDED', 'BLOCKED'];
const SORT = [
    { value: 'A_Z', label: 'A-Z' },
    { value: 'Z_A', label: 'Z-A' },
    { value: 'MOST_RECENT', label: 'Most recent' },
    { value: 'LEAST_RECENT', label: 'Least recent' },
    { value: 'HIGH_TO_LOW', label: 'Highest to lowest', suffix: '(balance)' },
    { value: 'LOW_TO_HIGH', label: 'Lowest to highest', suffix: '(balance)' },
];

export const CompaniesList = () => {
    const { t } = useTranslation('translation');
    const navigate = useNavigate();
    const { data, loading, refetch } = useQuery(COMPANIES, {
        variables: {
            first: PAGE_SIZE,
        },
    });
    const [searchParams] = useSearchParams();

    useEffect(() => {
        const search = searchParams.get('search');
        const sort = searchParams.get('sort');
        const status = searchParams.get('status')?.split(',');
        const page = searchParams.get('page') || '1';

        refetch({
            search: search,
            status: status,
            sort: sort,
            offset: (parseInt(page) - 1) * PAGE_SIZE,
        });
    }, [searchParams, refetch]);

    const previewCompany = (id: string) => {
        navigate(AppRoutes.companyPreview.replace(':id', id));
    };

    if (loading) return <Loader fullSize />;

    return (
        <Container>
            <Breadcrumbs crumbs={['dashboard', 'members', 'companies']} />
            <MembersTabs />
            <PageTitle>{t('companies.title')}</PageTitle>

            <div className="flex justify-between pb-4 gap-4">
                <div className="flex gap-4 flex-wrap">
                    <SearchFilter placeholder={t('companies.searchPlaceholder')} />
                    <StatusFilter options={STATUSES} />
                    <SortFilter options={SORT} />
                </div>
                <Button asChild>
                    <Link to={AppRoutes.newCompany}>{t('companies.addCompany')}</Link>
                </Button>
            </div>

            <Table className="border">
                <TableHeader>
                    <TableRow>
                        <TableHead>{t('companies.nameLabel')}</TableHead>
                        <TableHead>{t('companies.balanceLabel')}</TableHead>
                        <TableHead>{t('companies.emailLabel')}</TableHead>
                        <TableHead>{t('companies.statusLabel')}</TableHead>
                    </TableRow>
                </TableHeader>
                <TableBody className="hover:cursor-pointer">
                    {data?.companies.edges.length > 0 &&
                        normalizeArray(data?.companies).map((company: any) => (
                            <TableRow
                                key={company.id}
                                className="text-zinc-700 hover:bg-rose-50"
                                onClick={() => previewCompany(company.id)}
                            >
                                <TableCell className="flex gap-2 items-center">{company.name}</TableCell>
                                <TableCell>
                                    <Balance balance={company.balance} />
                                </TableCell>
                                <TableCell>{company.email || '-'}</TableCell>
                                <TableCell>
                                    <Status status={company.status} />
                                </TableCell>
                            </TableRow>
                        ))}
                </TableBody>
            </Table>
            {data?.companies.edges.length === 0 && (
                <div className={'py-5 text-center text-zinc-700'}>{t('members.noResults')}</div>
            )}
            <Pagination pageInfo={data?.companies?.pageInfo} total={data?.companies?.totalCount} />
        </Container>
    );
};
