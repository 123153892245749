import { client } from 'lib/apollo';

import { ADD_CHILD, ADD_PET } from './mutation';
import { USERS, USER_BY_ID } from './query';

export const getUsers = () => client.query({ query: USERS });

export const getUser = (id: string) =>
    client.query({
        query: USER_BY_ID,
        variables: {
            id,
        },
    });

export const addChild = (child: any, id: string) =>
    client.mutate({
        mutation: ADD_CHILD,
        variables: {
            input: child,
            user: id,
        },
    });

export const addPet = (pet: any, id: any) =>
    client.mutate({
        mutation: ADD_PET,
        variables: {
            input: pet,
            user: id,
        },
    });
