import { Balance } from 'components/Balance/Balance';
import { Button } from 'components/Button/Button';
import { Card } from 'components/Card/Card';
import { Icon } from 'components/Icon/Icon';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { AppRoutes } from 'Routes';
import { useCompanyStore } from 'stores/company.store';

export const CompanyPreviewbalance = () => {
    const company = useCompanyStore(state => state.company);
    const { t } = useTranslation('company');

    return (
        <Card className="flex justify-between">
            <div className="flex flex-col gap-8">
                <div className="text-lg font-semibold">{t('balance')}</div>
                <div className="text-2xl font-semibold text-rose-700">
                    <Balance balance={company?.balance} />
                </div>
            </div>
            <div className="flex gap-2">
                <Button variant="outline" asChild>
                    <Link to={AppRoutes.editCompany.replace(':id', company?.id)}>
                        <Icon name="edit" className="h-4 mr-2" />
                        {t('editProfileButton')}
                    </Link>
                </Button>
                <Button asChild>
                    <Link to={AppRoutes.companyProfile.replace(':id', company?.id)}>{t('viewProfileButton')}</Link>
                </Button>
            </div>
        </Card>
    );
};
