import { Avatar } from 'components/Avatar/Avatar';
import { Icon } from 'components/Icon/Icon';
import { DraftLabel } from 'components/Labels/DraftLabel';
import { ViewButton } from 'components/ViewButton/ViewButton';
import { useTranslation } from 'react-i18next';
import { AppRoutes } from 'Routes';

export const MemberBox = ({ member, onRemove }: any) => {
    const { t } = useTranslation('company');
    if (!member) return null;

    return (
        <div className="p-6 border border-rose-100 flex gap-6 flex-1">
            <Avatar image={member.image} size={128} />
            <div className="flex flex-col gap-4 flex-1">
                <div className="font-bold flex justify-between">
                    <div className="flex gap-2 items-center">
                        <div>
                            {member?.firstName} {member?.lastName}
                        </div>
                        <DraftLabel status={member.status} />
                    </div>
                    {onRemove && (
                        <div
                            onClick={onRemove}
                            className="text-rose-700 text-sm font-medium flex gap-1 items-center hover:cursor-pointer"
                        >
                            <Icon name="trash" className="h-4" /> <span className="underline">{t('removeButton')}</span>
                        </div>
                    )}
                    {!onRemove && <ViewButton to={AppRoutes.memberProfile.replace(':id', member?.id)} />}
                </div>
                <div className="bg-rose-100 h-px w-full"></div>
                <div className="flex gap-2 text-zinc-500">
                    <Icon name="company" />
                    <span>{member?.jobPosition}</span>
                </div>
                <div className="flex gap-2 text-zinc-500 items-center">
                    <Icon name="mail" className="h-5" />
                    <span>{member?.email}</span>
                </div>
            </div>
        </div>
    );
};
