import { useMutation } from '@apollo/client';
import { Label } from 'components/Label/Label';
import { Loader } from 'components/Loader/Loader';
import { Modal } from 'components/Modal/Modal';
import { Portal } from 'components/Portal/Portal';
import { RadioGroup, RadioGroupItem } from 'components/RadioGroup/RadioGroup';
import { useToast } from 'components/Toast/use-toast';
import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { CREATE_CONTACT } from 'services/contacts/mutation';
import { useCompanyStore } from 'stores/company.store';
import { useUserStore } from 'stores/user.store';

import { AssistantContact } from './_components/AssistantContact';
import { CompanyContact } from './_components/CompanyContact';
import { ContactFormActions } from './_components/ContactFormActions';
import { MemberContact } from './_components/MemberContact';
import { OtherContact } from './_components/OtherContact';

export const ContactsModal = ({ isOpen, onClose, types, member, company }: any) => {
    const [tab, setTab] = useState(types[0]);
    const methods = useForm();
    const [addContact, { loading: loadingContact }] = useMutation(CREATE_CONTACT);
    const { toast } = useToast();
    const { t } = useTranslation('forms');
    const getUser = useUserStore(state => state.getUser);
    const getCompany = useCompanyStore(state => state.getCompany);

    const onSubmit = (data: any) => {
        data.type = tab.toUpperCase();
        Object.keys(data).forEach(k => (data[k] === '' ? (data[k] = null) : null));
        addContact({
            variables: {
                input: data,
                member: member,
                company: company,
            },
        })
            .then((resp: any) => {
                if (resp.errors?.length > 0) {
                    toast({
                        description: resp.errors[0].message,
                        variant: 'warning',
                    });
                }
                if (resp.data.contactCreate) {
                    toast({
                        description: 'Contact created successfully',
                        variant: 'success',
                    });
                    onClose();
                    if (member) getUser(member);
                    if (company) getCompany(company);
                }
            })
            .catch(e =>
                toast({
                    description: 'Something went wrong. Please try again.',
                    variant: 'warning',
                }),
            );
    };

    return (
        <Portal>
            <Modal isOpen={isOpen} onClose={onClose} maxWidth={800}>
                <div className="flex flex-col gap-8">
                    <div>
                        <div className="text-xl font-semibold text-zinc-700 mb-2">{t('newContactTitle')}</div>
                        <div className="text-sm text-zinc-500">{t('newContactSubtitle')}</div>
                    </div>
                    {loadingContact && <Loader onUpdate />}
                    <div className="flex flex-col gap-4 text-sm font-medium">
                        <div>{t('contactFor')}</div>
                        <div className="flex gap-12">
                            <RadioGroup
                                className="flex gap-12"
                                defaultValue={types[0]}
                                onValueChange={(val: string) => setTab(val)}
                            >
                                {types.map((type: string) => (
                                    <div className="flex items-center gap-2" key={type}>
                                        <RadioGroupItem value={type} />
                                        <Label className="mb-0 capitalize">{type}</Label>
                                    </div>
                                ))}
                            </RadioGroup>
                        </div>
                    </div>
                    <FormProvider {...methods}>
                        <form onSubmit={methods.handleSubmit(onSubmit)}>
                            {tab === 'member' && <MemberContact />}
                            {tab === 'company' && <CompanyContact />}
                            {tab === 'partner' && <MemberContact />}
                            {tab === 'assistant' && <AssistantContact />}
                            {tab === 'other' && <OtherContact />}

                            <ContactFormActions onClose={onClose} />
                        </form>
                    </FormProvider>
                </div>
            </Modal>
        </Portal>
    );
};
