import { Icon } from 'components/Icon/Icon';
import { useTranslation } from 'react-i18next';
// import { Link } from 'react-router-dom';
import { HashLink as Link } from 'react-router-hash-link';

export const ViewButton = ({ to }: any) => {
    const { t } = useTranslation('translation');
    return (
        <Link to={to} className="flex gap-2 items-center">
            <Icon name="eye" className="h-5" />
            <span className="underline text-rose-700 font-medium">{t('members.viewButton')}</span>
        </Link>
    );
};
