import { CKEditor } from '@ckeditor/ckeditor5-react';
import { InputMask } from '@react-input/mask';
import { ClassicEditor, Bold, Essentials, Italic, Mention, Paragraph, Undo, Underline, Strikethrough } from 'ckeditor5';
import { Card, CardTitle } from 'components/Card/Card';
import Checkbox from 'components/Checkbox/Checkbox';
import { FormError } from 'components/FormError/FormError';
import { Input } from 'components/Input/Input';
import { Label } from 'components/Label/Label';
import { Select } from 'components/Select/Select';
import { EVENT_TYPES } from 'lib/options';
import { TIME_RANGE } from 'lib/time';
import { cn, validateDate } from 'lib/utils';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useEventStore } from 'stores/event.store';

import 'ckeditor5/ckeditor5.css';

export const EventInfoForm = () => {
    const event = useEventStore(state => state.event);
    const { t } = useTranslation('forms');
    const { t: e } = useTranslation('events');
    const {
        register,
        control,
        watch,
        formState: { errors },
    } = useFormContext();
    return (
        <Card className="flex flex-col gap-8">
            <CardTitle>{e('eventInfoTitle')}</CardTitle>

            <div className="flex flex-col gap-6">
                <div>
                    <Label>{t('title')}</Label>
                    <Input
                        {...register('name', { required: t('titleRequired') })}
                        placeholder={t('title')}
                        error={!!errors.name}
                    />
                    <FormError error={errors.name} />
                </div>

                <div className="flex gap-6">
                    <div className="flex-1">
                        <Label>{t('type')}</Label>
                        <Controller
                            control={control}
                            name="type"
                            rules={{ required: t('typeRequired') }}
                            render={({ field: { onChange } }) => (
                                <Select
                                    isSearchable={false}
                                    placeholder={t('selectPlaceholder')}
                                    options={EVENT_TYPES}
                                    onChange={(val: any) => onChange(val.value)}
                                    error={!!errors.type}
                                    defaultValue={EVENT_TYPES.find(s => s.value === event?.type)}
                                />
                            )}
                        />
                        <FormError error={errors.type} />
                    </div>
                    <div className="flex-1">
                        <Label>{t('capacity')}</Label>
                        <Input
                            {...register('capacity', { required: t('capacityRequired') })}
                            placeholder={t('capacity')}
                            type="number"
                            error={!!errors.capacity}
                        />
                        <FormError error={errors.capacity} />
                    </div>
                </div>

                <div className="flex gap-6">
                    <div className="flex-1">
                        <Label>{t('date')}</Label>
                        <InputMask
                            component={Input}
                            placeholder={t('datePlaceholder')}
                            mask="__.__.____"
                            replacement={{ _: /\d/ }}
                            error={!!errors.date}
                            {...register('date', {
                                validate: value => validateDate(value) || t('invalidDateError'),
                                required: t('dateRequired'),
                            })}
                        />
                        <FormError error={errors.date} />
                    </div>
                    <div className={cn('flex-1 flex gap-6')}>
                        <div className="flex-1">
                            <Label>{t('from')}</Label>
                            <Controller
                                control={control}
                                rules={{
                                    validate: value => {
                                        if (!watch('allDay') && !value) {
                                            return t('timeRequired');
                                        }
                                    },
                                }}
                                name="fromTime"
                                render={({ field: { onChange } }) => (
                                    <Select
                                        isDisabled={watch('allDay')}
                                        placeholder={t('selectPlaceholder')}
                                        error={!!errors.fromTime}
                                        options={TIME_RANGE}
                                        onChange={(val: any) => onChange(val.value)}
                                        defaultValue={TIME_RANGE.find(s => s.value === event?.type)}
                                    />
                                )}
                            />
                            <Controller
                                control={control}
                                name="allDay"
                                defaultValue={false}
                                render={({ field: { onChange, value } }) => (
                                    <Checkbox onChange={onChange} checked={value} className="mt-2">
                                        {t('allDay')}
                                    </Checkbox>
                                )}
                            />
                            <FormError error={errors.untilTime || errors.fromTime} />
                        </div>
                        <div className="flex-1">
                            <Label>{t('to')}</Label>
                            <Controller
                                control={control}
                                name="untilTime"
                                rules={{
                                    validate: value => {
                                        if (!watch('allDay') && !value) {
                                            return t('timeRequired');
                                        }
                                    },
                                }}
                                render={({ field: { onChange } }) => (
                                    <Select
                                        isDisabled={watch('allDay')}
                                        placeholder={t('selectPlaceholder')}
                                        error={!!errors.untilTime}
                                        options={TIME_RANGE}
                                        onChange={(val: any) => onChange(val.value)}
                                        defaultValue={TIME_RANGE.find(s => s.value === event?.type)}
                                    />
                                )}
                            />
                        </div>
                    </div>
                </div>

                <div>
                    <Label>{t('description')}</Label>
                    <Controller
                        control={control}
                        name="description"
                        render={({ field: { onChange, value } }) => (
                            <CKEditor
                                editor={ClassicEditor}
                                onChange={(value, editor) => onChange(editor.getData())}
                                config={{
                                    toolbar: {
                                        items: ['undo', 'redo', '|', 'bold', 'italic', 'underline', 'strikethrough'],
                                    },
                                    plugins: [
                                        Bold,
                                        Essentials,
                                        Italic,
                                        Mention,
                                        Paragraph,
                                        Undo,
                                        Underline,
                                        Strikethrough,
                                    ],
                                    initialData: event?.description,
                                    placeholder: 'Enter description...',
                                }}
                            />
                        )}
                    />
                </div>
            </div>
        </Card>
    );
};
