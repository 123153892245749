import { useQuery } from '@apollo/client';
import { Balance } from 'components/Balance/Balance';
import { Button } from 'components/Button/Button';
import { Card, CardTitle } from 'components/Card/Card';
import { Date } from 'components/Date/Date';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from 'components/Table/Table';
import { normalizeArray } from 'lib/utils';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { USERS } from 'services/users/query';

import { RemainderBalancesModal } from './RemainderBalancesModal';

export const RemainderBalances = () => {
    const [isOpen, setIsOpen] = useState(false);
    const { data } = useQuery(USERS, {
        variables: {
            isMember: true,
            sort: 'NEAREST_MEMBERSHIP_END',
            hasRemainderBalance: true,
        },
    });
    const { t } = useTranslation('dashboard');
    return (
        <>
            <Card className="flex-1 border flex flex-col gap-6">
                <div className="flex justify-between h-6">
                    <CardTitle className="text-base">{t('remainderBalancesTitle')}</CardTitle>
                    {data?.users?.edges.length > 5 && (
                        <Button asChild variant="link" className="h-6 hover:cursor-pointer">
                            <div onClick={() => setIsOpen(true)}>{t('viewAllButton')}</div>
                        </Button>
                    )}
                </div>
                <div>
                    <Table className="border bg-white">
                        <TableHeader>
                            <TableRow>
                                <TableHead>{t('nameLabel')}</TableHead>
                                <TableHead>{t('remainderBalanceLabel')}</TableHead>
                                <TableHead>{t('expiresLabel')}</TableHead>
                            </TableRow>
                        </TableHeader>
                        <TableBody>
                            {normalizeArray(data?.users)?.length > 0 &&
                                normalizeArray(data?.users)
                                    .slice(0, 5)
                                    .map((member: any) => (
                                        <TableRow key={member.id}>
                                            <TableCell className="flex gap-2 items-center w-full">
                                                {member.firstName} {member.lastName}
                                            </TableCell>
                                            <TableCell>
                                                <Balance
                                                    balance={{
                                                        amount: member.remainderBalance,
                                                        currency: 'CHF',
                                                    }}
                                                />
                                            </TableCell>
                                            <TableCell>
                                                <Date date={member.membershipEnd} />
                                            </TableCell>
                                        </TableRow>
                                    ))}
                        </TableBody>
                    </Table>
                </div>
            </Card>
            <RemainderBalancesModal
                isOpen={isOpen}
                onClose={() => setIsOpen(false)}
                members={normalizeArray(data?.users)}
            />
        </>
    );
};
