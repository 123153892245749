import { Avatar } from 'components/Avatar/Avatar';
import { Balance } from 'components/Balance/Balance';
import { Card } from 'components/Card/Card';
import { Label } from 'components/Label/Label';
import { useTranslation } from 'react-i18next';
import { useCompanyStore } from 'stores/company.store';

export const CompanyProfilePreview = () => {
    const company = useCompanyStore(state => state.company);
    const { t } = useTranslation('company');
    return (
        <div>
            <Card className="flex flex-col gap-4 pb-2 relative">
                <Avatar image={company?.image} />

                <div>
                    <Label>{t('companyId')}</Label>
                    <div className="text-rose-700">{company?.companyId || '-'}</div>
                </div>
                <div>
                    <Label>{t('accountType')}</Label>
                    <div className="text-rose-700">Company</div>
                </div>
                <div>
                    <Label>{t('balance')}</Label>
                    <div className="text-rose-700">
                        <Balance balance={company?.balance} />
                    </div>
                </div>
            </Card>
        </div>
    );
};
