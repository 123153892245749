import { Button } from 'components/Button/Button';
import { Modal } from 'components/Modal/Modal';
import { Portal } from 'components/Portal/Portal';
import { useTranslation } from 'react-i18next';
import { useUserStore } from 'stores/user.store';

type DeductRemainderConfirmModalProps = {
    isOpen: boolean;
    onClose: () => void;
    onConfirm: () => void;
    transaction: any;
};

export const DeductRemainderConfirmModal: React.FC<DeductRemainderConfirmModalProps> = ({
    isOpen,
    onClose,
    onConfirm,
    transaction,
}) => {
    const user = useUserStore(state => state.user);
    const { t } = useTranslation('documents');
    return (
        <Portal>
            <Modal isOpen={isOpen} onClose={onClose} maxWidth={512}>
                <div className="flex flex-col gap-4">
                    <div>
                        <div className="text-xl font-semibold text-zinc-700 mb-2">
                            {t('deductModalTitle', { receipt: transaction?.receiptNumber })}
                        </div>
                        <div className="text-sm text-zinc-500">
                            {user?.remainderBalance && Number(user?.remainderBalance) >= Number(transaction?.amount) ? (
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: t('deductModalText', {
                                            amount: `${transaction?.currency} ${transaction?.amount}`,
                                        }),
                                    }}
                                />
                            ) : (
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: t('deductModalInsufficientText', {
                                            amount: `${transaction?.currency} ${transaction?.amount}`,
                                            remainderBalance: `CHF ${user?.remainderBalance}`,
                                        }),
                                    }}
                                />
                            )}
                        </div>
                    </div>
                    <div className="flex justify-end gap-2">
                        <Button variant="outline" onClick={onClose}>
                            {t('cancelModalButton')}
                        </Button>
                        <Button onClick={onConfirm}>{t('proceedButton')}</Button>
                    </div>
                </div>
            </Modal>
        </Portal>
    );
};
