import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { useUserStore } from 'stores/user.store';

export const MemberPreviewAnniversaries = () => {
    const user = useUserStore(state => state.user);

    return (
        <div className="flex gap-6">
            <AnniverasyItem image="cake-big" value={user?.birthdayInDays} user={user} type="BIRTHDAY" />
            {user?.weddingDate && (
                <AnniverasyItem image="rings" user={user} value={user?.weddingAnniversaryInDays} type="WEDDING" />
            )}
        </div>
    );
};

const AnniverasyItem = ({ image, value, type, user, children }: any) => {
    const { t } = useTranslation('member');
    const getDays = () => {
        switch (value) {
            case 0:
                return t('today');
            case 1:
                return t('tomorrow');
            default:
                return value <= 30
                    ? t('xxDays', { days: value })
                    : t('onDate', {
                          date: dayjs(type === 'BIRTHDAY' ? user?.dateOfBirth : user?.weddingDate).format('D MMMM'),
                      });
        }
    };

    const getTitle = () => {
        switch (type) {
            case 'BIRTHDAY':
                return t('birthdayAnniversary', {
                    name: user?.firstName,
                });
            case 'WEDDING':
                return t('weddingAnniversary', {
                    name: user?.firstName,
                    year: calculateYearsPassed(),
                });
        }
    };

    const calculateYearsPassed = () => {
        const currentYear = dayjs().year();
        const weddingYear = dayjs(user?.weddingDate).year();
        return currentYear - weddingYear;
    };

    return (
        <div
            className="border border-rose-100 p-6 bg-rose-50 flex-1 relative overflow-hidden"
            style={{
                background: 'linear-gradient(0deg, rgba(255, 241, 242, 0.40) 0%, rgba(255, 241, 242, 0.40) 100%), #FFF',
            }}
        >
            {type && <div className="text-sm text-zinc-400">{getTitle()}</div>}
            <div className="text-xl font-semibold text-rose-700">{getDays()}</div>
            {image && (
                <img src={`/images/${image}.png`} alt="Cake" className="absolute top-4 right-[-0px] opacity-25" />
            )}
        </div>
    );
};
