import { cn } from 'lib/utils';

import styles from './Container.module.scss';
export const Container = ({ children, className }: any) => {
    return (
        <section className={cn('px-12 mb-12')}>
            <div className={cn(styles.container, className)}>{children}</div>
        </section>
    );
};
