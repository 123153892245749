import { Card, CardTitle } from 'components/Card/Card';
import { PreviewLabel, PreviewValue } from 'components/PreviewFields/PreviewFields';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { useStaffStore } from 'stores/staff.store';

export const StaffPersonalInfoPreview = () => {
    const member = useStaffStore(state => state.member);
    const { t } = useTranslation('forms');
    const { t: s } = useTranslation('staff');

    return (
        <Card className="flex-1">
            <div className="flex flex-col gap-8">
                <CardTitle>{s('staffInfoTitle')}</CardTitle>
                <div className="flex flex-col gap-6">
                    <div className="flex gap-6">
                        <div className="flex-1">
                            <PreviewLabel>{t('preview.email')}</PreviewLabel>
                            <PreviewValue>{member?.email}</PreviewValue>
                        </div>
                        <div className="flex-1">
                            <PreviewLabel>{t('preview.role')}</PreviewLabel>
                            <PreviewValue className="capitalize">{member?.staffRole?.toLowerCase()}</PreviewValue>
                        </div>
                    </div>

                    <div className="flex gap-6">
                        <div className="flex-1">
                            <PreviewLabel>{t('preview.firstName')}</PreviewLabel>
                            <PreviewValue>{member?.firstName}</PreviewValue>
                        </div>
                        <div className="flex-1">
                            <PreviewLabel>{t('preview.lastName')}</PreviewLabel>
                            <PreviewValue>{member?.lastName}</PreviewValue>
                        </div>
                    </div>

                    <div className="flex gap-6">
                        <div className="flex-1">
                            <PreviewLabel>{t('preview.dateOfBirth')}</PreviewLabel>
                            <PreviewValue>{dayjs(member?.dateOfBirth).format('DD.MM.YYYY')}</PreviewValue>
                        </div>
                        <div className="flex-1">
                            <PreviewLabel>{t('preview.jobPosition')}</PreviewLabel>
                            <PreviewValue>{member?.jobPosition}</PreviewValue>
                        </div>
                    </div>

                    <div className="flex gap-6">
                        <div className="flex-1">
                            <PreviewLabel>{t('preview.phone')}</PreviewLabel>
                            <PreviewValue>{member?.phoneNumber}</PreviewValue>
                        </div>
                        <div className="flex-1"></div>
                    </div>

                    <div className="flex gap-6">
                        <div className="flex-1">
                            <PreviewLabel>{t('address')}</PreviewLabel>
                            <PreviewValue>{member?.address}</PreviewValue>
                        </div>
                        <div className="flex-1 flex">
                            <div className="flex-1">
                                <PreviewLabel>{t('zipCode')}</PreviewLabel>
                                <PreviewValue>{member?.zipCode}</PreviewValue>
                            </div>
                            <div className="flex-1">
                                <PreviewLabel>{t('poBox')}</PreviewLabel>
                                <PreviewValue>{member?.poBox}</PreviewValue>
                            </div>
                        </div>
                    </div>

                    <div className="flex gap-6">
                        <div className="flex-1">
                            <PreviewLabel>{t('city')}</PreviewLabel>
                            <PreviewValue>{member?.city}</PreviewValue>
                        </div>
                        <div className="flex-1">
                            <PreviewLabel>{t('country')}</PreviewLabel>
                            <PreviewValue>{member?.country?.name}</PreviewValue>
                        </div>
                    </div>
                </div>
            </div>
        </Card>
    );
};
