import { useMutation } from '@apollo/client';
import { Breadcrumbs } from 'components/Breadcrumbs/Breadcrumbs';
import { Button } from 'components/Button/Button';
import { PageTitle } from 'components/PageTitle/PageTitle';
import { useToast } from 'components/Toast/use-toast';
import dayjs from 'dayjs';
import { EventCommentsForm } from 'pages/NewEvent/_components/EventCommentsForm';
import { EventFilesForm } from 'pages/NewEvent/_components/EventFilesForm';
import { EventInfoForm } from 'pages/NewEvent/_components/EventInfoForm';
import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { AppRoutes } from 'Routes';
import { EVENT_DOCUMENTS_ADD, EVENT_IMAGES_ADD, EVENT_UPDATE } from 'services/events/mutation';
import { useEventStore } from 'stores/event.store';

export const EditEvent = () => {
    const { event, loading, getEvent, images, setImages, documents, setDocuments } = useEventStore(state => state);
    const [updateEvent] = useMutation(EVENT_UPDATE);
    const [imagesAdd] = useMutation(EVENT_IMAGES_ADD);
    const [docsAdd] = useMutation(EVENT_DOCUMENTS_ADD);
    const { t } = useTranslation('events');
    const { toast } = useToast();
    const navigate = useNavigate();

    const methods = useForm({
        mode: 'onChange',
    });

    useEffect(() => {
        if (event) {
            const data = {
                name: event.name,
                type: event.type,
                capacity: event.capacity,
                date: event.date && dayjs(event.date).format('DD.MM.YYYY'),
                allDay: event.allDay,
                fromTime: event.fromTime,
                untilTime: event.untilTime,
                description: event.description,
                comments: event.comments,
            };
            methods.reset(data);
        }
    }, [event, methods]);

    const onSubmit = async (data: any) => {
        data.date = data.date ? dayjs(data.date, 'DD.MM.YYYY').format('YYYY-MM-DD') : null;

        try {
            const saveResp = await updateEvent({ variables: { event: event.id, input: data } });
            if (saveResp.errors && saveResp.errors?.length > 0) {
                toast({
                    title: t(saveResp.errors[0].extensions.code as any),
                    variant: 'warning',
                });
                return;
            }
            if (images.length > 0) {
                const imagesResp = await imagesAdd({ variables: { event: event.id, input: images } });
                if (imagesResp.errors && imagesResp.errors?.length > 0) {
                    failToast();
                    return;
                }
                setImages([]);
            }
            if (documents.length > 0) {
                const docsResp = await docsAdd({ variables: { event: event.id, input: documents } });
                if (docsResp.errors && docsResp.errors?.length > 0) {
                    failToast();
                    return;
                }
                setDocuments([]);
            }
            if (saveResp.data?.eventUpdate?.event) {
                toast({
                    title: t('updateSuccess'),
                    variant: 'success',
                });
            }
            getEvent(event.id);
            navigate(AppRoutes.eventPreview.replace(':id', event.id));
        } catch (err) {
            console.error(err);
        }
    };

    const failToast = () => {
        toast({
            title: t('createFailed'),
            description: t('createFailedDescription'),
            variant: 'warning',
        });
    };

    if (!event || loading) return null;

    return (
        <>
            <Breadcrumbs crumbs={['dashboard', 'events', event.name]} />
            <PageTitle withBack>{event.name}</PageTitle>

            <FormProvider {...methods}>
                <form onSubmit={methods.handleSubmit(onSubmit)}>
                    <div className="flex flex-col gap-6">
                        <EventInfoForm />
                        <EventFilesForm />
                        <EventCommentsForm />
                    </div>
                    <div className="flex justify-end gap-2 mt-8">
                        {/* <Button type="submit" variant="outline">
                            {t('saveDraftButton')}
                        </Button> */}
                        <Button type="submit">{t('updateEventButton')}</Button>
                    </div>
                </form>
            </FormProvider>
        </>
    );
};
