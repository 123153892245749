import { useMutation } from '@apollo/client';
import { AddNewContact } from 'components/AddNewContact/AddNewContact';
import { Breadcrumbs } from 'components/Breadcrumbs/Breadcrumbs';
import { ContactInfoModal } from 'components/ContactInfoModal/ContactInfoModal';
import { EditContactModal } from 'components/ContactsModal/EditContactModal';
import { EmptyState } from 'components/EmptyState/EmptyState';
import { MemberHeader } from 'components/MemberHeader/MemberHeader';
import { MemberTabs } from 'components/MemberTabs/MemberTabs';
import { StatusFilter } from 'components/StatusFilter/StatusFilter';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from 'components/Table/Table';
import { TableActions } from 'components/TableActions/TableActions';
import { useToast } from 'components/Toast/use-toast';
import { cn, normalizeArray } from 'lib/utils';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { CONTACT_STATUS_UPDATE } from 'services/contacts/mutation';
import { useUserStore } from 'stores/user.store';

const STATUSES = ['ACTIVE', 'ARCHIVED'];
const CONTACT_TYPES = ['company', 'partner', 'assistant', 'other'];

export const MemberContacts = () => {
    const [status, setStatus] = useState(['ACTIVE', 'ARCHIVED']);
    const [selectedContact, setSelectedContact] = useState(null);
    const [editContact, setEditContact] = useState(null);
    const [searchParams] = useSearchParams();
    const [statusUpdate] = useMutation(CONTACT_STATUS_UPDATE);
    const { user, getUser } = useUserStore(state => state);
    const { t } = useTranslation('translation');
    const { t: m } = useTranslation('member');
    const { toast } = useToast();

    useEffect(() => {
        const status = searchParams.get('status')?.split(',');
        if (status) {
            setStatus(status);
        } else {
            setStatus(['ACTIVE', 'ARCHIVED']);
        }
    }, [searchParams]);

    const edit = () => {
        setEditContact(selectedContact);
        setSelectedContact(null);
    };

    const handleStatusChange = (contact: any) => {
        if (!contact) return;
        statusUpdate({
            variables: { contact: contact.id, status: contact.status === 'ACTIVE' ? 'ARCHIVED' : 'ACTIVE' },
        }).then(r => {
            if (r.data.contactStatusUpdate?.status) {
                toast({
                    title: m('statusUpdateSuccess'),
                    variant: 'success',
                });
                if (user?.id) getUser(user?.id);
            } else {
                toast({
                    title: m('updateErrorTitle'),
                    description: m('updateErrorMessage'),
                    variant: 'warning',
                });
            }
        });
    };

    return (
        <div>
            <Breadcrumbs crumbs={['dashboard', 'members', `${user?.firstName} ${user?.lastName}`]} />
            <MemberHeader />
            <MemberTabs />
            {!!user?.contacts?.edges?.length && (
                <div className="flex justify-between mb-4">
                    <StatusFilter options={STATUSES} />
                    <AddNewContact types={CONTACT_TYPES} member={user?.id} />
                </div>
            )}
            {user?.contacts?.edges?.length === 0 ? (
                <EmptyState
                    image="/images/no-contacts.png"
                    title="No contacts registered yet"
                    text="You can add a new contact by clicking the button below"
                >
                    <AddNewContact types={CONTACT_TYPES} member={user?.id} />
                </EmptyState>
            ) : (
                <Table className="border overflow-visible">
                    <TableHeader>
                        <TableRow>
                            <TableHead>{t('contacts.typeLabel')}</TableHead>
                            <TableHead>{t('contacts.nameLabel')}</TableHead>
                            <TableHead>{t('contacts.phoneLabel')}</TableHead>
                            <TableHead>{t('contacts.billingLabel')}</TableHead>
                            <TableHead />
                        </TableRow>
                    </TableHeader>
                    <TableBody className="hover:cursor-pointer">
                        {user?.contacts?.edges?.length !== 0 &&
                            normalizeArray(user?.contacts)
                                ?.filter((c: any) => status.includes(c.status))
                                .map((contact: any) => (
                                    <TableRow
                                        key={contact.id}
                                        className={cn(
                                            contact.status === 'ARCHIVED' && 'text-zinc-400',
                                            'hover:bg-rose-50',
                                        )}
                                    >
                                        <TableCell className="capitalize" onClick={() => setSelectedContact(contact)}>
                                            {contact.type.toLowerCase()}
                                        </TableCell>
                                        <TableCell onClick={() => setSelectedContact(contact)}>
                                            {contact.name} {contact.lastName}
                                        </TableCell>
                                        <TableCell onClick={() => setSelectedContact(contact)}>
                                            {contact.phoneNumber}
                                        </TableCell>
                                        <TableCell onClick={() => setSelectedContact(contact)}>
                                            {contact.billToThisContact ? 'Yes' : '-'}
                                        </TableCell>
                                        <TableCell className="py-3 justify-end flex">
                                            <TableActions
                                                status={contact.status}
                                                onEdit={() => setEditContact(contact)}
                                                onStatusChange={() => handleStatusChange(contact)}
                                            />
                                        </TableCell>
                                    </TableRow>
                                ))}
                    </TableBody>
                </Table>
            )}
            <ContactInfoModal onClose={() => setSelectedContact(null)} contact={selectedContact} onEdit={edit} />
            <EditContactModal onClose={() => setEditContact(null)} contact={editContact} member={user?.id} />
        </div>
    );
};
