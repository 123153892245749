import { useMutation } from '@apollo/client';
import { Breadcrumbs } from 'components/Breadcrumbs/Breadcrumbs';
import { Button } from 'components/Button/Button';
import { Container } from 'components/Container/Container';
import { Icon } from 'components/Icon/Icon';
import { PageTitle } from 'components/PageTitle/PageTitle';
import { useToast } from 'components/Toast/use-toast';
import dayjs from 'dayjs';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { AppRoutes } from 'Routes';
import { STAFF_CREATE } from 'services/users/mutation';

import { StaffInfo } from './_components/StaffInfo';
import { StaffPersonalInfo } from './_components/StaffPersonalInfo';

export const NewStaff = () => {
    const navigate = useNavigate();
    const methods = useForm();
    const { t } = useTranslation('staff');
    const [staffCreate] = useMutation(STAFF_CREATE);
    const { toast } = useToast();

    const onSubmit = async (formData: any) => {
        formData.dateOfBirth = formData.dateOfBirth
            ? dayjs(formData.dateOfBirth, 'DD.MM.YYYY').format('YYYY-MM-DD')
            : null;

        try {
            const { data, errors } = await staffCreate({ variables: { input: formData } });
            if (data?.staffCreate?.user) {
                toast({
                    title: t('createSuccess'),
                    variant: 'success',
                });
                navigate(AppRoutes.staff, { replace: true });
            }
            if (errors && errors?.length > 0) {
                toast({
                    description: errors[0].message,
                    variant: 'warning',
                });
            }
        } catch (_) {
            toast({
                title: t('createFail'),
                description: t('creteFailDescription'),
                variant: 'warning',
            });
        }
    };

    return (
        <Container>
            <Breadcrumbs crumbs={['dashboard', 'staff', 'new staff member']} />
            <PageTitle className="py-8 flex items-center">
                <Icon name="back" onClick={() => navigate(-1)} className="h-6 mr-2 text-2xl hover:cursor-pointer" />
                {t('staffTitle')}
            </PageTitle>
            <FormProvider {...methods}>
                <form onSubmit={methods.handleSubmit(onSubmit)}>
                    <div className="flex gap-6">
                        <StaffInfo />
                        <StaffPersonalInfo />
                    </div>
                    <div className="flex justify-end py-8">
                        <Button>{t('publishButton')}</Button>
                    </div>
                </form>
            </FormProvider>
        </Container>
    );
};
