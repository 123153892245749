import { Loader } from 'components/Loader/Loader';
import { Navigation } from 'components/Navigation/Navigation';
import { cn } from 'lib/utils';
import { Suspense } from 'react';
import { Outlet } from 'react-router-dom';

export const Layout = () => {
    return (
        <section className={cn('flex flex-1 max-h-dvh')}>
            <Navigation />
            <div className={cn('flex-1 overflow-auto relative')}>
                <Suspense fallback={<Loader fullSize />}>
                    <Outlet />
                </Suspense>
            </div>
        </section>
    );
};
