import { gql } from '@apollo/client';

export const ANNOUNCEMENTS = gql`
    query announcements {
        announcements {
            totalCount
            edges {
                node {
                    id
                    title
                    description
                    status
                }
            }
        }
    }
`;
