import clsx from 'clsx';
import { Icon } from 'components/Icon/Icon';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from 'components/Tooltip/Tooltip';
import { cn } from 'lib/utils';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import styles from './ConsumptionActions.module.scss';

export const ConsumprionActions = ({ onEdit, onViewBalances, user }: any) => {
    const [isActive, setIsActive] = useState(false);
    const menu = useRef<any>(null);
    const { t } = useTranslation('documents');

    const handleClickOutside = (event: any) => {
        if (menu.current && !menu.current.contains(event.target)) {
            setIsActive(false);
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleClickOutside, true);

        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, []);

    const handleEdit = () => {
        if (user.status !== 'ACTIVE') return;
        onEdit();
    };

    return (
        <>
            <div
                className={clsx(styles.tableActions, isActive && styles.active)}
                onClick={() => setIsActive(!isActive)}
                ref={menu}
            >
                <div className={styles.trigger}>
                    <Icon name="three-dots" />
                </div>
                <div className={styles.dropdown}>
                    <div className={styles.dropdownContent}>
                        <div className={styles.dropdownItem} onClick={onViewBalances}>
                            <Icon name="doc-chart" /> {t('viewBalancesButton')}
                        </div>
                        <EditTooltip status={user.status}>
                            <div className={styles.dropdownItem} onClick={handleEdit}>
                                <Icon name="edit" /> {t('editBalanceButton')}
                            </div>
                        </EditTooltip>
                    </div>
                </div>
            </div>
        </>
    );
};

const EditTooltip = ({ children, status }: any) => {
    const { t } = useTranslation('documents');
    return (
        <TooltipProvider>
            <Tooltip delayDuration={0}>
                <TooltipTrigger className={cn(status !== 'ACTIVE' && 'opacity-50')}>{children}</TooltipTrigger>
                {status !== 'ACTIVE' && (
                    <TooltipContent sideOffset={-5} align="center">
                        {t('memberNotActive')}
                    </TooltipContent>
                )}
            </Tooltip>
        </TooltipProvider>
    );
};
