import { Icon } from 'components/Icon/Icon';
import { BlockedLabel } from 'components/Labels/BlockedLabel';
import { DraftLabel } from 'components/Labels/DraftLabel';
import { SuggestedLabel } from 'components/Labels/SuggestedLabel';
import { TerminatedLabel } from 'components/Labels/TerminatedLabel';
import { PageTitle } from 'components/PageTitle/PageTitle';
import { ShareholderBadge } from 'components/ShareholderBadge/ShareholderBadge';
import { useNavigate } from 'react-router-dom';
import { useUserStore } from 'stores/user.store';

export const MemberHeader = ({ children }: any) => {
    const user = useUserStore(state => state.user);
    const navigate = useNavigate();
    return (
        <PageTitle className="pt-8 flex items-center flex-1">
            <div className="flex flex-1 h-10">
                <div className="flex-1 flex items-center">
                    <Icon name="back" className="h-6 text-2xl mr-2 hover:cursor-pointer" onClick={() => navigate(-1)} />
                    <div>{`${user?.firstName} ${user?.lastName}`}</div>
                    {user?.isShareholder && (
                        <div className="ml-3 pt-1">
                            <ShareholderBadge />
                        </div>
                    )}
                    <DraftLabel status={user?.status} className="ml-2" />
                    <BlockedLabel status={user?.status} className="ml-2" />
                    <SuggestedLabel status={user?.status} className="ml-2" />
                    <TerminatedLabel status={user?.status} className="ml-2" />
                </div>
                <div>{children}</div>
            </div>
        </PageTitle>
    );
};
