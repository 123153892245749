import { useQuery } from '@apollo/client';
import { Card, CardSubtitle, CardTitle } from 'components/Card/Card';
import { MemberBox } from 'components/MemberBox/MemberBox';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { USER_BY_ID } from 'services/users/query';

import { RemoveMemberModal } from './RemoveMemberModal';

export const NewCompanyMembers = () => {
    const [open, setOpen] = useState(false);
    const [member, setMember] = useState<any>(null);
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const { data } = useQuery(USER_BY_ID, { variables: { id: searchParams.get('member') } });
    const { t } = useTranslation('company');

    useEffect(() => {
        if (data) setMember(data.user);
    }, [data]);

    const removeMember = (id: string) => {
        setOpen(true);
    };

    const confirmDelete = () => {
        setOpen(false);
        setMember(null);
        navigate(-1);
    };

    if (!member) return null;
    return (
        <>
            <Card>
                <CardTitle>{t('companyMembersTitle')}</CardTitle>
                <CardSubtitle>{t('companyMembersSubtitle')}</CardSubtitle>
                <div className="grid grid-cols-2 gap-6">
                    <MemberBox key={member.id} member={member} onRemove={() => removeMember(member.id)} />
                </div>
            </Card>
            <RemoveMemberModal isOpen={open} onClose={() => setOpen(false)} onConfirm={confirmDelete} />
        </>
    );
};
