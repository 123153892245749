import { useQuery } from '@apollo/client';
import { Button } from 'components/Button/Button';
import { Icon } from 'components/Icon/Icon';
import { Modal } from 'components/Modal/Modal';
import { Portal } from 'components/Portal/Portal';
import { RadioButton } from 'components/RadioButton/RadioButton';
import { SearchFilter } from 'components/SearchFilter/SearchFilter';
import { SortFilter } from 'components/SortFilter/SortFilter';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from 'components/Table/Table';
import { cn, normalizeArray } from 'lib/utils';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { COMPANIES } from 'services/companies/query';

const SORT = [
    { value: 'A_Z', label: 'A-Z' },
    { value: 'Z_A', label: 'Z-A' },
    { value: 'MOST_RECENT', label: 'Most recent' },
    { value: 'LEAST_RECENT', label: 'Least recent' },
];
const PAGE_SIZE = 100;

type ExistingCompanyModalProps = {
    isOpen: boolean;
    onClose: () => void;
    onSelect: (id: string | null) => void;
};
export const ExistingCompanyModal: React.FC<ExistingCompanyModalProps> = ({ isOpen, onClose, onSelect }) => {
    const [selectedCompany, setSelectedCompany] = useState(null);
    const { data, refetch } = useQuery(COMPANIES, {
        variables: {
            first: PAGE_SIZE,
        },
    });
    const [searchParams] = useSearchParams();

    const { t } = useTranslation('member', { keyPrefix: 'companyMembership' });

    useEffect(() => {
        const search = searchParams.get('search');
        const sort = searchParams.get('sort');

        refetch({
            search: search,
            sort: sort,
        });
    }, [searchParams, refetch]);

    return (
        <Portal>
            <Modal isOpen={isOpen} onClose={onClose} maxWidth={800} uncloseable>
                <div className="flex flex-col gap-6" style={{ minHeight: '60vh' }}>
                    <div>
                        <div className="text-xl font-semibold text-zinc-700 mb-2">{t('existingModalTitle')}</div>
                        <div className="text-sm text-zinc-500">{t('existingModalSubtitle')}</div>
                    </div>
                    <div className="flex flex-col gap-4">
                        <div className="flex gap-4">
                            <SearchFilter placeholder={t('searchPlaceholder')} />
                            <SortFilter options={SORT} />
                        </div>
                        <div>
                            <Table className="border">
                                <TableHeader>
                                    <TableRow>
                                        <TableHead className="w-2" />
                                        <TableHead>{t('companyLabel')}</TableHead>
                                    </TableRow>
                                </TableHeader>
                                <TableBody className="hover:cursor-pointer">
                                    {data?.companies.edges.length > 0 &&
                                        normalizeArray(data?.companies).map((company: any) => (
                                            <TableRow
                                                key={company.id}
                                                className="text-zinc-700 hover:bg-rose-50"
                                                onClick={() => setSelectedCompany(company.id)}
                                            >
                                                <TableCell className="pr-2">
                                                    <RadioButton selected={selectedCompany === company.id} />
                                                </TableCell>
                                                <TableCell className="flex gap-2 items-center pl-2">
                                                    <CompanyImage image={company.image} />
                                                    {company.name}
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                </TableBody>
                            </Table>
                        </div>
                        <div className="flex justify-end gap-2">
                            <Button variant="outline" onClick={onClose}>
                                {t('cancelButton')}
                            </Button>
                            <Button disabled={!selectedCompany} onClick={() => onSelect(selectedCompany)}>
                                {t('continueButton')}
                            </Button>
                        </div>
                    </div>
                </div>
            </Modal>
        </Portal>
    );
};

const CompanyImage = ({ image }: any) => {
    return (
        <div className={cn(`bg-zinc-300 w-[32px] h-[32px] flex justify-center items-center`)}>
            {image ? (
                <img src={`${image.url}?fit=facearea&facepad=2.5&w=32&h=32`} alt={image.alt} />
            ) : (
                <Icon name="user" className="text-zinc-50 h-[18px]" />
            )}
        </div>
    );
};
