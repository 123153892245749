import { Breadcrumbs } from 'components/Breadcrumbs/Breadcrumbs';
import { Button } from 'components/Button/Button';
import { CompanyHeader } from 'components/CompanyHeader/CompanyHeader';
import { CompanyTabs } from 'components/CompanyTabs/CompanyTabs';
import { Icon } from 'components/Icon/Icon';
import { useNavigate, useParams } from 'react-router-dom';
import { AppRoutes } from 'Routes';
import { useCompanyStore } from 'stores/company.store';

import { CompanyInfoPreview } from './_components/CompanyInfoPreview/CompanyInfoPreview';
import { CompanyMembersPreview } from './_components/CompanyMembersPreview/CompanyMembersPreview';
import { CompanyProfilePreview } from './_components/CompanyProfilePreview/CompanyProfilePreview';

export const CompanyProfile = () => {
    const company = useCompanyStore(state => state.company);
    const navigate = useNavigate();
    const { id } = useParams();

    const editCompany = () => {
        if (!id) return;
        navigate(AppRoutes.editCompany.replace(':id', id));
    };

    return (
        <>
            <Breadcrumbs crumbs={['members', 'companies', company?.name]} />
            <CompanyHeader>
                {company && (
                    <Button onClick={editCompany}>
                        <Icon name="edit" className="h-4 mr-2" />
                        Edit
                    </Button>
                )}
            </CompanyHeader>
            <CompanyTabs />

            <div className="flex flex-col gap-6">
                <div className="flex gap-6">
                    <CompanyProfilePreview />
                    <CompanyInfoPreview />
                </div>
                <CompanyMembersPreview />
            </div>
        </>
    );
};
