import { Label } from 'components/Label/Label';
import { cn } from 'lib/utils';

export const PreviewLabel = ({ children }: any) => {
    return <Label className="text-zinc-700 opacity-60">{children}</Label>;
};

export const PreviewValue = ({ className, children }: any) => {
    return <div className={cn('text-zinc-700 py-2', className)}>{children || '-'}</div>;
};
