import { client } from 'lib/apollo';

import { EVENT } from './query';

export const getEvent = (id: string) =>
    client.query({
        query: EVENT,
        variables: {
            id,
        },
    });
