import { Avatar } from 'components/Avatar/Avatar';
import { Button } from 'components/Button/Button';
import { Card } from 'components/Card/Card';
import { CompanySettings } from 'components/CompanySettings/CompanySettings';
import { Icon } from 'components/Icon/Icon';
import { Label } from 'components/Label/Label';
import { useTranslation } from 'react-i18next';
import { useCompanyStore } from 'stores/company.store';

export const CompanyPreviewAvatar = () => {
    const { t } = useTranslation('company');
    const { company, getCompany } = useCompanyStore(state => state);
    return (
        <div>
            <Card className="flex flex-col gap-8 relative pb-3">
                <div className="flex flex-col gap-4">
                    <div className="flex justify-between">
                        <Avatar image={company?.image} status={company?.status} />
                        <Button size="smallIcon">
                            <Icon name="export" className="h-4" />
                        </Button>
                    </div>
                    <div className="text-3xl font-semibold">{company?.name}</div>
                </div>
                <div className="flex flex-col gap-6">
                    <div>
                        <Label className="opacity-60">{t('companyId')}</Label>
                        <div>{company?.companyId}</div>
                    </div>
                    <div>
                        <Label className="opacity-60">{t('accountType')}</Label>
                        <div>{t('company')}</div>
                    </div>
                </div>
                <div>
                    <div className="bg-zinc-200 h-px w-[300px]" />
                </div>
                <div>
                    <CompanySettings company={company} onChange={() => getCompany(company?.id)} />
                </div>
            </Card>
        </div>
    );
};
