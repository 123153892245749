import { Icon } from 'components/Icon/Icon';
import { cn } from 'lib/utils';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { AppRoutes } from 'Routes';

import styles from './Navigation.module.scss';

export const Navigation = () => {
    const menu = useRef<any>(null);
    const { t } = useTranslation();
    const [isOpen, setIsOpen] = useState(false);

    const handleClick = (event: any) => {
        setIsOpen(false);
    };

    useEffect(() => {
        document.addEventListener('click', handleClick, true);

        return () => {
            document.removeEventListener('click', handleClick, true);
        };
    }, []);

    return (
        <>
            <div className={styles.trigger} onClick={() => setIsOpen(!isOpen)}>
                <Icon name="hamburger" />
            </div>
            <div
                className={cn(styles.navigation, isOpen && styles.open, 'border-r flex flex-col overflow-auto')}
                ref={menu}
            >
                <div className={styles.logo}>
                    <img src="/images/czr-logo.svg" alt="Club zum Renweg logo" />
                </div>
                <div className={cn(styles.navLinks, 'flex-1')}>
                    <div>
                        <NavLink
                            to={AppRoutes.dashboard}
                            className={({ isActive }) => cn(styles.navLink, isActive && styles.active)}
                        >
                            {t('nav.dashboard')}
                        </NavLink>
                        <NavLink
                            to={AppRoutes.members}
                            className={({ isActive }) => cn(styles.navLink, isActive && styles.active)}
                        >
                            {t('nav.members')}
                        </NavLink>
                        <NavLink
                            to={AppRoutes.events}
                            className={({ isActive }) => cn(styles.navLink, isActive && styles.active)}
                        >
                            {t('nav.events')}
                        </NavLink>
                        <NavLink
                            to={AppRoutes.staff}
                            className={({ isActive }) => cn(styles.navLink, isActive && styles.active)}
                        >
                            {t('nav.staff')}
                        </NavLink>
                        <NavLink
                            to={AppRoutes.invoices}
                            className={({ isActive }) =>
                                cn(
                                    styles.navLink,
                                    window.location.pathname.includes(AppRoutes.documents) && styles.active,
                                )
                            }
                        >
                            {t('nav.documents')}
                        </NavLink>
                        <NavLink
                            to={AppRoutes.announcement}
                            className={({ isActive }) => cn(styles.navLink, isActive && styles.active)}
                        >
                            {t('nav.announcements')}
                        </NavLink>
                    </div>
                    <NavLink
                        to={AppRoutes.logout}
                        className={({ isActive }) => cn(styles.navLink, isActive && styles.active)}
                    >
                        {t('nav.logout')}
                    </NavLink>
                </div>
                <div className={cn(styles.address)}>
                    <div dangerouslySetInnerHTML={{ __html: t('nav.address') }}></div>
                </div>
            </div>
        </>
    );
};
