import { useQuery } from '@apollo/client';
import { FutureAnniversary } from 'components/AnniversaryBoxes/FutureAnniversary';
import { Card, CardTitle } from 'components/Card/Card';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ANNIVERSARIES } from 'services/users/query';

import { AnniversariesModal } from './AnniversariesModal';

export const Anniversaries = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [focusOn, setFocusOn] = useState<string | null>(null);
    const { data } = useQuery(ANNIVERSARIES, { fetchPolicy: 'no-cache' });
    const { t } = useTranslation('dashboard');

    const openModal = () => {
        if (data?.anniversaries.length > 0) setIsOpen(true);
        if (data?.anniversaries.length === 0 && data?.pastAnniversaries.length > 0) {
            setFocusOn('PAST');
            setIsOpen(true);
        }
    };

    return (
        <>
            <Card className="border flex-1 flex flex-col gap-6">
                <div className="flex justify-between items-center">
                    <CardTitle className="text-base mb-0">
                        {t('anniversariesTitle')}{' '}
                        {data?.anniversaries.length > 0 && (
                            <span className="text-zinc-500 font-normal text-sm">({data?.anniversaries?.length})</span>
                        )}
                    </CardTitle>

                    <div className="text-sm text-rose-700 hover:underline cursor-pointer" onClick={() => openModal()}>
                        {data?.anniversaries.length > 0 && t('viewAll')}
                        {data?.anniversaries.length === 0 && data?.pastAnniversaries.length > 0 && t('viewPast')}
                    </div>
                </div>
                {data?.anniversaries?.length === 0 ? (
                    <NoAnniversaries />
                ) : (
                    <div className="flex flex-col gap-2 max-h-[280px] overflow-auto">
                        {data?.anniversaries?.map((anniversary: any, index: number) => (
                            <FutureAnniversary key={index} data={anniversary} />
                        ))}
                    </div>
                )}
            </Card>
            <AnniversariesModal
                isOpen={isOpen}
                onClose={() => setIsOpen(false)}
                current={data?.anniversaries}
                past={data?.pastAnniversaries}
                focusOn={focusOn}
            />
        </>
    );
};

const NoAnniversaries = () => {
    const { t } = useTranslation('dashboard');

    return (
        <div className="flex flex-col items-center max-w-[420px] mx-auto">
            <img src="/images/anniversaries.png" alt="Pink cake" />
            <div className="text-zinc-700 font-semibold text-3xl mt-6 mb-3">{t('noAnniversariesTitle')}</div>
            <div className="text-center">{t('noAnniversariesText')}</div>
        </div>
    );
};
