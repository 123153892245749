import { cn } from 'lib/utils';
import { Outlet } from 'react-router-dom';

import styles from './AuthLayout.module.scss';

export const AuthLayout = () => {
    return (
        <section className="flex flex-1">
            <div className={cn('flex-1 hidden lg:flex', styles.login)}>
                <div className={styles.logo}>
                    <img src="/images/logo_white.png" alt="CZR Logo" />
                </div>
                <div className="absolute bottom-12 left-12 text-white text-sm">Made by Mindnow AG</div>
            </div>
            <div className="flex flex-1 justify-center items-center bg-zinc-50">
                <Outlet />
            </div>
        </section>
    );
};
