import { Button } from 'components/Button/Button';
import { Modal } from 'components/Modal/Modal';
import { Portal } from 'components/Portal/Portal';
import { useTranslation } from 'react-i18next';

export const BlockCompanyModal = ({ isOpen, onClose, onBlock }: any) => {
    const { t } = useTranslation('company', { keyPrefix: 'settings' });

    return (
        <Portal>
            <Modal isOpen={isOpen} onClose={onClose} maxWidth={512} uncloseable>
                <div className="flex flex-col gap-4">
                    <div>
                        <div className="text-xl font-semibold text-zinc-700 mb-2">{t('blockModalTitle')}</div>
                        <div className="text-sm text-zinc-500">{t('blockModalSubtitle')}</div>
                    </div>
                    <div className="flex justify-end gap-2">
                        <Button variant="outline" onClick={onClose} type="button">
                            {t('cancelModalButton')}
                        </Button>
                        <Button onClick={onBlock} type="button">
                            {' '}
                            {t('yesModalButton')}
                        </Button>
                    </div>
                </div>
            </Modal>
        </Portal>
    );
};
