import { Icon } from 'components/Icon/Icon';
import { cn } from 'lib/utils';
import { useNavigate } from 'react-router-dom';

export const PageTitle = ({ withBack, children, className }: any) => {
    const navigate = useNavigate();
    return (
        <div className="flex items-center gap-2">
            {withBack && (
                <Icon name="back" onClick={() => navigate(-1)} className="text-2xl h-6 hover:cursor-pointer" />
            )}
            <div className={cn('py-4 text-zinc-700 text-3xl font-semibold', className)}>{children}</div>
        </div>
    );
};
