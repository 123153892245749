import { cn } from 'lib/utils';
import { AppRoutes } from 'Routes';

import styles from './Breadcrumbs.module.scss';

type BreadcrumbsProps = {
    crumbs: string[];
    className?: string;
};

export const Breadcrumbs: React.FC<BreadcrumbsProps> = ({ crumbs, className }) => {
    return (
        <div className={cn(styles.breadcrumbs, 'text-zinc-400', className)}>
            {crumbs.map((crumb, index) =>
                index < crumbs.length - 1 ? (
                    <a
                        key={index}
                        href={`${(AppRoutes as any)[crumb]}`}
                        className={cn(index === crumbs.length - 1 && 'text-zinc-500')}
                    >
                        {crumb}
                        <span className="text-zinc-400">{'>'}</span>
                    </a>
                ) : (
                    <span
                        key={index}
                        className={cn(index === crumbs.length - 1 && 'text-zinc-500 capitalize', className)}
                    >
                        {crumb}
                    </span>
                ),
            )}
        </div>
    );
};
