import { Card, CardTitle } from 'components/Card/Card';
import { MemberBox } from 'components/MemberBox/MemberBox';
import { normalizeArray } from 'lib/utils';
import { useTranslation } from 'react-i18next';
import { useCompanyStore } from 'stores/company.store';

export const CompanyMembersPreview = () => {
    const company = useCompanyStore(state => state.company);
    const { t } = useTranslation('company');

    if (!company?.members) return null;

    return (
        <Card className="flex flex-col gap-8">
            <CardTitle>{t('companyMembersTitle')}</CardTitle>
            <div className="grid grid-cols-2 gap-6">
                {normalizeArray(company?.members).map((member: any) => (
                    <MemberBox member={member} key={member.id} />
                ))}
            </div>
        </Card>
    );
};
