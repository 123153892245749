import { useMutation } from '@apollo/client';
import clsx from 'clsx';
import { Button } from 'components/Button/Button';
import { Icon } from 'components/Icon/Icon';
import { useToast } from 'components/Toast/use-toast';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { AppRoutes } from 'Routes';
import { EVENT_CANCEL, EVENT_DELETE } from 'services/events/mutation';
import { useEventStore } from 'stores/event.store';

import { EventCancelModal } from './EventCancelModal';
import { EventDeleteModal } from './EventDeleteModal';
import styles from './Styles.module.scss';

export const EventSettings = () => {
    const menu = useRef<any>(null);
    const { event, getEvent, setInvite } = useEventStore(state => state);
    const [isActive, setIsActive] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);
    const [cancelModal, setCancelModal] = useState(false);
    const { t } = useTranslation('events');
    const { toast } = useToast();
    const navigate = useNavigate();

    // MUTATIONS
    const [deleteEvent] = useMutation(EVENT_DELETE);
    const [cancelEvent] = useMutation(EVENT_CANCEL);

    const handleClickOutside = (event: any) => {
        if (menu.current && !menu.current.contains(event.target)) {
            setIsActive(false);
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleClickOutside, true);

        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, []);

    const handleDelete = () => {
        if (!event?.id) return;

        deleteEvent({ variables: { event: event?.id } }).then(resp => {
            if (resp.data.eventDelete.success) {
                setDeleteModal(false);
                toast({
                    title: t('deleteEventSuccess'),
                    variant: 'success',
                });
                navigate(AppRoutes.events);
            } else {
                failedToast();
            }
        });
    };

    const handleCancel = (notify: boolean) => {
        if (!event?.id) return;

        cancelEvent({ variables: { event: event?.id, notify } }).then(resp => {
            if (resp.data?.eventCancel?.status) {
                setCancelModal(false);
                toast({
                    title: t('cancelEventSuccess'),
                    variant: 'success',
                });
                getEvent(event?.id);
            } else {
                failedToast();
            }
        });
    };

    const failedToast = () => {
        toast({
            title: t('createFailed'),
            description: t('createFailedDescription'),
            variant: 'warning',
        });
    };

    if (event?.status === 'CANCELED' || event?.status === 'CONCLUDED') return <div></div>;

    return (
        <>
            <div
                className={clsx(styles.eventSettings, isActive && styles.active)}
                onClick={() => setIsActive(!isActive)}
                ref={menu}
            >
                <Button variant="ghost" className="flex gap-2 hover:bg-rose-50">
                    {t('settingsButton')} <Icon name="carret-down" className="h-4" />
                </Button>

                <div className={styles.dropdown}>
                    <div className={styles.dropdownContent}>
                        {event?.status === 'DRAFT' && (
                            <div className={styles.dropdownItem} onClick={() => setDeleteModal(true)}>
                                <Icon name="trash" /> <span>{t('deleteEventButton')}</span>
                            </div>
                        )}
                        {event?.status === 'UPCOMING' && (
                            <>
                                <div className={styles.dropdownItem} onClick={() => setCancelModal(true)}>
                                    <Icon name="error" /> <span>{t('cancelEventButton')}</span>
                                </div>
                                <div className={styles.dropdownItem} onClick={() => setInvite(true)}>
                                    <Icon name="invitation" /> <span>{t('inviteMembersButton')}</span>
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </div>

            <EventDeleteModal isOpen={deleteModal} onClose={() => setDeleteModal(false)} onConfirm={handleDelete} />
            <EventCancelModal isOpen={cancelModal} onClose={() => setCancelModal(false)} onConfirm={handleCancel} />
        </>
    );
};
