import { gql } from '@apollo/client';

export const LOGIN = gql`
    mutation login($email: String!, $password: String!, $otp: String!) {
        tokenAuth(email: $email, password: $password, otp: $otp) {
            refreshExpiresIn
            token
            refreshToken
            user {
                id
                email
                firstName
                lastName
                username
            }
        }
    }
`;

export const REFRESH_TOKEN = gql`
    mutation refreshToken($token: String) {
        refreshToken(refreshToken: $token) {
            refreshExpiresIn
            token
            refreshToken
        }
    }
`;

export const SEND_OTP = gql`
    mutation sentOtp($email: String!, $password: String!) {
        sendOtpCode(email: $email, password: $password) {
            success
            message
            phoneNumber
        }
    }
`;
