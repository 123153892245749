import { Button } from 'components/Button/Button';
import { Icon } from 'components/Icon/Icon';
import { Modal } from 'components/Modal/Modal';
import { Portal } from 'components/Portal/Portal';
import { cn } from 'lib/utils';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

type MembershipTypeModalProps = {
    isOpen: boolean;
    onClose: () => void;
    onSelect: (type: string) => void;
};
export const MembershipTypeModal: React.FC<MembershipTypeModalProps> = ({ isOpen, onClose, onSelect }) => {
    const [selectedType, setSelectedType] = useState('');
    const { t } = useTranslation('member', { keyPrefix: 'companyMembership' });

    return (
        <Portal>
            <Modal isOpen={isOpen} onClose={onClose} maxWidth={512}>
                <div className="flex flex-col gap-4">
                    <div>
                        <div className="text-xl font-semibold text-zinc-700 mb-2">{t('typeModalTitle')}</div>
                        <div className="text-sm text-zinc-500">{t('typeModalSubtitle')}</div>
                    </div>
                    <div className="flex flex-col gap-2">
                        <TypeBox
                            icon="company"
                            title={t('typeExistingTitle')}
                            text={t('typeExistingText')}
                            selected={selectedType === 'EXISTING'}
                            onClick={() => setSelectedType('EXISTING')}
                        />
                        <TypeBox
                            icon="company-new"
                            title={t('typeNewTitle')}
                            text={t('typeNewText')}
                            selected={selectedType === 'NEW'}
                            onClick={() => setSelectedType('NEW')}
                        />
                    </div>
                    <div className="flex justify-end gap-2">
                        <Button variant="outline" onClick={onClose}>
                            {t('cancelButton')}
                        </Button>
                        <Button disabled={!selectedType} onClick={() => onSelect(selectedType)}>
                            {t('continueButton')}
                        </Button>
                    </div>
                </div>
            </Modal>
        </Portal>
    );
};

const TypeBox = ({ title, text, icon, selected, onClick }: any) => {
    return (
        <div
            className={cn(
                'border border-rose-100 p-4 flex gap-4 items-center hover:cursor-pointer',
                selected && 'bg-rose-50',
            )}
            onClick={onClick}
        >
            <Icon name={icon} className="text-[40px] text-rose-800" />
            <div className="flex flex-col gap-1">
                <div className="text-rose-800 font-bold">{title}</div>
                <div className="text-zinc-500 text-sm">{text}</div>
            </div>
        </div>
    );
};
