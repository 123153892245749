import { Container } from 'components/Container/Container';
import { Loader } from 'components/Loader/Loader';
import { useEffect } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import { useCompanyStore } from 'stores/company.store';

export const CompanyLayout = () => {
    const { id } = useParams();
    const { loading, getCompany, setCompany } = useCompanyStore(state => state);

    useEffect(() => {
        if (id) getCompany(id);
        return () => setCompany(null);
    }, [id, getCompany, setCompany]);

    if (loading) return <Loader fullSize />;

    return (
        <Container>
            <Outlet />
        </Container>
    );
};
