import { Avatar } from 'components/Avatar/Avatar';
import { Card } from 'components/Card/Card';

export const PartnerImagePreview = ({ image }: any) => {
    return (
        <div>
            <Card>
                <Avatar image={image} />
            </Card>
        </div>
    );
};
