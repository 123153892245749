import { FullEvent } from 'components/FullEvent/FullEvent';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from 'components/Table/Table';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { AppRoutes } from 'Routes';

export const DashboardEventsList = ({ events }: any) => {
    const { t } = useTranslation('dashboard');
    const navigate = useNavigate();

    const goToEvent = (id: string) => {
        navigate(AppRoutes.eventPreview.replace(':id', id));
    };

    return (
        <div>
            <Table className="border bg-white">
                <TableHeader>
                    <TableRow>
                        <TableHead>{t('eventNameLabel')}</TableHead>
                        <TableHead>{t('eventDateLabel')}</TableHead>
                        <TableHead>{t('eventConfirmedLabel')}</TableHead>
                        <TableHead>{t('eventCapacityLabel')}</TableHead>
                    </TableRow>
                </TableHeader>
                <TableBody className="hover:cursor-pointer">
                    {events?.length > 0 &&
                        events.map((event: any) => (
                            <TableRow
                                key={event.id}
                                className="text-zinc-700 hover:bg-rose-50"
                                onClick={() => goToEvent(event.id)}
                            >
                                <TableCell className="flex gap-2 items-center">
                                    {event.name} {event.status}
                                </TableCell>
                                <TableCell>{dayjs(event.date).format('DD.MM.YYYY')}</TableCell>
                                <TableCell>{event.confirmed?.totalCount}</TableCell>
                                <TableCell>
                                    {event.capacity} <FullEvent event={event} />
                                </TableCell>
                            </TableRow>
                        ))}
                </TableBody>
            </Table>
        </div>
    );
};
