import { Card, CardTitle } from 'components/Card/Card';
import { PreviewLabel, PreviewValue } from 'components/PreviewFields/PreviewFields';
import dayjs from 'dayjs';
import { FamilyMembers } from 'pages/NewMember/_components/FamilyMembers/FamilyMembers';
import { useTranslation } from 'react-i18next';
import { useUserStore } from 'stores/user.store';

export const FamilyInfoPreview = () => {
    const user = useUserStore(state => state.user);
    const { t } = useTranslation('member', { keyPrefix: 'familyInfo' });
    const { t: f } = useTranslation('forms');

    return (
        <>
            <div id="family" />
            <Card className="flex flex-col gap-8">
                <CardTitle>{t('title')}</CardTitle>
                <div className="flex gap-6">
                    <div className="flex-1">
                        <PreviewLabel>{f('preview.maritalStatus')}</PreviewLabel>
                        <PreviewValue>{user?.maritalStatus && f(user.maritalStatus)}</PreviewValue>
                    </div>
                    <div className="flex-1">
                        <PreviewLabel>{f('weddingDate')}</PreviewLabel>
                        <PreviewValue>
                            {(user?.weddingDate && dayjs(user?.weddingDate).format('DD.MM.YYYY')) || '-'}
                        </PreviewValue>
                    </div>
                </div>
                <FamilyMembers editeable={false} />
                <div>
                    <PreviewLabel>{f('familyInfo')}</PreviewLabel>
                    <PreviewValue>{user?.familyInformation || '-'}</PreviewValue>
                </div>
            </Card>
        </>
    );
};
