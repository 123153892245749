import { useQuery } from '@apollo/client';
import { Breadcrumbs } from 'components/Breadcrumbs/Breadcrumbs';
import { Button } from 'components/Button/Button';
import { Card } from 'components/Card/Card';
import { Container } from 'components/Container/Container';
import { Icon } from 'components/Icon/Icon';
import { PageTitle } from 'components/PageTitle/PageTitle';
import { Status } from 'components/Status/Status';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { AppRoutes } from 'Routes';
import { ANNOUNCEMENTS } from 'services/announcement/qurery';

export const Announcement = () => {
    const { t } = useTranslation('anouncement');
    const { data } = useQuery(ANNOUNCEMENTS);
    const [announcement, setAnnouncement] = useState<any>(null);

    useEffect(() => {
        if (data?.announcements?.totalCount > 0) {
            setAnnouncement(data.announcements.edges[0].node);
        }
    }, [data]);

    return (
        <Container>
            <Breadcrumbs crumbs={['dashboard', 'announcement']} />
            <PageTitle className="flex-1">
                <div className="flex justify-between">
                    <div>{t('title')}</div>
                    <div>
                        <Button asChild>
                            <Link to={AppRoutes.editAnnouncement}>
                                <Icon name="edit" className="h-4 mr-2" />
                                {t('editButton')}
                            </Link>
                        </Button>
                    </div>
                </div>
            </PageTitle>

            <div className="mt-4">
                <Card className="flex flex-col gap-6">
                    <div className="flex justify-between">
                        <div>
                            <div className="text-sm font-medium mb-4">{t('titleLabel')}</div>
                            <div>{announcement?.title || '-'}</div>
                        </div>
                        <div className="flex items-center p-2">
                            <Status status={announcement?.status || 'INACTIVE'} />
                        </div>
                    </div>
                    <div>
                        <div className="text-sm font-medium mb-4">{t('descriptionLabel')}</div>
                        <div>{announcement?.description || '-'}</div>
                    </div>
                </Card>
            </div>
        </Container>
    );
};
