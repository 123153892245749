import { useQuery } from '@apollo/client';
import { Balance } from 'components/Balance/Balance';
import { Card, CardTitle } from 'components/Card/Card';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from 'components/Table/Table';
import { normalizeArray } from 'lib/utils';
import { useTranslation } from 'react-i18next';
import { USERS } from 'services/users/query';

export const LowestBalances = () => {
    const { data } = useQuery(USERS, {
        variables: {
            isMember: true,
            first: 5,
            sort: 'LOWEST_BALANCE',
            hasNegativeBalance: false,
        },
    });
    const { t } = useTranslation('dashboard');
    return (
        <Card className="flex-1 border flex flex-col gap-6">
            <CardTitle className="text-base">{t('lowestBalancesTitle')}</CardTitle>
            <div>
                <Table className="border bg-white">
                    <TableHeader>
                        <TableRow>
                            <TableHead>{t('nameLabel')}</TableHead>
                            <TableHead>{t('balanceLabel')}</TableHead>
                        </TableRow>
                    </TableHeader>
                    <TableBody>
                        {normalizeArray(data?.users)?.length > 0 &&
                            normalizeArray(data?.users).map((member: any) => (
                                <TableRow key={member.id}>
                                    <TableCell className="flex gap-2 items-center w-full">
                                        {member.firstName} {member.lastName}
                                    </TableCell>
                                    <TableCell>
                                        <Balance balance={member.balance} />
                                    </TableCell>
                                </TableRow>
                            ))}
                    </TableBody>
                </Table>
            </div>
        </Card>
    );
};
