import { Card, CardTitle } from 'components/Card/Card';
import { Label } from 'components/Label/Label';
import { normalizeArray } from 'lib/utils';
import { useTranslation } from 'react-i18next';

export const UserPreferencesPreview = ({ user }: any) => {
    const { t } = useTranslation('forms');
    const { t: p } = useTranslation('member', { keyPrefix: 'preferences' });
    return (
        <>
            <div id="preferences" />
            <Card className="flex flex-col gap-8 mb-10">
                <CardTitle>{p('title')}</CardTitle>
                <div className="flex flex-col gap-6">
                    <div>
                        <Label>{t('likes')}</Label>
                        <div className="py-2 flex gap-2 flex-wrap">
                            {normalizeArray(user?.likes)?.map((like: any) => (
                                <PreferenceItem key={like.id} name={like.name} />
                            ))}
                        </div>
                        {user?.likes?.edges?.length === 0 && <div className="py-2">-</div>}
                    </div>

                    <div>
                        <Label>{t('dislikes')}</Label>
                        <div className="py-2 flex gap-2 flex-wrap">
                            {normalizeArray(user?.dislikes)?.map((dislike: any) => (
                                <PreferenceItem key={dislike.id} name={dislike.name} />
                            ))}
                        </div>
                        {user?.dislikes?.edges?.length === 0 && <div className="py-2">-</div>}
                    </div>

                    <div>
                        <Label>{t('dietRestrictions')}</Label>
                        <div className="py-2 flex gap-2 flex-wrap">
                            {normalizeArray(user?.dietRestrictions)?.map((diet: any) => (
                                <PreferenceItem key={diet.id} name={diet.name} />
                            ))}
                        </div>
                        {user?.dietRestrictions?.edges?.length === 0 && <div className="py-2">-</div>}
                    </div>

                    <div>
                        <Label>{t('allergies')}</Label>
                        <div className="py-2 flex gap-2 flex-wrap">
                            {normalizeArray(user?.allergies)?.map((allergy: any) => (
                                <PreferenceItem key={allergy.id} name={allergy.name} />
                            ))}
                        </div>
                        {user?.allergies?.edges?.length === 0 && <div className="py-2">-</div>}
                    </div>
                </div>
                <div>
                    <Label>{t('comments')}</Label>
                    <div className="py-2 text-sm">{user?.preferenceComment || '---'}</div>
                </div>
            </Card>
        </>
    );
};

const PreferenceItem = ({ name }: any) => {
    return <div className="bg-rose-100 px-2 py-[2px] text-zinc-700 text-sm">{name}</div>;
};
