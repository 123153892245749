import { Button } from 'components/Button/Button';
import { Modal } from 'components/Modal/Modal';
import { Portal } from 'components/Portal/Portal';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from 'components/Table/Table';
import dayjs from 'dayjs';
import { cn, normalizeArray } from 'lib/utils';
import { useTranslation } from 'react-i18next';

type ViewBalancesModalProps = {
    isOpen: boolean;
    onClose: () => void;
    member: any;
};

export const ViewBalancesModal: React.FC<ViewBalancesModalProps> = ({ isOpen, onClose, member }) => {
    const { t } = useTranslation('documents');
    return (
        <Portal>
            <Modal isOpen={isOpen} onClose={onClose} maxWidth={800}>
                <div className="flex flex-col gap-6">
                    <div>
                        <div className="text-lg font-semibold text-zinc-700 mb-2">
                            {member?.firstName} {member?.lastName}'s {t('viewBalancesModalTitle')}
                        </div>
                        <div className="text-sm text-zinc-500">{t('viewBalancesModalSubtitle')}</div>
                    </div>
                    <div>
                        <Table className="border table-fixed">
                            <TableHeader>
                                <TableRow>
                                    <TableHead>{t('dateLabel')}</TableHead>
                                    <TableHead>{t('amountLabel')}</TableHead>
                                    <TableHead>{t('noteLabel')}</TableHead>
                                </TableRow>
                            </TableHeader>
                            <TableBody>
                                {member?.balanceEntries?.edges?.length > 0 &&
                                    normalizeArray(member?.balanceEntries).map((entry: any) => (
                                        <TableRow key={entry.id}>
                                            <TableCell>{dayjs(entry.createdAt).format('DD.MM.YYYY HH:mm')}</TableCell>
                                            <TableCell className={cn(entry.amount < 0 && 'text-orange-700')}>
                                                CHF {entry.amount}
                                            </TableCell>
                                            <TableCell>{entry.note}</TableCell>
                                        </TableRow>
                                    ))}
                            </TableBody>
                        </Table>
                        {member?.balanceEntries?.edges?.length === 0 && (
                            <div className={'py-5 text-center text-zinc-700'}>{t('noBalances')}</div>
                        )}
                    </div>
                    <div className="flex justify-end">
                        <Button onClick={onClose}>{t('closeModalButton')}</Button>
                    </div>
                </div>
            </Modal>
        </Portal>
    );
};
