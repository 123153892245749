import { useMutation } from '@apollo/client';
import { Avatar } from 'components/Avatar/Avatar';
import { Button } from 'components/Button/Button';
import Checkbox from 'components/Checkbox/Checkbox';
import { Modal } from 'components/Modal/Modal';
import { Portal } from 'components/Portal/Portal';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from 'components/Table/Table';
import { Tab, Tabs } from 'components/Tabs/Tabs';
import { useToast } from 'components/Toast/use-toast';
import { normalizeArray } from 'lib/utils';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EVENT_CONFIRM_INVITE, EVENT_REMOVE_INVITE } from 'services/events/mutation';
import { useEventStore } from 'stores/event.store';

type EventInviteesModalProps = {
    isOpen: boolean;
    onClose: () => void;
    initialTab: string;
    onTabChange: (tab: string) => void;
};
export const EventInviteesModal: React.FC<EventInviteesModalProps> = ({ isOpen, onClose, initialTab, onTabChange }) => {
    const { event, getEvent } = useEventStore(state => state);
    const [invitedList, setInvitedList] = useState([]);
    const [confirmedList, setConfirmedList] = useState([]);
    const [selectedMembers, setSelectedMembers] = useState<any>([]);
    const [allSelected, setAllSelected] = useState(false);
    const { t } = useTranslation('events');
    const { toast } = useToast();

    const [confirmInvitesMutation] = useMutation(EVENT_CONFIRM_INVITE);
    const [removeInvitesMutation] = useMutation(EVENT_REMOVE_INVITE);

    useEffect(() => {
        if (event) {
            const conf = normalizeArray(event.confirmed);
            const inv = normalizeArray(event.invited);
            setInvitedList(inv.filter(({ id: id1 }: any) => !conf.some(({ id: id2 }: any) => id1 === id2)));
            setConfirmedList(normalizeArray(event.confirmed));
        }
    }, [event]);

    const changeTab = (type: string) => {
        if (initialTab === type) return;
        onTabChange(type);
        setAllSelected(false);
        setSelectedMembers([]);
    };

    const selectMember = (id: string) => {
        if (allSelected) setAllSelected(false);
        if (selectedMembers.includes(id)) {
            setSelectedMembers(selectedMembers.filter((member: any) => member !== id));
            return;
        }
        setSelectedMembers([...selectedMembers, id]);
    };

    const selectAll = (tab: string) => {
        if (allSelected) {
            setSelectedMembers([]);
            setAllSelected(false);
            return;
        }
        setAllSelected(true);
        if (tab === 'CONFIRMED') {
            setSelectedMembers(confirmedList.map(({ id }: any) => id));
        } else {
            setSelectedMembers(invitedList.map(({ id }: any) => id));
        }
    };

    const handleSelected = () => {
        if (initialTab === 'INVITED') confirmInvites();
        if (initialTab === 'CONFIRMED') removeInvites();
    };

    const confirmInvites = async () => {
        if (!event.id) return;
        const resp = await confirmInvitesMutation({
            variables: { event: event.id, members: selectedMembers, selectAll: allSelected },
        });
        if (resp?.data?.eventConfirmInvite?.event?.id) {
            getEvent(event.id);
            toast({
                title: t('confirmInviteSuccess'),
                variant: 'success',
            });
            closeModal();
        } else {
            failedToast();
        }
    };

    const removeInvites = async () => {
        if (!event.id) return;
        const resp = await removeInvitesMutation({
            variables: { event: event.id, members: selectedMembers, selectAll: allSelected },
        });
        if (resp?.data?.eventRemoveInvite?.event?.id) {
            getEvent(event.id);
            toast({
                title: t('removeInviteSuccess'),
                variant: 'success',
            });
            closeModal();
        } else {
            failedToast();
        }
    };

    const closeModal = () => {
        setSelectedMembers([]);
        setAllSelected(false);
        onClose();
    };

    const failedToast = () => {
        toast({
            title: t('createFailed'),
            description: t('createFailedDescription'),
            variant: 'warning',
        });
    };

    return (
        <Portal>
            <Modal isOpen={isOpen} onClose={onClose} maxWidth={800}>
                <div className="flex flex-col gap-6">
                    <div className="text-xl font-semibold text-zinc-700 mb-2">{t('membersListTitle')}</div>
                    <div>
                        <Tabs className="py-0 mb-4">
                            <Tab active={initialTab === 'CONFIRMED'} onClick={() => changeTab('CONFIRMED')}>
                                {t('confirmedLabel')} ({event?.confirmed?.totalCount})
                            </Tab>
                            <Tab active={initialTab === 'INVITED'} onClick={() => changeTab('INVITED')}>
                                {t('invitedLabel')} ({event?.invited?.totalCount})
                            </Tab>
                        </Tabs>
                        <Table className="border">
                            {initialTab === 'CONFIRMED' && (
                                <>
                                    <TableHeader>
                                        <TableRow>
                                            <TableHead className="flex gap-2 items-center">
                                                <Checkbox
                                                    checked={allSelected}
                                                    onChange={() => selectAll('CONFIRMED')}
                                                />
                                                {t('nameLabel')}
                                            </TableHead>
                                        </TableRow>
                                    </TableHeader>
                                    <TableBody className="hover:cursor-pointer">
                                        {confirmedList.length > 0 &&
                                            confirmedList.map((member: any) => (
                                                <TableRow key={member.id} className="text-zinc-700 hover:bg-rose-50">
                                                    <TableCell
                                                        className="flex gap-2 items-center"
                                                        onClick={() => selectMember(member.id)}
                                                    >
                                                        <Checkbox
                                                            onChange={() => selectMember(member.id)}
                                                            checked={selectedMembers.includes(member.id)}
                                                        />
                                                        <Avatar image={member.image} size={32} />
                                                        {member.firstName} {member.lastName}
                                                        {member?.invitedGuests?.edges?.length > 0 && (
                                                            <span className="text-sm text-zinc-500">
                                                                +{member.invitedGuests.edges.length}
                                                            </span>
                                                        )}
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                    </TableBody>
                                </>
                            )}
                            {initialTab === 'INVITED' && (
                                <>
                                    <TableHeader>
                                        <TableRow>
                                            <TableHead className="flex gap-2 items-center">
                                                <Checkbox checked={allSelected} onChange={() => selectAll('INVITED')} />
                                                {t('nameLabel')}
                                            </TableHead>
                                        </TableRow>
                                    </TableHeader>
                                    <TableBody className="hover:cursor-pointer">
                                        {invitedList.length > 0 &&
                                            invitedList.map((member: any) => (
                                                <TableRow key={member.id} className="text-zinc-700 hover:bg-rose-50">
                                                    <TableCell
                                                        className="flex gap-2 items-center"
                                                        onClick={() => selectMember(member.id)}
                                                    >
                                                        <Checkbox
                                                            onChange={() => selectMember(member.id)}
                                                            checked={selectedMembers.includes(member.id)}
                                                        />{' '}
                                                        <Avatar image={member.image} size={32} />
                                                        {member.firstName} {member.lastName}
                                                        {member?.invitedGuests?.edges?.length > 0 && (
                                                            <span className="text-sm text-zinc-500">
                                                                +{member.invitedGuests.edges.length}
                                                            </span>
                                                        )}
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                    </TableBody>
                                </>
                            )}
                        </Table>
                    </div>
                    <div className="flex justify-end gap-2">
                        <Button variant="outline" onClick={onClose}>
                            {t('closeButtonLabel')}
                        </Button>
                        <Button onClick={() => handleSelected()}>
                            {initialTab === 'CONFIRMED' ? t('removeConfirmationButton') : t('confirmAttendanceButton')}
                        </Button>
                    </div>
                </div>
            </Modal>
        </Portal>
    );
};
