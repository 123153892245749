import { useMutation } from '@apollo/client';
import { BlockCompanyModal } from 'components/BlockCompanyModal/BlockCompanyModal';
import { CompanyDeleteModal } from 'components/CompanyDeleteModal/CompanyDeleteModal';
import { Icon } from 'components/Icon/Icon';
import { useToast } from 'components/Toast/use-toast';
import { UnblockCompanyModal } from 'components/UnblockCompanyModal/UnblockCompanyModal';
import { cn } from 'lib/utils';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { AppRoutes } from 'Routes';
import { COMPANY_BLOCK_UNBLOCK, COMPANY_DELETE } from 'services/companies/mutation';

import styles from './CompanySettings.module.scss';

export const CompanySettings = ({ company, onChange }: any) => {
    const menu = useRef<any>(null);
    const [isActive, setIsActive] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);
    const [blockModal, setBlockModal] = useState(false);
    const [unblockModal, setUnblockModal] = useState(false);
    const navigate = useNavigate();

    const { t } = useTranslation('company');
    const { toast } = useToast();
    // MUTATIONS
    const [blockUnblock] = useMutation(COMPANY_BLOCK_UNBLOCK);
    const [deleteCompany] = useMutation(COMPANY_DELETE);

    const isCompanyBlocked = company?.status === 'BLOCKED';

    const handleClickOutside = (event: any) => {
        if (menu.current && !menu.current.contains(event.target)) {
            setIsActive(false);
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleClickOutside, true);

        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, []);
    //////////////////////////////
    // BLOCK UNBLOCK LOGIC
    //////////////////////////////
    const handleBlockUnblockCompany = () => {
        if (company.status === 'BLOCKED') {
            setUnblockModal(true);
        } else {
            setBlockModal(true);
        }
    };
    const confirmBlockUnblock = () => {
        const companyID = company?.id;
        const status = company.status === 'BLOCKED' ? 'UNBLOCK' : 'BLOCK';

        blockUnblock({ variables: { company: companyID, action: status } }).then(r => {
            if (r.data.companyBlock?.status) {
                toast({
                    title: status === 'BLOCK' ? t('settings.successBlockTitle') : t('settings.successUnblockTitle'),
                    variant: 'success',
                });
                setUnblockModal(false);
                setBlockModal(false);
                onChange();
            } else {
                failedToast();
            }
        });
    };

    //////////////////////////////
    // PERMANENTLY DELETE LOGIC
    //////////////////////////////
    const handleDeleteCompany = () => {
        const companyID = company.id;
        deleteCompany({ variables: { company: companyID } }).then(r => {
            if (r.data.companyDelete?.success) {
                setDeleteModal(false);
                toast({
                    title: t('settings.deleteSuccess'),
                    variant: 'success',
                });
                navigate(AppRoutes.companies, { replace: true });
            } else {
                failedToast();
            }
        });
    };

    //////////////////////////////
    // ERROR TOAST
    //////////////////////////////
    const failedToast = () => {
        toast({
            title: t('failToastTitle'),
            description: t('failToastText'),
            variant: 'warning',
        });
    };

    return (
        <>
            <div
                className={cn(styles.companySettings, isActive && styles.active)}
                onClick={() => setIsActive(!isActive)}
                ref={menu}
            >
                <div className={styles.trigger}>
                    {t('settings.title')} <Icon name="carret-down" />
                </div>

                <div className={styles.dropdown}>
                    <div className={styles.dropdownContent}>
                        <div className={styles.dropdownItem} onClick={() => handleBlockUnblockCompany()}>
                            <Icon name="block" />{' '}
                            <span>{isCompanyBlocked ? t('settings.unblockCompany') : t('settings.blockCompany')}</span>
                        </div>

                        <div className={styles.dropdownItem} onClick={() => setDeleteModal(true)}>
                            <Icon name="trash" /> <span>{t('settings.deleteCompany')}</span>
                        </div>
                    </div>
                </div>
            </div>

            <BlockCompanyModal isOpen={blockModal} onClose={() => setBlockModal(false)} onBlock={confirmBlockUnblock} />
            <UnblockCompanyModal
                isOpen={unblockModal}
                onClose={() => setUnblockModal(false)}
                onUnblock={confirmBlockUnblock}
            />
            <CompanyDeleteModal
                isOpen={deleteModal}
                onClose={() => setDeleteModal(false)}
                onDelete={handleDeleteCompany}
                company={company}
            />
        </>
    );
};
